import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { window } from 'rxjs/operators';
import { Window } from 'selenium-webdriver';


@Component({
  selector: 'app-pateint-orders',
  templateUrl: './pateint-orders.component.html',
  styleUrls: ['./pateint-orders.component.scss']
})
export class PateintOrdersComponent implements OnInit {
  Orders: any;
  logindata: any;
  cols: any;
  Reports: any;
  Show: boolean = true;
  HomeURL: any;

  constructor(public router: Router, public http: HttpClient, public generalService: GeneralService) {
    debugger
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeURL = data.Webservice;
     
     
    });


    this.logindata = JSON.parse(localStorage.getItem("LoginDetails"));
    this.cols = [
      { field: 'ORDERID', header: 'ORDERID', width: '120px' },
      { field: 'FIRSTNAME', header: 'FIRSTNAME', width: '120px' },
      { field: 'AMOUNT', header: 'AMOUNT', width: '120px' },
      { field: 'PAYMENTSTATUS', header: 'PAYMENTSTATUS', width: '120px' },
      { field: 'STATUS', header: 'STATUS', width: '120px' },
      { field: 'CREATEDDATE', header: 'Created Date', width: '120px' }
    ]
   
  }

  ngOnInit(): void {
    this.getPatientOrders();

  }
  /// getting the Orders 
  getPatientOrders() {
    debugger
    let arr = [];
    var UploadFile = new FormData();
    UploadFile.append("Param1", this.logindata.EMP_ID);
    var url = 'api/Values/GetPatientOrders';
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.Orders = [];
      this.Orders = data;
      //this.Orders = data.length;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }
  // this code used  for  to click the Order its show the  Oderdetails(invoice)
  navigateToInvoice(orderid) {
    debugger
    this.router.navigate(['/OrderInvoice/' + orderid]);// routerlink
    //this.router.navigate(['/Invoice/' + orderid]);
    /*+ orderid*/

  }

  ShowReports(prodid) {
    debugger
    this.Show = false;
    var UploadFile = new FormData();
    UploadFile.append("ORDERID", prodid);
    var url = "api/Values/Get_Reports_BasedOn_ORDERID";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.Reports = data;

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  Back() {
    debugger
    this.Show = true;
  }
}
