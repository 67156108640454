import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { CartService } from '../../Services/cart.service';
import { MessageService } from 'primeng/api';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
declare var $;
import { Router } from '@angular/router';
@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
  Blogs: any;
  HomeUrl: any;

  constructor(public router: Router, public http: HttpClient, public generalService: GeneralService, public toastrService: ToastrService,
    public messageService: MessageService, public cartService: CartService) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }

  ngOnInit(): void {
    this.getNew();
  }
  // getiing the news its show on frontend
  getNew() {
    var UploadFile = new FormData();
    var url = "api/Admin/Get_News";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.Blogs = data;
        for (var i = 0; i < this.Blogs.length; i++) {
          this.Blogs[i].Description = decodeURIComponent(this.Blogs[i].Description);
        }
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  }
}
