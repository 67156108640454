import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { VERSION } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
declare var $;
import { Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';

@Component({

  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MessageService]
})
export class HomeComponent implements OnInit {
  healthPackages: any = [];
  organBasedTests: any = [];
  a2zTests: any = [];
  news: any = [];
  a2zTestsList: any = [];
  searchText: string;
  cartItems: any[];
  ShowAll: boolean = true;
  organs: any = [];
  alphabet: any = [];
  flag: any = 0;
  url: any;
  selectedAlphabet: any;
    HomeUrl: any;
  constructor(public router: Router, public generalService: GeneralService, public http: HttpClient,
    public toastrService: ToastrService, public messageService: MessageService, public cartService: CartService) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }
  items$ = this.cartService.items$;
  ngOnInit(): void {
    this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    this.getHealthPackages();
    this.getOrgans();
    this.get_A_to_Z_Tests();
    this.Getnews();
  }
  //=================Getting HealthPackages data from BackEnd using general service=====================//
  getHealthPackages() {
    var UploadFile = new FormData();
    var url = "api/Values/GetHealthPackages?flag=0";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.healthPackages = data;
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  } 
  //============ this code  use for  getting the news  on scroll============
  Getnews() {
    debugger
    var url = 'api/Admin/Get_News';
    this.generalService.GetData(url).then((data: any) => {
      debugger
     
     this.news = data;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }
  addToCart1(item) {
    debugger
    var TEST_ID;
    this.messageService.clear();
    let arr = [];
    arr.push(
      {
        TEST_ID: item.PACKAGE_ID
        , TESTNAME: item.PACKAGENAME
        , TESTCODE: item.PACKAGECODE
        , PRICE: item.PRICE
        , ISPACKAGE: true
      }
    )
    this.cartService.addToCart(arr[0]);
    debugger
    if (localStorage.getItem('info') == 'added') {
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Package added to the cart successfully' });

    }
    else if (localStorage.getItem('info') == 'exists') {
      this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Package already added to the cart' });

    }
  }
  

  //=================Getting getOrgans data from BackEnd using general service=====================//
  getOrgans() {
    debugger
    var UploadFile = new FormData();
    var url = "api/Values/getOrgans";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.url = localStorage.getItem('url');
        this.organs = data;   //getOrgans
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  }
  moreHealthPackages() {
    //this.ShowAll = false;
    this.router.navigateByUrl('/HealthPackages')
  }
  navigatepage(val: any) {
    localStorage.setItem('moreinfo', JSON.stringify(val));
    this.router.navigateByUrl('/BookTest')
  }
  //=================Getting A2Z Tests data from BackEnd using general service=====================//
  get_A_to_Z_Tests() {
    var UploadFile = new FormData();
    var UploadFile = new FormData();
    UploadFile.append("Tests1", '');

    UploadFile.append("UserID", '');
    UploadFile.append("TEST_ID", this.selectedAlphabet);
    UploadFile.append("falg", this.flag);
    var url = "api/Values/Get_TestsDetails";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.a2zTests = data;    //All Tests
        this.a2zTestsList = data;    //All Tests
        this.alphabetFilter('A');//Filtering with A by Default when page loads
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  }
   


  updateFilter(searchText: string): void {
    debugger
    this.searchText = searchText;
    this.a2zTests = [];
    this.a2zTests = this.a2zTestsList.filter(a => a.TESTNAME == this.searchText)
  }
  addToCart(item) {
    debugger
    var PACKAGE_ID;
    this.messageService.clear();
    let arr = [];
    arr.push(
      {
         
          TEST_ID: item.TEST_ID
        , TESTNAME: item.TESTNAME
        , TESTCODE: item.TESTCODE
        , PRICE: item.PRICE
        , ISPACKAGE: false
        , PACKAGE_ID: PACKAGE_ID
       

       
      }
    )
    this.cartService.addToCart(arr[0]);
    debugger
    if (localStorage.getItem('info') == 'added') {
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Test addted to the cart successfully' });

    }
    else if (localStorage.getItem('info') == 'exists') {
      this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Test already added to the cart' });

    }
  }
  alphabetFilter(term) {
    debugger
    this.selectedAlphabet = term;
    this.a2zTests = Object.assign([], this.a2zTestsList).filter(
      item => item.TESTNAME.charAt(0).toUpperCase().indexOf(term.toUpperCase()) > -1
    )
   // this.get_A_to_Z_Tests();
  }

  //Navigating to Organ Based Tests page for full description
  navigateToOrganBasedTests(val) {
    localStorage.setItem('organ', JSON.stringify(val))
    this.router.navigateByUrl('/OrgansTests')
  }
  moreTests() {
    this.router.navigateByUrl('/BookTest')
  }
  knowMore(val) {
    debugger
   // this.router.navigate(['/HealthPackage'], { queryParams: { Name: val.PACKAGENAME } });
    //this.router.navigateByUrl('/HealthPackage/', val.PACKAGE_ID)
    this.router.navigate(['/HealthPackage/', val.PACKAGENAME]);
  }
}
export interface Product {
  PACKAGE_ID: number;
  TEST_ID: number;
  TESTNAME: string;
  TESTCODE: string;
  PRICE: number;
  ISPACKAGE: boolean;
}

