<div class="container">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page"> Clinical pathology</li>
    </ol>
  </nav>
  <!--<div class="row">-->
  <!--<div class="col-lg-10"></div>




  <br />





  <br />
  <br />-->
  <h1> Clinical pathology</h1>
  <div class="row">
    <div class="col-lg-5">
      <img style="width:100%;border-radius:10px;box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;" src="../../../assets/img/OurServices/clinical-pathology.jpg" alt="Credence Diagnostic Centre"/>




    </div>
    <div class="col-lg-7">

      <p>
        <b> Clinical pathology </b>supports the diagnosis of disease using laboratory testing of blood and other bodily fluids and tissues,
        and microscopic evaluation of individual cells. Clinical pathology covers many lab functions, it is concerned with disease
        diagnosis, treatment, and prevention.
        Clinical pathologists are healthcare providers with special training. They often direct all the special divisions of the lab. This may include the following:

      </p>
      <p>
        1. Blood bank<br />
        2. Clinical chemistry and biology<br />
        3. Toxicology<br />
        4. Hematology<br />
        5.  Immunology and serology<br />
        6. Microbiology<br />

      </p>
      <p>
        Clinical pathology also includes maintenance of information systems, research, and quality control.
      </p>
    </div>

  </div>
</div>
