<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Health Packages</li>
    </ol>
  </nav>
 

  <div class="maniHeader">
    <h1 class="heallth"> HEALTH PACKAGES</h1>
   <!---- <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       width="50" height="50"
       viewBox="0 0 172 172"
       style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal;"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#3498db"><path d="M6.88,82.56v6.88h158.24v-6.88z"></path></g></g></svg>  --->
  </div>
  <!--This is to show some of the health packages.-->



  <div class="row mobileMargin mb-3"style="margin:0 -22px;">
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6" *ngFor="let item of healthPackages">
      <div class="card card_border"style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;">
        <div class="card1" >
          <img src="{{HomeUrl+item.Image}}" class="card_image" alt="Credence Diagnostics Centre">
        </div>
        <div class="card-bodyy">
          <div (click)="knowMore(item)">

            <h5 class="card-titlle">{{item.PACKAGENAME}}</h5>
            <div class="pacCard">
              {{item.PACKAGECODE || 0}}
            </div>
            <div>
              <!--<p class="text" *ngFor="let desc of item.PACKAGEDESCRIPTION1 | slice:0:2">

                {{desc}}
              </p>-->
              <!-- <P>{{item.PACKAGEDESCRIPTION1.length}}</P>-->
            </div>
            <div>
              <b>Includes: </b> {{item.PACKAGEDESCRIPTION1.length}} test 
            </div>
            <hr />
          </div>
          <div class="row">
            <div class="col-lg-4" (click)="knowMore(item)">
              <div class="know_more">
                Know More
              </div> &nbsp;
            </div>

            <div class="col-lg-4">
              <div class="addto_cart" (click)="addTocart1(item)">Add To Cart</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
<p-toast position="top-right"></p-toast>
