
<div class="container">
  <div class="cardinvoice">
  <div id="print-section" style="width:100%; ">
    <table class="margin" style="width:100%;background-color: #fff;">
      
      <tr><td style="text-align:center;"><img src="../../../assets/img/Crlogo.png" style="width:20% !important;" alt="Logo"></td></tr>
      <tr>
        <td style="text-align: center;">
          <div class="strg_6"> Tax Invoice</div>

        </td>

        <!--</tr>-->
      <tr class=" bill">

        <td class="bill_4 " colspan="5" style="text-align:left">
          <b class="biil_1">Bill To:</b><br />
          <span class="bill_2"> {{orderDetails[0].USERNAME}} - {{orderDetails[0].PATIENTPHONE}} </span>
          <br />
          <span class="bill_3"> {{orderDetails[0].HNo}}  {{orderDetails[0].Area}} {{orderDetails[0].Landmark}}</span><br />
        
        <!--{{orderDetails[0].Address}}--><br />
          <br />
          <!--{{orderDetails[0].City}}, {{orderDetails[0].State}} ,  {{orderDetails[0].Pincode}}<br />-->
          <!--<div class="strg" style="margin-top: -100px;    margin-left: 894px;"><img src="../../../assets/img/Crlogo.png" /></div>-->
          <b class=" from">Bill From:</b><br />
          <div class="strg_0" style=" text-align: Right !important">Credence</div>
          <div  class="moula"style=" text-align: Right !important">
            4-7, Moula-Ali, Hyderabad -500 040, Telangana,India.
          </div>
          <div class="moula_1"style=" text-align: Right !important">
            Telangana
          </div>

        </td>
      </tr>
      <!-- this code  use for generate the order invoice -->
      <div style="border:none !important">
        <td class="booking">
          <table style="width:100%;">
            <tr style="border:none !important;">
              <td class="td_class1">
                <div><span class="strg_1">Booking Id:</span><span class="ordstrn_1"> {{orderDetails[0].ORDERID}}</span></div>
                <div><span class="strg_2">Booking Date:</span><span class="ordstrn_1"> {{orderDetails[0].ORDERDATE | date:'dd-MMM-yyyy'}}</span></div>
                <div><span class="strg_3">Invoice Date:</span><span class="ordstrn_1"> {{orderDetails[0].ORDERDATE | date:'dd-MMM-yyyy'}}</span></div>
                <div><span class="strg_4">Invoice Time:</span><span class="ordstrn_1"> {{orderDetails[0].ORDERDATE | date:'hh:mm a'}}</span></div>
              </td>
            </tr>
          </table>
        </td>
      </div>
      <tr class="test-code">
        <td class="Item" style="padding:0;">
          <table style="width:100%;">
            <tr style="font-family: 'Poppins';
    font-size: 15px;
    color: #000;
    font-weight: bold;
    letter-spacing: 0.5px;">
              <td class="sno" style="width:5%; ">S No</td>
              <td  class="sno_1"style="width:4%; ">TestCode</td>
              <td class="sno_2" style="width:15%;">TestName/PackageName</td>

              <!--<td style="width:10%;text-align:right">CategoryName</td>-->

              <td class="sno_3" style="width:10%;text-align:right">Price</td>

            </tr>
            <!---  ganerate invoice  fields like orderid testname code price show this fields-->

            <tr *ngFor="let item1 of orderDetails1;let i=index" style="font-family: Arial;
    font-size: 15px;
    color: #000;
    font-weight: normal;
    letter-spacing: 0.5px;">
              <td class="sno_4"  style="width:5%;">{{i+1}}</td>
              <td class="sno_5"style="width:4%;">{{item1.TESTCODE}}{{item1.PACKAGECODE}}</td>
              <td class="sno_6"style="width:15%;">{{item1.TESTNAME}}{{item1.PACKAGENAME}}</td>

              <!--<td style="width:10%;text-align:right">{{item1.CATEGORINAME}}</td>-->
              <!--<td style="width:10%;text-align:right" >₹{{item1.PRICE}}
          {{item1.PacPrice }}</td>-->
            
              <td style="width:15%;text-align:right">
                <div *ngIf="item1.PRICE!=0">

                  ₹{{item1.PRICE}}
                </div>
                <div *ngIf="item1.PacPrice!=0">
                  ₹{{item1.PacPrice}}
                </div>
              </td>
            </tr>
          </table>
        </td>
      </tr>
     
      <tr class="total">
        <td style="padding:0;">
          <table style="width:100%;">
            <tr style="font-family: 'Poppins';
    font-size: 15px;
    color: #000;
    font-weight: bold;
    letter-spacing: 0.5px;">
              <div class="strg_5" style=" text-align: Right !important"> Total MRP:  {{grandTotal |  currency:'INR'}}</div>

            </tr>


          </table>
        </td>
      </tr>


      <tr class="data">
        <td>
          <div class="row" style="font-size:14px;">
            <div class="col-lg-8 services">
              Credence Diagnostics Centre is one of the leading Diagnostic laboratory network in India moving ahead at every step.
              We are currently servicing nearly 2000 doctors & labs all across India. Our vision is to be the undisputed leader in providing world class diagnostic services by maintaining highest ethical standards and quality.
              Year 2014 marks the beginning of a new era for Credence as it is launching web based services.
            </div>
            <div class="col-lg-4 author" style="text-align:right;">
              Authorized Signatory
            </div>
            <P style="margin-left: 20px;  margin-top: 20px;color: #204a48fc;">
              <b> This is computer generated invoice</b>
            </P>


          </div>
        </td>
      </tr>
      <tfoot>



      </tfoot>
    </table>
    <p  class="footer"style="text-align:right">
      <br />
      <!--- its code use for print the order  invoic -->
      <button type="submit" class="btn btn-primary hidden-print print" (click)="print()">Print</button>
      &nbsp;
      <button type="submit" class="btn btn-danger hidden-print cancel"  routerLink="/CustomerOrders">Cancel</button>
    </p>

    <script src="script.js"></script>
  </div>
  </div>
</div>

<style>
  .modal-dialog {
    max-width: 60% !important;
    margin: 1.75rem auto !important;
  }

  .card {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
  }

  .custom.tabs-primary {
    background-color: rgba(62, 80, 180, 0) !important;
    border: 1px solid #ffffff !important;
    COLOR: #000 !important;
  }

  .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.8) !important;
  }

    .nav-item .nav-link.active {
      background-color: #ffffff !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

    .nav-item .nav-link:hover {
      background-color: #9ed9f7 !important;
      border-bottom: 3px solid #9ed9f7 !important;
    }

  .HideDescription {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .strg_c {
    font-family: poppins;
    font-size: 12px;
    color: #000;
    letter-spacing: 0.5px;
    line-height: 1.8;
    text-align: center;
    padding-top: 0;
  }

  .strg {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    letter-spacing: 0.5px;
    text-align: center;
    padding-top: 0.5rem
  }

  .ordstrn {
    font-family: Arial;
    font-size: 15px;
    color: #000;
    font-weight: normal;
    letter-spacing: 0.5px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  #plugin {
    height: 30% !important;
    position: absolute;
    width: 100%;
  }
</style>

