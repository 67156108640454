import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { VERSION } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { MessageService } from 'primeng/api';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
declare var $;
import { Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { window } from 'rxjs/operators';
import { Window } from 'selenium-webdriver';
import { visitValue } from '@angular/compiler/src/util';

@Component({
  selector: 'app-book-test',
  templateUrl: './book-test.component.html',
  styleUrls: ['./book-test.component.scss'],
  providers: [MessageService]
})
export class BookTestComponent implements OnInit {
  categories: any = [];
  a2zTests: any = [];
  a2zTestsList: any = [];
  Tests: any = [];
  selectedProduct: any;
  catID: any;
  searchTests: any;
  a2zTestsListAry: any;
  gridview: boolean = true;
  selectedAlphabet: any;
  currntPacksize: any;
  PTests: any = [];
  TEST_ID: any;
  alphabet: any;
    TESTNAME: any;
    TESTCODE: any;
    CONTAINER: any;
    SAMPLE: any;
    Temperature_of_Sample: any;
    TestShedule: any;
    DESCRIPTION: any;
    PRICE: any;
    Method: any;
    Reporting_Time: any;
  constructor(public router: Router, public generalService: GeneralService, public toastrService: ToastrService, public messageService: MessageService, public cartService: CartService) { }

  ngOnInit(): void {
    this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    if (localStorage.getItem('moreinfo')) {
      var data = JSON.parse(localStorage.getItem('moreinfo'));
      localStorage.setItem('moreinfo', '');
      this.ViewTest(data);
      //this.Back(data);
    }
    this.GetHomepageDetails();
    this.get_A_to_Z_Tests();
   
  }


  //=================Getting HealthPackages data from BackEnd using general service=====================//
  GetHomepageDetails() {
    var UploadFile = new FormData();
    var url = "api/Values/GetHomepageDetails";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.categories = data.catlist;
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  }
  //=================Getting A2Z Tests data from BackEnd using general service=====================//
  get_A_to_Z_Tests() {
    debugger
    var UploadFile = new FormData();
    var UploadFile = new FormData();
    UploadFile.append("Tests1", '');
    UploadFile.append("UserID", '');
    UploadFile.append("TEST_ID", this.selectedAlphabet);
    UploadFile.append("falg", '0');
    var url = "api/Values/Get_TestsDetails";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.PTests = data;
        //this.a2zTests = [];
        //for (var i = 0; i < data.length; i++) {
        //  if (!this.a2zTests.some(e => e.TESTNAME === this.PTests[i].TESTNAME)) {
        //    this.a2zTests.push(this.PTests[i]);
        //  }
        //}
        this.a2zTests = data;    //All Tests
        this.a2zTestsList = data;    //All Tests
        this.a2zTestsListAry = data;    //All Tests
        this.alphabetFilter('A');//Filtering with A by Default when page loads
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  }
  alphabetFilter(term) {
    debugger
    this.selectedAlphabet = term;
    this.a2zTests = Object.assign([], this.a2zTestsList).filter(
      item => item.TESTNAME.charAt(0).toUpperCase().indexOf(term.toUpperCase()) > -1
    )
    // this.get_A_to_Z_Tests();
  }
  //Click on Category filter correspondnig Tests
  clickOnCategory(val) {
    debugger
    this.catID = val;
    this.a2zTests = this.a2zTestsList.filter(a => a.CATEGORYID == this.catID);

  }
  addToCart(item) {
    debugger
    var PACKAGE_ID;
    this.messageService.clear();
    let arr = [];
    arr.push({ TEST_ID: item.TEST_ID, TESTNAME: item.TESTNAME, TESTCODE: item.TESTCODE, PRICE: item.PRICE, ISPACKAGE: false, PACKAGE_ID: PACKAGE_ID })
    this.cartService.addToCart(arr[0]);
    debugger
    if (localStorage.getItem('info') == 'added') {
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Test added to the cart successfully' });
    }
    else if (localStorage.getItem('info') == 'exists') {
      this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Test already added to the cart' });
    }
  }


  /**
 filtering tests method
  **/
  filterTests(event) {
    debugger
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    var UploadFile = new FormData()
    UploadFile.append("Names", this.selectedProduct)
    var url = "api/Values/SearchNames"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.a2zTests = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  filter(event) {
    let filtered: any[] = [];
    let query = event.query;
    //this.a2zTestsList = [];
    //this.a2zTestsList = this.a2zTestsList.filter((item) => {
    //  return (
    //    item.TESTNAME.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.TESTCODE.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
    //    item.TagName.toLowerCase().indexOf(query.toLowerCase()) > -1);
    //})
    this.a2zTestsList = [];
    for (let i = 0; i < this.a2zTests.length; i++) {
      let cat = this.a2zTests[i];
      if (cat.TESTNAME.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(cat);
      }
    }
    this.a2zTestsList = filtered;

  }
  search(ev) {
    debugger
    if (ev) {
      this.a2zTests = this.a2zTestsList.filter(a => a.TESTNAME == ev.TESTNAME);
    }
    else {
      this.a2zTests = this.a2zTestsList
    }
  }
  keyUp(ev) {
    debugger
    // this.a2zTests = this.a2zTestsList.filter(a => a.TESTNAME == ev.TESTNAME);
    this.a2zTests = this.a2zTestsListAry.filter((item) => {
      // return item.TESTNAME.toLowerCase().includes(ev.target.value.toLowerCase());
      // return item.TESTNAME.toLowerCase().includes(ev.target.value.toLowerCase());
      return item.TESTNAME.toLowerCase().startsWith(ev.target.value.toLowerCase())
      //   return (
      //     item.TESTNAME.toLowerCase().indexOf(ev.target.value.toLowerCase()) > -1 || item.TESTCODE.toLowerCase().indexOf(ev.target.value.toLowerCase()) > -1 ||
      //     item.TagName.toLowerCase().indexOf(ev.target.value.toLowerCase()) > -1);
      //})
    });
  }

  //Back(val: any) {
  //  debugger
  //  this.gridview = false;
  
  //  this.Tests = val;

  //}
  return() {
    this.gridview = true;
  }
  ViewTest(val: any) {
    debugger
    this.gridview = false;

    this.Tests = val;

    this.TEST_ID = val.TEST_ID
    //  this.TESTNAME = item.TESTNAME
    //this.TESTCODE = item.TESTCODE
    //this.CONTAINER = item.CONTAINER
    //this.SAMPLE = item.SAMPLE
    //this.Temperature_of_Sample = item.Temperature_of_Sample
    //this.TestShedule = item.TestShedule
    //this.DESCRIPTION = item.DESCRIPTION
    //this.PRICE = item.PRICE
    var UploadFile = new FormData()
    UploadFile.append("Param", val.TEST_ID)
    var url = "api/Values/GetTests_basedon_TestId"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Tests = data
      this.TESTNAME = this.Tests[0].TESTNAME;
      this.TESTCODE = this.Tests[0].TESTCODE;
      this.CONTAINER = this.Tests[0].CONTAINER;
      this.SAMPLE = this.Tests[0].SAMPLE;
      this.Temperature_of_Sample = this.Tests[0].Temperature_of_Sample;
      this.DESCRIPTION = this.Tests[0].DESCRIPTION;
      this.TestShedule = this.Tests[0].TestShedule;
      this.Method = this.Tests[0].Method; 
      this.Reporting_Time = this.Tests[0].Reporting_Time; 

      this.PRICE = this.Tests[0].PRICE;
      

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

 


  }
