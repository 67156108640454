import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { GMapModule } from 'primeng/gmap'
import { GeolocationService } from '@ng-web-apis/geolocation';
declare var google: any;

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss'],
  providers: [MessageService]
})



export class UpdateProfileComponent implements OnInit {
  insertingData: boolean = false;
  image: any;
  lat: any;
  lng: any;
  map: any;
  options: any;
  imagefile: any;
  overlays: any[];

  dialogVisible: boolean;

  markerTitle: string;

  selectedPosition: any;

  infoWindow: any;

  draggable: boolean;

  HomeURL: any;
  logindata: boolean;
  LoginDetails: any;
  ADDRESS_Parts1: {};
  ADDRESS1: any;
  Longitude: any;
  Latitude: any;
  Name: any;
  mobile: any;
  HNo: any;
  Email: any;
  Area: any;
  Landmark: any;
  City: any;
  Pincode: any;
  State: any;
  Section: any;
  constructor(public router: Router, public http: HttpClient,
    public generalService: GeneralService, private toast: MessageService,
    private geolocation: GeolocationService) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    if (this.LoginDetails != null) {
      debugger
      this.logindata = true;
      //this.Name = this.LoginDetails.FarmerName
      //this.mobile = this.LoginDetails.MobileNo

      this.Name = this.LoginDetails.EMP_NAME
      this.mobile = this.LoginDetails.PHONENUMBER
      this.Email = this.LoginDetails.EMAIL
      console.log(this.LoginDetails)
     // this.HNo = this.LoginDetails.HNO
    //  this.Area = this.LoginDetails.AREA
     // this.Landmark = this.LoginDetails.LANDMARK
      this.City = this.LoginDetails.CITY
      this.State = this.LoginDetails.STATE
      this.Pincode = this.LoginDetails.PINCODE


  
    } else {
      this.logindata = false;
    }
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });

    navigator.geolocation.getCurrentPosition(position => {
      var lt = position.coords.latitude
      var ln = position.coords.longitude
      this.lat = (lt) * 1
      this.lng = (ln) * 1
    });

    this.options = {
      center: { lat: this.lat, lng: this.lng },
      zoom: 15
    };
  }


  ngOnInit(): void {
    /*using geolocation for get exact current position by using @ng-web-apis/geolocation Module*/
    navigator.geolocation.getCurrentPosition(position => {
      var lt = position.coords.latitude
      var ln = position.coords.longitude
      this.lat = (lt) * 1
      this.lng = (ln) * 1
      this.initOverlays();
      this.options = {
        center: { lat: this.lat, lng: this.lng },
        zoom: 15
      };
      this.infoWindow = new google.maps.InfoWindow();
      this.getLocation(this.lat, this.lng)
    });

    //navigator.geolocation.getCurrentPosition(resp => {})
  }

  /*Method for Insert customer Address in DB*/
  InsertAdderss(Name, mobile, HNo, Area, Landmark, City, State, Pincode, Section) {
    this.insertingData = true;
    // this.geoCode(HNo + "," + Area + ",") + Landmark + "," + City + "," + State + "," + Pincode;
    if (Name == '' || Name == undefined || Name == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your name.', 'warning')
    }
    else if (mobile == undefined || mobile == undefined || mobile == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your mobile.', 'warning')
    }
    else if (HNo == undefined || HNo == '' || HNo == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your hno.', 'warning')
    }
    else if (Area == undefined || Area == '' || Area == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your area.', 'warning')
    }
    else if (Landmark == '' || Landmark == undefined || Landmark == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your landmark.', 'warning')
    }
    else if (City == undefined || City == '' || City == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your city.', 'warning')
    }
    else if (State == undefined || State == '' || State == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your state.', 'warning')
    }
    else if (Pincode == undefined || Pincode == '' || Pincode == null) {
      this.generalService.ShowAlert('Warning', 'Please enter your pincode.', 'warning')
    }

    else {
      let Address = [{
        Name: Name,
        Mobile: mobile,
        UserId: this.LoginDetails.EMP_ID,
        HNo: HNo,
        Area: Area,
        Landmark: Landmark,
        City: City,
        State: State,
        Pincode: Pincode,
        Class: Section,
        AddressType: 'Home',
        Latitude: this.lat,
        Longitude: this.lng,
        Address: this.ADDRESS1,
        Email: this.Email
      }];
      let formData: FormData = new FormData();
      formData.append('Param', JSON.stringify(Address));
      var url = "api/Values/Add_Customer_Address"
      // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
      this.generalService.PostData(url, formData).then((data: any) => {
        if (data == 'SUCCESS') {
          this.generalService.ShowAlert('Success', 'Your address updated successfully.', 'success')
          this.router.navigate(['Cart'])
          // this.navCtrl.pop();
        }
      });
    }
  }

  /**
   * initially loading method for bindin current customer position
   */
  initOverlays() {
    debugger
    if (!this.overlays || !this.overlays.length) {
      this.overlays = [
        new google.maps.Marker({ position: { lat: this.lat, lng: this.lng }, title: "Current" }),
        //new google.maps.Polygon({
        //  paths: [
        //    { lat: this.lat, lng: this.lng },
        //    { lat: this.lat, lng: this.lng },
        //    { lat: this.lat, lng: this.lng },
        //    { lat: this.lat, lng: this.lng }
        //  ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35
        //}),
        // new google.maps.Circle({ center: { lat: this.lat, lng: this.lng }, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500 }),
        new google.maps.Polyline({ path: [{ lat: this.lat, lng: this.lng }, { lat: this.lat, lng: this.lng }], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2 })
      ];
    }
  }

  /**
   * handleMapClick Method for getting lat and lng by click on map
   * @param event
   */
  handleMapClick(event) {
    debugger
    this.dialogVisible = true;
    this.selectedPosition = event.latLng;
    this.getLocation(this.selectedPosition.lat(), this.selectedPosition.lng())
    this.lat = this.selectedPosition.lat()
    this.lng = this.selectedPosition.lng()
    this.overlays = [
      new google.maps.Marker({ position: { lat: this.lat, lng: this.lng }, title: "Current" }),
      new google.maps.Polygon({
        paths: [
          { lat: this.lat, lng: this.lng }, { lat: this.lat, lng: this.lng }, { lat: this.lat, lng: this.lng },
          { lat: this.lat, lng: this.lng }
        ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35
      }),
      // new google.maps.Circle({ center: { lat: this.lat, lng: this.lng }, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500 }),
      new google.maps.Polyline({ path: [{ lat: this.lat, lng: this.lng }, { lat: this.lat, lng: this.lng }], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2 })
    ];
  }

  /**
   * /Method for ovelay click event on Gmap
   * @param event
   */
  handleOverlayClick(event) {
    let isMarker = event.overlay.getTitle != undefined;
    if (isMarker) {
      let title = event.overlay.getTitle();
      this.infoWindow.setContent('' + title + '');
      this.infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      this.toast.add({ severity: 'info', summary: 'Marker Selected', detail: title });
    }
    else {
      this.toast.add({ severity: 'info', summary: 'Shape Selected', detail: '' });
    }

  }

  /**
   * Gmap Handle Drag End Point @param from gmap
   * @param event
   */
  handleDragEnd(event) {
    this.toast.add({ severity: 'info', summary: 'Marker Dragged', detail: event.overlay.getTitle() });
  }

  /**
   * Add Marker On GMap
   */
  addMarker() {
    this.overlays.push(new google.maps.Marker({ position: { lat: this.selectedPosition.lat(), lng: this.selectedPosition.lng() }, title: this.markerTitle, draggable: this.draggable }));
    this.markerTitle = null;
  }



  /**Get Formated Addreess using this method*
   *@param lat
   * @param long
   */
  getLocation(lat, long) {
    var geocoder = new google.maps.Geocoder();
    this.Latitude = lat; this.Longitude = long;
    let latlng = new google.maps.LatLng(lat, long);

    let request = { latLng: latlng };
    geocoder.geocode(request, async (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let result = results[0];
        this.ADDRESS1 = result.formatted_address;
        this.Pincode = result.address_components.find(x => x.types == 'postal_code').long_name;
        this.State = result.address_components.find(x => x.types[0] == 'administrative_area_level_1').long_name;
        this.City = result.address_components.find(x => x.types[0] == 'locality').long_name;
      }
    })
  }

}
