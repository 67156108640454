
<div class="container">


  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Our Services</li>
    </ol>
  </nav>
  <section id="Biochemistry">
    <div class="container">
      <div class="row">
        <div class="col-lg-2">

          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1">
                <span class="d-flex align-items-center">Biochemistry</span>
              </div>

            </div>
          </div>

        </div>
        <div class="col-lg-10"></div>
        <br /> <br />
        <div class="row">
          <div class="col-lg-5">
            <img style="width:100%" src="../../../assets/img/OurServices/biochemistry.jpg"alt="credence Diagnostics center" />
          </div>
          <div class="col-lg-7">
            <p>Biochemistry is the study of the chemicals that make up life and how they behave. It seeks to explain how inanimate chemicals like carbohydrates and proteins can give rise to living organisms. </p>
            <p>Biochemistry as a scientific discipline began in the 1700s and 1800s, with early studies of phenomena like fermentation and the discovery of the first enzyme. However, it blossomed in the 20th century, thanks in part to new techniques like X-ray crystallography that allowed biochemists to study the precise three-dimensional structures of molecules. </p>
            <p>Perhaps the most famous biochemical molecule is deoxyribonucleic acid or DNA, the material that carries our genes. The structure of DNA was discovered in 1953 after a frantic (and at times disreputable) race. Famously, DNA is a double helix, made up of two strands that coil around each other. Each strand carries a sequence of “letters”, which are the basis of genes. </p>

          </div>


        </div>
        <br />
        <p>In the wake of this discovery, biochemists like Francis Crick realised that the information on DNA is used to make proteins, which are long chains of smaller molecules called amino acids. Proteins are the workhorses of living cells, doing everything from digesting food to pushing waste out of the cell. The long chains fold up into remarkably intricate structures, which are crucial to the proteins’ function. </p>
        <p>However, before proteins can be made the information from DNA is first copied onto a third kind of molecule called RNA (ribonucleic acid), which is similar to DNA. RNA can also act as an enzyme, as proteins do. Its ability to perform so many tasks has led some biochemists to suggest that it played a key role in the origin of life on Earth, before DNA and protein arose. </p>

      </div>
    </div>
  </section>

  <section id="Microbiology">
    <div class="container">
      <div class="row">

        <div class="col-lg-10"></div>
        <div class="col-lg-2">

          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1">
                <span class="d-flex align-items-center">Clinical Pathology</span>
              </div>

            </div>
          </div>

        </div>
        <br /> <br />
        <div class="row">

          <div class="col-lg-7">
            <p>
              Clinical pathology (Medical Laboratory) is a medical specialty that is concerned with the diagnosis of disease based on the laboratory analysis of bodily fluids, such as blood, urine, and tissue homogenates or extracts using the tools of chemistry, microbiology, hematology and molecular pathology. This specialty requires a medical residency.
            </p>
            <p>
              Clinical pathology(Medical Laboratory) is a term used in the US, UK, Ireland, many Commonwealth countries, Portugal, Brazil, Italy, Japan, and Peru; countries using the equivalent in the home language of "laboratory medicine" include Austria, Germany, Romania, Poland and other Eastern European countries; other terms are "clinical analysis" (Spain) and "clinical/medical biology (France, Belgium, Netherlands, North and West Africa
            </p>
          </div>
          <div class="col-lg-5">
            <img style="width:100%" src="../../../assets/img/OurServices/clinical-pathology.jpg" alt="credence Diagnostics center"/>
          </div>


        </div>

      </div>
    </div>
  </section>

  <section id="Microbiology">
    <div class="container">
      <div class="row">
        <div class="col-lg-2">

          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1">
                <span class="d-flex align-items-center"> Microbiology</span>
              </div>

            </div>
          </div>

        </div>
        <div class="col-lg-10"></div>

        <br /> <br />
        <div class="row">
          <div class="col-lg-5">
            <img style="width:100%" src="../../../assets/img/OurServices/Microbiology_H.png" alt="credence Diagnostics center"/>
          </div>
          <div class="col-lg-7">
            <p>
              Micro-organisms and their activities are vitally important to virtually all processes on Earth. Micro-organisms matter because they affect every aspect of our lives – they are in us, on us and around us.

            </p><p>
              Microbiology is the study of all living organisms that are too small to be visible with the naked eye. This includes bacteria, archaea, viruses, fungi, prions, protozoa and algae, collectively known as 'microbes'. These microbes play key roles in nutrient cycling, biodegradation/biodeterioration, climate change, food spoilage, the cause and control of disease, and biotechnology. Thanks to their versatility, microbes can be put to work in many ways: making life-saving drugs, the manufacture of biofuels, cleaning up pollution, and producing/processing food and drink.

            </p><p>
              Microbiologists study microbes, and some of the most important discoveries that have underpinned modern society have resulted from the research of famous microbiologists, such as Jenner and his vaccine against smallpox, Fleming and the discovery of penicillin, Marshall and the identification of the link between Helicobacter pylori infection and stomach ulcers, and zur Hausen, who identified the link between papilloma virus and cervical cancer.
            </p>
          </div>



        </div>

      </div>
    </div>
  </section>

  <section id="Microbiology">
    <div class="container">
      <div class="row">

        <div class="col-lg-9"></div>
        <div class="col-lg-3">

          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1">
                <span class="d-flex align-items-center">Genetics & Molecular Medicine</span>
              </div>

            </div>
          </div>

        </div>
        <br /> <br />
        <div class="row">

          <div class="col-lg-7">
            <p>
              Genetics & Molecular Medicine is an international peer-reviewed, Open Access journal that publishes high-quality articles on the latest advancements and current research in the field of Genetics and Molecular Medicine. It accepts articles from different disciplines including but not limited to: Genetics in medical field, Molecular genetics, Gene therapies, Evolutionary Genetics,Epigenetics, Behavioural genetics, Developmental Genetics, Gene regulation, Heredity genetics, Genetic analysis, Gene expression profiling, Genetic variation, Cellular genetics and molecular biology, Population genetics, Quantitative and computational genetics, Signal transduction, Genome and systems biology, Aging, genetic disorders, etc.

            </p> <p>
            </p> <p>
              Genetics & Molecular Medicine publishes the novel, peer-reviewed Research articles, Case reports, Review articles, Opinion, Letters to the Editor, Editorials, Rapid and Short Communications and many more, in broad sections of molecular medicine, genetics and its applications in medicine by gene therapy.
            </p>
            Our journal strongly supports the Open Access initiative. All published articles will be assigned DOI provided by Cross Ref. Genetics & Molecular Medicine will keep up-to- date with the latest advancements in the feilds of genetics and molecular research. Abstracts and Pdfs of all articles published are freely available to everyone immediately after publication.
          </div>
          <div class="col-lg-5">
            <img style="width:100%" src="../../../assets/img/OurServices/genetics_molecular_bio.jpg" alt="credence Diagnostics center"/>
          </div>


        </div>

      </div>
    </div>
  </section>

  <section id="Microbiology">
    <div class="container">
      <div class="row">
        <div class="col-lg-2">

          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1">
                <span class="d-flex align-items-center"> Histo Cyto Pathology</span>
              </div>

            </div>
          </div>

        </div>
        <div class="col-lg-10"></div>

        <br /> <br />
        <div class="row">
          <div class="col-lg-5">
            <img style="width:100%" src="../../../assets/img/OurServices/Histopathology-and-Cytology.jpg"alt="credence Diagnostics center" />
          </div>
          <div class="col-lg-7">
            <p>
              Histopathology is the diagnosis and study of diseases of the tissues, and involves examining tissues and/or cells under a microscope. Histopathologists are responsible for making tissue diagnoses and helping clinicians manage a patient’s care.
            </p><p>
              Histopathologists are doctors who work closely with other clinical specialties. They can reach a diagnosis by examining a small piece of tissue from the skin, liver, kidney or other organ. This is called a biopsy.
            </p><p>
              They examine the tissue carefully under a microscope, looking for changes in cells that might explain what is causing a patient’s illness. Around 20 million histopathology slides are examined in the UK each year.
            </p>
            <p>
              Histopathologists provide a diagnostic service for cancer; they handle the cells and tissues removed from suspicious ‘lumps and bumps’, identify the nature of the abnormality and, if malignant, provide information to the clinician about the type of cancer, its grade and, for some cancers, its responsiveness to certain treatments
            </p>
          </div>



        </div>

      </div>
    </div>
  </section>

  <section id="Molecular Biology">
    <div class="container">
      <div class="row">

        <div class="col-lg-9"></div>
        <div class="col-lg-3">

          <div class="row">
            <div class="col-md-12">
              <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1">
                <span class="d-flex align-items-center">Molecular Biology</span>
              </div>

            </div>
          </div>

        </div>
        <br /> <br />
        <div class="row">

          <div class="col-lg-7">
            <p>
              Molecular biology /məˈlɛkjʊlər/ is the branch of biology that seeks to understand the molecular basis of biological activity in and between cells, including molecular synthesis, modification, mechanisms, and interactions.[1][2][3] The study of chemical and physical structure of biological macromolecules is known as molecular biology.
            </p> <p>
            </p> <p>
              Molecular biology was first described as an approach focused on the underpinnings of biological phenomena - uncovering the structures of biological molecules as well as their interactions, and how these interactions explain observations of classical biology
            </p><p>
              In 1945 the term molecular biology was used by William Astbury. The development in the field of molecular biology happened very late as to understand that the complex system or advantageous approach would be made in simple way of understanding by using bacteria and bacteriophages this organism yields information about basic biological process more readily than animal cell. In 1953 then two young men named Francis Craig and James Watson working at Medical Research Council unit, Cavendish laboratory, Cambridge, made a double helix model of DNA which changed the whole research scenario they proposed the DNA structure based on previous research done by Rosalind Franklin and Maurice Wilkins then the research lead to finding DNA material in other microorganisms, plants and animals
            </p>
          </div>
          <div class="col-lg-5">
            <img style="width:100%" src="../../../assets/img/OurServices/molecular_biology.jpg"alt="credence Diagnostics center" />
          </div>


        </div>

      </div>
    </div>
  </section>
</div>
