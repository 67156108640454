import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {
  @Output() searchValueChanged: EventEmitter<string> = new EventEmitter<string>();
  searchText: string;
  constructor() { }

  ngOnInit(): void {
  }
  changedSearchText(): void {
    debugger
    // emit the change so the parent component can see it
    this.searchValueChanged.emit(this.searchText);
 
  }
}
