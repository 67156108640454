<!--<div class="container">-->
<div class="container" *ngIf="Show">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Customer Orders</li>
    </ol>
  </nav>
  <h4 style="color:#2a7693"><b>My Bookings</b></h4>
  <div class="row">
    <div class="col-lg-6" *ngFor="let item1 of Orders;let i=index">
      <div class="card" style="margin-bottom:1%;">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h5>
              {{item1.CREATEDDATE | date:'dd-MMM-yyyy H:m'}}
            </h5>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <h5 class="text-right">
              {{item1.ORDERID}}
            </h5>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-5"></div>
          <div class="col-lg-7">
              <h1 class="cardItems1">
                <h6>Order Amount:{{item1.AMOUNT}}/-</h6>
              </h1>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5">
            <h2 class="cardItems">{{item1.STATUS}}</h2>
          </div>
          <div class="col-md-7 text-right">
            <button mat-button color="primary" style="height:auto;" (click)="navigateToInvoice(item1.ORDERID)" class="btn btn-info btn-rounded">Invoice </button>
            <button mat-button color="accent" style="height:auto;" (click)="ShowReports(item1.ORDERID)" class="btn btn-danger btn-rounded">Reports</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!Show">
  <div class="row">
    <button class="btn btn-danger" style="margin:10px 50px;" name="back" (click)="Back()"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i> Back</button>
  </div>
  <div *ngFor="let item of Reports" style="margin-top:10px;margin-left:50px;">
    <a href="{{HomeURL+item.ReportsPath}}" target="_blank">
      <i class="fa fa-file-pdf-o" style="color: #ed1c24;" aria-hidden="true"></i>&nbsp; View Report
    </a>
  </div>
</div>
<p-toast position="top-Right"></p-toast>
