<div class="container">
  <p-toast position="top-right"></p-toast>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Book A Test</li>
    </ol>
  </nav>

  <div class="row ">
    <div class="col-md-3">
      <div class="card mb-4">
        <div class="card-header"><b style="color:black">Categories</b></div>
        <div class="list-group list-group-flush" *ngFor="let item of categories" (click)="clickOnCategory(item.CaregoryNo)" style="cursor:pointer">
          <a *ngIf="item.CaregoryNo==catID" class="list-group-item list-group-item-action active">{{item.CategorysName}}</a>
          <a *ngIf="item.CaregoryNo!=catID" class="list-group-item list-group-item-action">{{item.CategorysName}}</a>
        </div>
      </div>
    </div>
    <!-- this code   use for  search the tests and test code or Tagname we  also clik the test then open test details and add to cart fanctionality -->
    <div class="col-md-9">
      <h4 style="font-weight:700">Home Sample Collection Hyderabad - Book an Appointment Online</h4>
      <div class="card" *ngIf="gridview">
        <div class="card-header">
          <div class="row">

            <div class="col-lg-8">
              <!--<p-autoComplete placeholder="Search.." [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" (onKeyUp)="keyUp($event)" (onSelect)="search($event)" [showEmptyMessage]="true" [suggestions]="a2zTestsList" (completeMethod)="filter($event)" field="TESTNAME" [minLength]="1"></p-autoComplete>-->
              <i class="fa fa-search"></i>&nbsp;
              <p-autoComplete placeholder=" Search Lab tests ...!" [inputStyle]="{'width':'100%'}" [(ngModel)]="selectedProduct" [suggestions]="searchTests" (completeMethod)="filterTests($event)" field="TESTNAME" [dropdown]="false" class="lab">


              </p-autoComplete>
            </div>
            <div class="col-lg-4" style="text-align:right">
              <span class="input-group-text"><b>Total tests found: {{a2zTests.length}}</b></span>

            </div>
          </div>

        </div>
        <div class="card-body">
          <!--<ul class="overflow">
        <li [ngClass]="{'testHead1':item==selectedAlphabet,'testHead':item !=selectedAlphabet}" style="cursor: pointer;" (click)="alphabetFilter(item)" *ngFor="let item of alphabet">{{item}}</li>
      </ul>-->
          <ul class="overflow">
            <li [ngClass]="{'testHead1':item==selectedAlphabet,'testHead':item !=selectedAlphabet}" style="cursor: pointer;" (click)="alphabetFilter(item)" *ngFor="let item of alphabet">{{item}}</li>
          </ul>
          <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-6" *ngFor="let item of a2zTests">
              <div class="card" (click)="ViewTest(item)" style="cursor:pointer;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;">
                <!--(click)="Back(item)"-->
                <div class="card-body">
                  <h5 class="card_title" *ngIf="item.Name!=''">{{item.TESTNAME}}</h5> <!--- ({{item.Name}})-->
                  <h5 class="card_title" *ngIf="item.Name==''">{{item.TESTNAME}}</h5>
                  <p style="font-size: 8px;margin-top: -8px;margin-bottom: 0px;">Tag: {{item.TagName}}</p>
                  <div class="pacCard">
                    {{item.TESTCODE}}
                  </div>
                  <p class="imm">
                    <i class="fa fa-angle-double-right pacIcion" aria-hidden="true"></i>
                    {{item.SAMPLE}}
                  </p>


                  <div class="row">
                    <div class="col-lg-6 mrp">
                      <b>Rs - ₹ {{item.PRICE}}</b>
                    </div>
                    <div class="col-lg-6" style="text-align:end;cursor:pointer" (click)="addToCart(item)">
                      <i class="fa fa-cart-arrow-down pacCart" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- this code book a test gridview once we clik the home page test to redirect this page  -->
      <div *ngIf="!gridview">


        <div class="row">
          <div class="col-lg-2 ">
            <i class="fa fa-arrow-circle-left" (click)="return()" aria-hidden="true" style="font-size: 31px;padding-left: 10px;cursor: pointer;"></i>
          </div>
          <h4 class="test"> <b>Test Details</b></h4>

        </div>




        <div class="card" style="color:#220202">
          <br />


          <div class="col-md-12">
            <div class="decCard">

              <div class="row">


                <div class="col-lg-12"><h4 class="mainHeader" style="color:darkred">{{TESTNAME}}</h4></div>
                <!--<div class="col-lg-2">
              <i class="fa fa-cart-arrow-down pacCart" aria-hidden="true" style="cursor:pointer;font-size: 32px;color: #d43900; margin-left: 10px;" (click)="addToCart(Tests)"></i>
            </div>-->
              </div>

              <div class="pacCard">

                {{TESTCODE}}
              </div>
              <div class="aboutFont">
                Container<p class=" tests"> :</p>
                <div class="aboutFont_0">{{CONTAINER}}</div>
              </div>
              <div class="ratefont4">
                Sample<p class=" tests"> :</p>
                <div class="ratefont_4">{{SAMPLE}}</div>
              </div>
              <div class="ratefont3">
                Temperature <p class=" tests"> :</p>
                <div class="ratefont_3">{{Temperature_of_Sample}}</div>
                <div class="ratefont2">
                  Test Shedule <p class=" tests"> :</p>
                  <div class="ratefont_2">{{TestShedule}}</div>
                </div>
                <div class="ratefont1">
                  Method<p class=" tests"> :</p>
                  <div class="ratefont_1">{{Method}}</div>
                </div>
                <div class="ratefont0">
                  Reporting_Time<p class=" tests"> :</p>
                  <div class="ratefont_0">{{Reporting_Time}}</div>
                </div>
                <br />


                <div class="container">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="ratefont">
                        <b style="margin-left: -14px;">Price(₹)</b> :&nbsp; <b style="color:red;font-size: 20px;">{{PRICE}}</b>
                      </div>
                    </div>
                    <div class="col-lg-6">


                      <h5 *ngFor="let item of Tests" (click)="addToCart(item)" class="add">
                        <div class="pacCard" style="font-size: 21px;">
                          <b>Add to Cart</b>&nbsp;&nbsp;
                          <i class="fa fa-cart-arrow-down"></i>
                        </div>
                      </h5>

                    </div>
                  </div>
                </div>

                <br />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</div>





