<div class="container">



  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Genetics & Molecular Medicine</li>
    </ol>
  </nav>


  <!--<br />

  <br /> <br />-->
  <h1>Genetics & Molecular Medicine</h1>
  <div class="row">
    <div class="col-lg-5">
      <img style="width:100%;border-radius:10px;box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;" src="../../../assets/img/OurServices/genetics_molecular_bio.jpg" alt="Credence Diagnostics Centre"/>
    </div>
    <div class="col-lg-7">
      <p>
        <b> Genetics </b> is the scientific study of genes and their inheritance, which includes how traits are passed from parent to
        offspring. A gene is a part of the DNA segment that contains instructions for functioning of the body. Changes in a gene can cause
        its function to alter, which leads to diseases, since genes are in inherited these types of diseases tend to cluster in families
        just like traits that are passed down. Increasingly sophisticated scientific equipment is now allowing us to understand the
        fundamentals of genes and their functions, like whether or not they are active in a genome, the ability of DNA to repair the
        damage caused by external factors, and the emergence of disease.

      </p>
      <p>
        Credence Diagnostic Centre is an authorized Genetic Clinic and Counseling Centre (PNDT registration [154/2020], DM&HO, Govt.
        of India). The Department was established in 2017 and provides services such as Genetic Testing for patients and Genetic
        Counseling. Genetic evaluation, pre and post-test counseling, genetics testing for chromosomal abnormalities, single or multiple
        gene diseases and inborn errors of metabolism. Genetic Counseling provides information on how genetic diseases can affect an
        individual or an entire family.  The genetic facility is equipped with Real time PCR systems, High resolution Sequencers, High
        powered fluorescent Microscope, Computer enhanced image capturing system and automated DNA extraction systems for error free and
        accurate diagnostic procedure. The genetic counselor collects personal and family health history, then tries to determine the
        likelihood of inheriting a genetic condition and test required to screen for a particular disease.  Counseling can be required by
        couples planning for a pregnancy, couples with history of infertility related issues, how to manage a child that is showing signs
        of a genetic disorder and to maintain overall health, individuals affected with cancer or family history of cancers, couple with
        family history of known genetic conditions.
        Trained several graduate and postgraduate students in Cytogenetic, FISH and Molecular techniques.
      </p>
      <b>ACTIVITIES OF THE DEPARTMENT: </b><br /><br />
      <b>➣</b> Cytogenetic and Molecular Diagnostic testing for various genetic disorders<br />
      <b>➣</b> Genetic counseling is provided by Certified Genetic Counselors to individuals and families.<br />
      <b>➣</b> Basic to translational research activities are carried out and the institution is recently recognized as PhD centre
      from Osmania University.
    </div>



  </div>






</div>
