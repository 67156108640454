<section id="Biochemistry">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
        <li class="breadcrumb-item bread_active" aria-current="page">Biochemistry</li>
      </ol>
    </nav>

    <!--<div class="row">
    <div class="col-lg-2">

      <div class="row">
        <div class="col-md-12">


        </div>

      </div>
    </div>

  </div>-->
    <!--<div class="col-lg-10"></div>
  <br /> <br />-->
    <h1>Biochemistry</h1>
    <div class="row">
      <!--<h1>Biochemistry</h1><br />-->
      <div class="col-lg-5">

        <img style="width:100%;border-radius:10px;box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;" src="../../../assets/img/biochemistry.jpg"alt="Credence Diagnostic Centre" />

      </div>
      <div class="col-lg-7">
        <p>
          <b>Biochemistry</b> is a branch of both life science and a chemical science – it is the application of chemistry to the study of
          biological processes at the cellular and molecular level. it explores the chemistry of living organisms and the molecular
          basis for the changes occurring in living cells. It uses the methods of chemistry,<b>
            "Biochemistry has become the foundation
            for understanding all biological processes”
          </b>
        </p>
        <p>
          It emerged as a distinct discipline around the beginning of the 20th century when scientists combined chemistry,
          physiology, and biology to investigate the chemistry of living systems. It uses the methods of chemistry, physics,
          molecular biology, and immunology to study the structure and behaviour of the complex molecules found in biological material
          and the ways these molecules interact to form cells, tissues, and whole organisms. Biochemistry seeks to unravel the complex
          chemical reactions that occur in a wide variety of life forms, it provides the basis for practical advances in medicine,
          veterinary medicine, agriculture, and biotechnology. 
        </p>

      </div>


    </div>
    <br />
    <p>

      The biochemist seeks to determine how specific molecules such as proteins, nucleic acids, lipids, vitamins, and hormones function
      in such processes. Particular emphasis is placed on the regulation of chemical reactions in living cells.
    </p>
    <p>At CDC all the Biochemical parameters are analysed by following the standard operating procedures.</p>
  </div>
</section>
