<div class="container">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">News Details</li>
    </ol>
  </nav>
  <div class="  card" style="margin-bottom:1%;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;" *ngFor="let item of Blogs">
    <div class="row">
      <!-- new title -->
      <h2 style="color:#3498db; margin-left: 29px;font-weight: bold; font-size: 18px;">{{item.Title}}</h2>
      <div class="col-lg-12" style="text-align:start;margin-left: 16px;
    font-style: initial!important;
    color: #b93956;"><i><b style=" font-style: initial!important">{{item.CreatedDate | date:'dd-MMM-yyyy'}}</b></i></div>
    </div>
    <!--this code use for  onnce insert to image  on admin it s will show in fronted-->
    <div class="row">
      <div class="col-lg-6">
        <img src="{{HomeUrl+item.Image}}" alt="credence Diagnostics center"class="card_image news">
      </div>


      <div class="col-lg-6">

        <div class="HideDescription" style="color:#000; margin-left: 25px;font-size: 14px;letter-spacing: 0.5px;margin-top: 22px;" [innerHtml]="item.Description"></div>
      </div>

      <br />
    </div>
    <br />
  </div>
</div>
