
<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item" style="cursor:pointer"><a routerLink="/HealthPackages">HealthPackages</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">{{packageName}}</li>
    </ol>
  </nav>
  <div class="customContent noShadow mt-0">
    <div class="row mb-5">
      <div class="col-sm-12 col-md-12 col-lg-8">
        <div class="fmList">
          <h2 class="headingTitle ">
            <span class="mL20 heart-icon-pd pointer"></span>
          </h2>

          <div class="altSec mb-3 pb-3">
            <div>

              <div class="row">
                <div class="col-lg-3">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1">
                        <span class="d-flex align-items-center">Package Details</span>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="col-lg-9"></div>

              </div>
              <br />

              <div class="card">
                <div class="row ">
                  <div class="col-lg-6"><p class="title_1"> Package Name</p><p class="title_2">{{healthPackages[0].PACKAGENAME}}</p></div>
                  <div class="col-lg-6"><p class="title_3"> Package Code</p><p class="title_4">{{healthPackages[0].PACKAGECODE}}</p> </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-lg-6"><p class="title_1"> Package Price</p><p class="title_2" style="color:#ec3a5e">₹{{healthPackages[0].PRICE}}</p></div>
                  <div class="col-lg-6"><p class="title_3"> Discount (%)</p><p class="title_4">{{healthPackages[0].Discount}}</p> </div>
                </div>


              </div>

              <span class="paknm">Tests Includes <span class="nots">{{healthPackages[0].PACKAGEDESCRIPTION1.length}} </span> <sup class="info"></sup></span>
              <br />  <br />
              <div class="tt_2">
           
                <div *ngFor="let item of testsInclude" class="chip">
                  <br />
                  {{item}}
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <!-- this code use for   to click  the know more button it will be show the  package details
          and its show includes and price  andd  addto cart  otherwise he will be continue with  add the test to click continue its redirect to home page   -->

      <div class="col-md-4 card_m_l">

        <div class="card">
          <div class="card-header1">
            <div class="">COMPLETE CARE  WITH SMART REPORT</div>

          </div><br />
          <div class="parameter-value"><strong>{{healthPackages[0].PACKAGENAME}} </strong> </div>
          <div class="card-body">
            <div class="row">

              <table style="width: 100%;">
                <tr>
                  <td>
                    <p class="size">
                      Total Amount
                    </p>
                  </td>
                  <td> <p class="float"> ₹{{healthPackages[0].PRICE}}</p></td>
                </tr>
              </table>
            </div>
            <br />
            <div style="z-index:99;position:relative; ">
              <button type="submit" class="btn solidBtnSm redButton width230 addto" (click)="addToCart()" >Add to Cart</button>
              <button type="submit" class="btn bdrBtn redTxt width230 morepackage" (click)="goToHealthPacks()">+ Add more Packages</button>
            </div>
          </div>
        </div>
        <div class="container">
        <div class="card" >
          <b> Sample Report</b><br />
                               <a href="../../../assets/img/PSaritha1.jpg"  target="_blank" >
                               <!--<a href="../../../assets/img/P Saritha-Female41 years-78052.pdf">-->
                                 <!--<a href="../../../assets/img/Somamma-Female80 yrs-14698.pdf" target="_blank">-->
                                 <!--<img src="../../../assets/img/reports.png" />-->
                                 <!--<img src="../../../assets/img/PSaritha1.jpg" />-->
                                 <img src="../../../assets/img/Saritha.JPG" class="sample" />
                               </a>
        </div>
          </div>
      </div>     
    </div>
  </div>
</div>
<p-toast position="top-right"></p-toast>


