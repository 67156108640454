

<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Cart </li>
    </ol>
  </nav>
  <!--<app-breadcrumb></app-breadcrumb>-->

  <div class="row">
    <div class="col-md-8" >
      <div class="card" >
        <div class="card-header" style="    background-color: #3498db !important;
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.3px;">
          <div class="red" *ngIf="CartLength!=0">  ORDER SUMMARY ({{CartLength}})</div>
          <div class="row" *ngIf="CartLength==0">
            <span  class="cart_1">NO ITEMS IN YOUR CART</span>
          </div>

        </div>
        <div class="card-body">
          <div class="row " style="border-bottom: 1px solid #c1c1c1;" *ngFor="let menu of cartProducts; let i = index">
            <div class="col-lg-12 maniHeader">{{menu.TESTNAME}}</div>
            <div class="col-lg-4">
              <div class="pacCard1">{{menu.TESTCODE}}</div>
            </div>
            <div class="col-lg-4">
              <div class="">{{menu.PRICE | currency:'INR'}}</div>
            </div>
            <div class="col-lg-4" style="text-align:right">
              <div class="" style="font-size: 17px;cursor:pointer;
    color: red;"><i class="fa fa-trash-o" (click)="RemoveItems(i, menu)"> </i></div>
            </div>

          </div>


          <br />
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 button_p" style="padding-right:0px;padding-top: 20px;">
            <button type="button" class="btn btn-primary class1" (click)="Continue()">Continue to add new health check up/Lab test</button>
          </div>

        </div>
      </div>
    </div>

    <div class="col-md-4 card_m_l" style="padding-left:0px;">
      <div class="card">
        <div class="card-header_1" >
          <div class=""> PAYMENT DETAILS</div>

        </div>
        <div class="card-body">

          <div class="row">

            <table style="width: 100%;">
              <tr>
                <td>
                  <p class="size">
                    Total Amount
                  </p>
                </td>
                <td> <p class="float">  {{TotalAmount | currency:'INR'}}</p></td>
              </tr>
            </table>
          </div>

          <div class="row">
            <table style="width: 100%;">
              <tr>
                <td>
                  <p class="size">
                    Offer Price
                  </p>
                </td>
                <td> <p class="float">  {{offerPrice | currency:'INR'}}</p></td>
              </tr>
            </table>
          </div>


          <div class="row">
            <table style="width: 100%;">
              <tr>
                <td>
                  <p class="g_total">
                    Grand Total

                  </p>
                </td>
                <td>   <p class="float" style="font-size:20px;">  {{TotalAmount| currency:'INR'}}</p></td>
              </tr>
            </table>
          </div>



          <br />
         <!-- this code use for add to  address and once customer place the order to customer select any one address  -->
          <div *ngIf="loginStatus">
            <div *ngFor="let item of addressDetails; let i=index" class="addcartCard">
              <div class="card">
                <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.Id}}" (change)="getSelectedAddress(item.Id)" style="width: 18px;height: 18px;" /> <b style="margin-left: 25px;margin-top: -20px;">My Address {{i+1}}</b>
                 <button mat-button color="accent" style="height: 23px; margin-left: 212px;margin-top: -18px;width: 69px;background-color: #046804;"   routerLink="/UpdateProfile" class="btn btn-primary btn-rounded"><p style="font-size: 9px;margin-left:-5px">Add Address</p>Add Address</button>
                <i class="fa fa-trash" (click)="deleteaddress(i,item.Id)" style="color:red;margin-left: 301px;margin-top: -23px;font-size: 20px;"></i>

                <div class="ratefont" style="margin-left: 10px;">

                  <!--{{item.Address}}-->
                  {{item.Area}}, {{item.HNo}}, {{item.Landmark}}, {{item.City}}, {{item.State}}, {{item.Pincode}}
                  
                </div>
              </div>
            </div>
            <br />
          </div>
          <!-- -->
          <div style="z-index:99;position:relative; ">
            <button *ngIf="loginStatus" type="button" class="btn btn-primary class_1" (click)="CheckOut()">Proceed</button>
            <button *ngIf="!loginStatus" type="button" class="btn btn-primary class_1"  routerLink="/Login">Proceed</button>
            <!--<button [disabled]="CartLength==0" class="btn btn-primary class1" (click)="CheckOut()" type="button">Proceed</button>-->
            <!-- <button class="btn btn-primary class1" (click)="PalceOrder('Online')" type="button">Check Out</button>-->
          </div>






        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="myModal5">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header state modal-primary"style="background-color:dodgerblue;">
          <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Place Order</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"style="color:white;">×</span></button>
        </div>
        <div class="modal-body" style="padding-top:0px;margin-top:-10px;text-align:left;">
          <br />
          <p-radioButton name="groupname" label="Online Payment" value="Online" [(ngModel)]="selectedValue"></p-radioButton>
          &nbsp; &nbsp;
          <p-radioButton name="groupname" label="Cash on Delivery" value="Offline" [(ngModel)]="selectedValue"></p-radioButton>
          <br /> <br />
          <div>
            <button *ngIf="selectedValue" style="float:right" class="btn btn-primary" (click)="PalceOrder(selectedValue)" type="submit">Place Order</button>
          </div>
          <!--<form [formGroup]="form" (ngSubmit)="PalceOrder(form.value,selectedValue)">
            <div class="row">
              <div class="col-lg-12">
                <div class="bank_t">
                  <label>Address <span [ngStyle]="{'color':'red'}">*</span></label>
                  <div class="form-group">
                    <input [formControl]="Address" placeholder="Address" class="form-control validation-field margin" type="text" style="text-transform:capitalize">
                    <small class="text-danger" *ngIf="form.get('Address').touched">
                      <span *ngIf="form.get('Address').hasError('required')">Address is required</span>

                    </small>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">

                <div class=" bank_t">
                  <label>City <span [ngStyle]="{'color':'red'}">*</span></label>
                  <div class="form-group">
                    <input [formControl]="City" placeholder="City" class="form-control validation-field margin" type="text" style="text-transform:capitalize">
                    <small class="text-danger" *ngIf="form.get('City').touched">
                      <span *ngIf="form.get('City').hasError('required')">City is required</span>
                    </small>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">

                <div class=" bank_t">
                  <div class="form-group">
                    <label>Area <span [ngStyle]="{'color':'red'}">*</span></label>
                    <input [formControl]="Area" class="form-control validation-field margin" style="text-transform:capitalize;" placeholder="Area" type="text">
                    <small class="text-danger" *ngIf="form.get('Area').touched">
                      <span *ngIf="form.get('Area').hasError('required')">Area is required</span>

                    </small>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">

                <div class=" bank_t">
                  <label>LandMark <span [ngStyle]="{'color':'red'}">*</span></label>
                  <div class="form-group">
                    <input [formControl]="LandMark" class="form-control validation-field margin" style="text-transform:capitalize;" placeholder="LandMark" type="text">
                    <small class="text-danger" *ngIf="form.get('LandMark').touched">
                      <span *ngIf="form.get('LandMark').hasError('required')">LandMark is required</span>

                    </small>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">

                <div class=" bank_t">
                  <label>Pincode <span [ngStyle]="{'color':'red'}">*</span></label>
                  <div class="form-group">

                    <input [formControl]="Pincode" class="form-control validation-field margin" placeholder="Pincode" type="text" maxlength="6">
                    <small class="text-danger" *ngIf="form.get('Pincode').touched">
                      <span *ngIf="form.get('Pincode').hasError('required')">Pincode is required</span>
                      <span *ngIf="form.get('Pincode').hasError('minlength')">Pincode Number Shouldbe Only 6 Numbers</span>
                      <span *ngIf="form.get('Pincode').hasError('maxlength')">Pincode Number Shouldbe Only 6 Numbers</span>

                    </small>
                  </div>
                </div>
              </div>




            </div>



            <div class="col-lg-offset-9">
              <button style="float:right;background-color:#00adef!important; color:white!important;border-color:#2d3f9e!important;width:80px!important" [disabled]="!form.valid" class="btn btn-outline-warning w-100p mb-1 mr-1" type="submit">Submit</button>
            </div>

          </form>-->
        </div>
      </div>
    </div>
  </div>



  <!--<p-toast position="top-center"></p-toast>-->

</div>
