<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active"  aria-current="page">OrgansTests</li>
      <!--<li class="breadcrumb-item bread_active" aria-current="page">{{packageName}}</li>-->
    </ol>
  </nav>
  <section id="WhatIsAllergy" class="section_wia">
    <div class="container">
      <ul class="nav nav-tabs">
        <li class="active"><a href="#tab1" data-toggle="tab" aria-expanded="true"><span>Overview</span></a></li>
        <li class=""><a href="#tab2" data-toggle="tab" aria-expanded="false"><span>Symptoms</span></a></li>
        <li class=""><a href="#tab3" data-toggle="tab" aria-expanded="false"><span>Are you at risk for {{organName}} Disease?</span></a></li>
        <li class=""><a href="#tab4" data-toggle="tab" aria-expanded="false"><span>Types of {{organName}} Disease</span></a></li>
        <li class=""><a href="#tab5" data-toggle="tab" aria-expanded="false"><span>Diagnosis</span></a></li>
        <li class="" (click)="organTests()"><a href="#tab6" data-toggle="tab" aria-expanded="false"><span>Tests</span></a></li>
        <li class=""><a href="#tab7" data-toggle="tab" aria-expanded="false"><span>Prevention</span></a></li>

      </ul>
     
      <div class="tab-content">
        <div class="col-md-3" *ngFor="let item of organBasedTestsList;let i=index">
          <img src="{{HomeUrl+item.Image}}"alt="credence Diagnostics center" class="card_image" style="width: 100%;   margin-left: -1px;"> <b class="organname">{{organName}}</b>
        </div>
        <div class="tab-pane active in" id="tab1" [innerHtml]="organBasedTestsList[0].OverView">
          <div class="tab_cont">
            <h1>Overview</h1>
            <!--<div>{{organBasedTestsList[0].OverView}}</div>-->

          </div>
        </div>

        <div class="tab-pane fade" id="tab2" [innerHtml]="organBasedTestsList[0].Symptoms">
          <div class="tab_cont2">
            <h1>Symptoms</h1>
            <p>The following symptoms are early warning signs of developing {{organName}} disease:</p>

            <ul class="list1">
              <!--<li>{{organBasedTestsList[0].Symptoms}} </li>-->
            </ul>

          </div>
        </div>

        <div class="tab-pane fade" id="tab3" [innerHtml]="organBasedTestsList[0].AtRisk">
          <div class="tab_cont">
            <h1>Are you at risk {{organName}} disease?</h1>
            <p>The major risk factors for {{organName}} disease are:</p>
            <ul class="list1">
              <!--<li>{{organBasedTestsList[0].AtRisk}} </li>-->
            </ul>
          </div>
        </div>

        <div class="tab-pane fade" id="tab4" [innerHtml]="organBasedTestsList[0].TypesOfDiseases">
          <div class="tab_cont">
            <h1>Types of {{organName}} Disease</h1>
            <ul class="list1">
              <!--<li>{{organBasedTestsList[0].TypesOfDiseases}} </li>-->
            </ul>
          </div>
        </div>

        <div class="tab-pane fade" id="tab5" [innerHtml]="organBasedTestsList[0].DiagnosisAndTests">
          <div class="tab_cont">
            <h1>Diagnosis</h1>
            <ul class="list1">
              <!--<li>{{organBasedTestsList[0].DiagnosisAndTests}} </li>-->
            </ul>
          </div>
        </div>


        <div class="tab-pane fade" id="tab6">
          <div class="tab_cont">
            <!--<h1>Tests</h1>-->
            <!--This is used to bind the A2Z dynamic tests.-->
            <div class="row mb-5">
              <div class="col-md-3" *ngFor="let item of a2zTestsList;let i=index">
                <div class="card" style="">
                  <div class="card-body">
                    <h5 class="card-title">{{item.TestName}}</h5>
                    <div class="pacCard">
                      {{item.TESTCODE}}
                    </div>
                    <p>
                      <i class="fa fa-angle-double-right pacIcion" aria-hidden="true"></i>
                      {{item.SAMPLE}}
                    </p>

                    <div class="row">
                      <div class="col-lg-6">
                        <b>Rs - ₹ {{item.PRICE}}</b>
                      </div>
                      <div class="col-lg-6" style="text-align:end" (click)="addToCart(item)">
                        <i class="fa fa-cart-arrow-down pacCart" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="tab7" [innerHtml]="organBasedTestsList[0].Prevention">
          <div class="tab_cont">
            <h1>Prevention</h1>
            <ul class="list1">
              <!--<li>{{organBasedTestsList[0].Prevention}} </li>-->
            </ul>
          </div>
        </div>

      </div>
    </div>
  </section>

</div>



<p-toast position="top-right"></p-toast>
