import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { VERSION } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { DataService } from '../../Services/data.service';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from "rxjs";
declare var $;
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  providers: [MessageService]
})


export class PageHeaderComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
 form: FormGroup;
  logindata: any;
  // Define a variable to use for showing/hiding the Login button
  isUserLoggedIn: boolean;
  selectedItem: any;
  loggedIn: boolean = false;
  Categories: any;
  Name: any;
  Mobile: any;
  EnquiryAry: any = [];
  formGroup: any;
  constructor(private dataSharingService: DataService, public messageService: MessageService,  public fb: FormBuilder,public router: Router, public generalService: GeneralService, public cartService: CartService) {
    debugger
    this.isLoggedIn = dataSharingService.isLoggedIn();
   
    debugger

   

    this.form = this.fb.group({
      'Name': ['', Validators.required],
      'Mobile': ['', Validators.compose([Validators.required, Validators.pattern(/^[6-9]\d{9}$/)])],
      
    });
    this.logindata = JSON.parse(localStorage.getItem("LoginDetails"));
    if (this.logindata != '' && this.logindata != null && this.logindata != undefined) {
      this.loggedIn = true;
    }
    else{
      this.loggedIn = false;
    }

  }
  items$ = this.cartService.items$;

  ngOnInit(): void {
    window.onscroll = function () { myFunction() };
    //console.log(this.logindata);
    var header = document.getElementById("myHeader");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    this.getAllCategories();
  }
  Logout() {
    debugger   
    localStorage.removeItem("LoginDetails");
    this.loggedIn = false;
    this.dataSharingService.logout();

    this.router.navigateByUrl('/Home');
    //window.location.reload();
  }
  LoginPage() {
    debugger
    this.router.navigateByUrl('/Login');   
  }
  getAllCategories() {
    debugger
    var url = 'api/Values/GetCategories';
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.Categories = [];
      this.Categories = data;

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }

  FilterList(data) {
    debugger
    var carName = ""; var catarr = []
    //this.ngOnInit()
    catarr = this.Categories.filter(A => A.CATEGORINAME == data)//Filtering Categories data based on selected chemical name for getting category id    
    var str = data.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      carName += "_" + str[i];
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/Instruments/' + carName]);
    
  }

  View() {
    debugger
    $('#myModal7').modal('show');
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  //Submit() {
  //  this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Thank You for Contact  My team will be reach you soon' });
  //  this.Name = "";
  //  this.Mobile = "";
  //}
  Submit(form: any) {
  
    debugger;

    this.EnquiryAry = []; this.EnquiryAry.push({ Name: form.Name, Mobile: form.Mobile,  })
    var UploadFile = new FormData();
    UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
    var url = "api/Values/RequestAcallBack"
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;

      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Thank You for Contact  My team will be reach you soon' });
      this.form.reset();
    });

  }


}
