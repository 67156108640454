import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { VERSION } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
declare var $: any
import { DataService } from '../../Services/data.service';
import { Observable } from "rxjs";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
  addressId: any;
  cartIndex: any;
  cartProducts: any = [];
  CartLength: number = 0;
  addressDetails: any;
  offerPrice: number = 0;
  grandTotal: number = 0;
  loginStatus: boolean;
  selectedValue: any;
  admin: any = 8106754546 ;
  
  public form: FormGroup;
  public Pincode: AbstractControl
  public LandMark: AbstractControl;
  public Address: AbstractControl;
  public Area: AbstractControl;
  public City: AbstractControl;
  xml2: any = [];
  TotalAmount: number;
  Logindetails: any;
    order: any;

  constructor(private dataSharingService: DataService, fb: FormBuilder, public formBuilder: FormBuilder, private messageService: MessageService,
    public generalService: GeneralService,public cartService: CartService, public router: Router) {
    debugger
    this.Logindetails = JSON.parse(localStorage.getItem('LoginDetails'));
    this.cartProducts = JSON.parse(localStorage.getItem('products'));
    this.isLoggedIn = dataSharingService.isLoggedIn();
    if (this.cartProducts) {
      this.CartLength = this.cartProducts.length;
    }
    this.form = fb.group({
      Pincode: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
      LandMark: ['', Validators.required],
      Address: ['', Validators.required],
      Area: ['', Validators.required],
      City: ['', Validators.required],
    });
  }
  items$ = this.cartService.items$;

  ngOnInit(): void {
    this.calAmount();
    debugger
    if (this.Logindetails) {
      this.loginStatus = true;
      this.getCustomerAddress()
    }
    else {
      this.loginStatus = false;
    }

  }
     



  calAmount() {
    this.TotalAmount = 0;
    if (this.cartProducts) {
      for (let data of this.cartProducts) {
        this.TotalAmount += data.PRICE;
      }
    }
  }

  RemoveItems(i, item) {
    this.messageService.clear();
    this.cartService.removeFromCart(item, i);
    this.cartProducts = JSON.parse(localStorage.getItem('products'));
    this.calAmount();
    if (item.ISPACKAGE == '2') {
      this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Test removed from the cart' });
    }
    else if (item.ISPACKAGE == '1') {
      this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Package removed from the cart' });
    }
  }
  clearCart() {
  }
  Continue() {
    this.router.navigateByUrl('/BookTest')
  }

  CheckOut() {
    debugger
    
    if (this.Logindetails) {
      if (this.addressDetails) {
        if (!this.addressId) {
          this.generalService.ShowAlert('Warning', 'Please select or add address for Delivery.', 'warning');
        }
        else {
          $('#myModal5').modal('show');
        }
      } else {
        this.generalService.ShowAlert('Warning', 'Please add address for Delivery.', 'warning');
        this.router.navigate(['/UpdateProfile']);
        //}
        //else {
        //    this.router.navigate(['/Login'])
        //    this.generalService.ShowAlert('INFO', 'Please login to checkout your order.', 'info');


      }

    }
  }
  /*end code*/
  
  /*place to order with user selected cart items*/
  PalceOrder(selectedValue) {
    debugger;
    //if (selectedValue) {
      
      var d = new Date();
      var n = d.getTime();
      this.xml2 = [];
      this.xml2.push({
        CollectionType: "InPerson",
        Status: "Pending",
        //PaymentStatus: "Pending",
        AddressId: this.addressId,
        Patient_ID: this.Logindetails.EMP_ID,
        TotalAmount: this.TotalAmount,
      })

      if (selectedValue == "Offline") {
        this.selectedValue = "COD"
        this.xml2[0].PaymentType = this.selectedValue;
        this.xml2[0].PaymentStatus = '';
    
        this.OfflinePayment(this.cartProducts)
      }
      else {
        this.xml2[0].TransactionID = n;
        this.xml2[0].PaymentType = this.selectedValue
        this.xml2[0].PaymentStatus = 'failed'
        this.OnlinePayment(this.cartProducts, n)
      }
    //}
    //else {
    //  alert("please any payment method");
    //}

  }
  /*end code*/

  /*pay products with online payment*/
  OfflinePayment(CartProducts) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append('xml1', JSON.stringify(CartProducts));
    UploadFile.append('xml2', JSON.stringify(this.xml2));
    UploadFile.append('UserID', '1');
    var url = "api/Values/InsertOrders";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      this.order = data;
      debugger
      if (data) {
        $('#myModal5').modal('hide');
        for (var i = 0; i < CartProducts.length; i++) {
          this.cartService.removeFromCart(CartProducts[0], 0);
        }
        this.generalService.ShowAlert('SUCCESS', 'Test(s) booked successfully.', 'success');
        this.sendsms();
        this.sendsms1();
        this.cartProducts = [];
        this.CartLength = 0;
        this.TotalAmount = 0;
        //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        //this.router.onSameUrlNavigation = 'reload';
        //this.router.navigate(['/Cart']);
      }
      else {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
        
      }
    }, err => {
    });
  }
  sendsms() {
    debugger
    
    var UploadFile = new FormData();

    var msg = "New test booked by customer " + this.Logindetails.EMP_NAME + " , " + this.Logindetails.PHONENUMBER + " with booking id " + this.order + " CREDENCE DIAGNOSTICS "
    UploadFile.append("Text", msg);
    UploadFile.append("Mobile", this.admin);
    var url = "api/Values/Send_Sms";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger

    });
  }
  sendsms1() {
    debugger
    var UploadFile = new FormData();
   // Thank You, your booking id is { #var # }
   // We have received an amount of Rs. { #var # } CREDENCE
  
    var msg = "Thank You for your booking, Your booking ID is " + this.order + " CREDENCE DIAGNOSTICS "  
    UploadFile.append("Text", msg);
    UploadFile.append("Mobile", this.Logindetails.PHONENUMBER);
    var url = "api/Values/Send_Sms";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
    });
  }


  getSelectedAddress(addressId: any) {
    //this.addressId = addressId;
    //console.log(addressId)
  }
  /*end getting customer address code*/
  getCustomerAddress() {
    debugger
    let Address = [{
      UserId: this.Logindetails.EMP_ID
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(Address));
    var url = "api/Values/CRED_Get_Customer_Address_Based_On_Id"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      debugger
      if (data != "") {
        this.addressDetails = data;
      }
    });
  }



  deleteaddress(index, Id) {
    this.cartIndex = 0;
    this.cartIndex = index;
    let formData: FormData = new FormData();
    formData.append('Id', Id);
    var url = "api/Admin/Deleteaddress"
    // this.http.post(url, formData).pipe(map(res => res)).subscribe((data: any) => {
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.getCustomerAddress();
      }
    });
  }
  /*pay products with online payment*/
  OnlinePayment(CartProducts, n) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append('xml1', JSON.stringify(CartProducts));
    UploadFile.append('xml2', JSON.stringify(this.xml2));
    UploadFile.append('UserID', '1');

    var url = "api/Values/InsertOrders";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data) {
        debugger
        $('#myModal5').modal('hide');          
        this.RedirectToPaymentGateway(CartProducts, n)
      }    
    }, err => {
    });
  }
  /*end code*/

  CheckPaymentSuccessOrNot(n) {

  }

 /*rediret payment gateway*/
  RedirectToPaymentGateway(CartProducts, n) {
    debugger
    var amount: any = 1;
    var UploadFile = new FormData();
    UploadFile.append('Name', this.Logindetails.EMP_NAME);
    UploadFile.append('Mobile', this.Logindetails.PHONENUMBER);
    UploadFile.append('Email', this.Logindetails.EMAIL);
    UploadFile.append('Amount', amount);
    UploadFile.append('Productinfo', "Product");
    UploadFile.append('TransactionId',n);
    UploadFile.append('BookingDetails', JSON.stringify(CartProducts));    
    var url = "api/Admin/PaymentGateWayWEB";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      window.location.href = data.toString();
      localStorage.removeItem('products')
      this.cartProducts = [];
      this.CartLength = 0;
      this.TotalAmount = 0;
      
    }, err => {
    });
  }
  /*end*/
}
//
