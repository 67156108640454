<div class="container">

  <!--<app-breadcrumb></app-breadcrumb>-->

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/OurTeam">Leadership</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Dr V. Lakshmi</li>
    </ol>
  </nav>
  <br /><br />
  <br />




  <h2 style="    color: black;  margin-top: -66px;">
    <b>Dr V. Lakshmi</b>
  </h2>
  <!--<p style="font-size: 14px;  color: black;margin-left: 41px;"><b>Professor,Dept Of Microbiology</b></p>-->
  <p style="font-size: 14px;  color: black;margin-left: 100px;
    margin-top: -9px;"><b>Lab Director</b></p>

  <div class="row">

    <div class="col-lg-7">
      <p style="color:black">

        <b style="color:black"> ➣  Dr V. Lakshmi</b>

        Professor, Dept. of Microbiology, Kamineni Academy of Medical Sciences &nbsp;&nbsp;&nbsp;&nbsp;& Research Center (KAMSRC), Hyderabad.
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp; In charge of Post Graduate teaching in Microbiology.<br /><br />
        <b>➣</b> KAMSRC Research committee member<br /><br />
        <b>
          ➣ Laboratory Director of Credence Diagnostic Center,
        </b> Hyderabad<br /><br />

        <b>  ➣   In charge of Research & Development, Credence Diagnostic Center, </b>Hyderabad, TS
        <br /><br />
        <b>    ➣ Retired Professor & Head, Department of Microbiology,</b> Nizam’s Institute of Medical &nbsp;&nbsp;&nbsp;&nbsp;Sciences, Hyderabad, TS, India

        <br /><br />
        <b style="color:black;">   ➣  As a strong advocate of advanced technology in clinical diagnosis of infectious a   &nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;   diseases,</b>
        she played a key role in the overall development of the Microbiology dept. &nbsp;&nbsp;&nbsp;&nbsp;at Nizam’s Institute of Medical Sciences and in catalyzing its emergence as an  &nbsp;&nbsp;&nbsp;&nbsp;eminent referral laboratory.
        She made great strides in introducing automation in the  &nbsp;&nbsp;&nbsp;&nbsp;laboratory as suited in a tertiary care hospital.


        <br /><br />
        <b>  ➣  </b>  With her new position at Credence Diagnostic Center, she has developed the  &nbsp;&nbsp;&nbsp;&nbsp;Mycobacteriology, Molecular and Autoimmune
        laboratories, using state of the art and  &nbsp;&nbsp;&nbsp;&nbsp;automated systems for rapid diagnostics.

    </div>
    <div class="col-lg-5">

      <img style="width:400px;height:400px;border-radius:10px;" src="../../../assets/img/WhatsApp Image 2022-02-19 at 5.42.17 PM.jpeg" alt="Credence Diagnostic Centre"/>
      <!--<img style="width:100%" src="../../../assets/img/WhatsApp Image 2022-01-29 at 16.18.30.jpeg" />-->
      <!--<img style="width:100%" src="../../../assets/img/WhatsApp Image 2022-01-29 at 16.18.30.jpeg" />-->
      <!--<img style="width:100%" src="../../../assets/img/WhatsApp Image 2022
          -01-29 at 16.18.30.jpeg" />-->
    </div>


  </div>

  <p style="color:black;">
    <b>➣ </b>
    she is one of the awardees of the<b style="color:black;"> Biotechnology Ignition Grant (DBT) in 2017</b>
    and has set up her own startup –<b style="color:black;"> MFP Medical Devices, </b>an LLP, incubating in  &nbsp;&nbsp;&nbsp;&nbsp;Kamineni Life Sciences.
    The project was successfully completed and is under scaling up.
  </p>
  <b>➣ </b>
  She has successfully guided 7 PhD projects. She has<b style="color:black;"> 147 publications</b> in peer-reviewed International and National journals to her credit.
  Her special &nbsp;&nbsp;&nbsp;&nbsp;interest is in the development of simple, rapid and point of care assays for an early and cost-effective diagnosis of
  infectious diseases. <br /><br />
  <b>➣</b>  She is well known Internationally for the application of Dried Blood spot (DBS) technology for surveillance of infectious pathogens.
  She was involved in  &nbsp;&nbsp;&nbsp;&nbsp;several national and international collaborative projects of Public health importance and applied research,
  especially using the DBS and LAMP assay.  &nbsp;&nbsp;&nbsp;&nbsp;Her research work on the LAMP assay for rapid molecular diagnosis of Infectious diseases
  such as HIV and Dengue, won 5 national awards.<br /><br />
  <p style="color:black;">
    <b> ➣  </b> She had organized several CMEs, conferences and technically oriented workshops. She has very successfully organized the National IAMM conference  &nbsp;&nbsp;&nbsp;&nbsp;(MICROCON 2013) at Hyderabad, India, in November 2013 and the State Level CME on an Update on TB under the State Task Force for TB, in 2016.
  </p>

  <p><b> ➣  </b>  She has delivered guest lectures focusing on rapid diagnostics and technical aspects of clinical microbiology and infection control.</p>

  <p><b>➣ </b> She regularly conducts hands-on training workshop on basic molecular techniques.</p>


  <p><b> ➣</b> She is a member of Institutional Research Committee of KAMSRC and takes care of Post graduate teaching.</p>


  <p style="color:black; "><b>➣ </b>Overall, she is a very resourceful, dynamic, diligent, innovative and adventurous person with zeal to extend support for the cause of improving clinical  &nbsp;&nbsp;&nbsp;&nbsp;diagnostics of infectious diseases in a clinically useful time.</p>

</div>

