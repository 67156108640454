

<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Leadership</li>
    </ol>
  </nav>

 
  <!--<div class="col-lg-9">
    <p>
      The Department of Genetics has state of the art cytogenetic facility to study the Human chromosome whether it is to observe the structure or to see deletions and insertions in the chromosomes.
      The genetic facility is equiped with Real time PCR systems,High resolution Sequencers,High powered fluroscent Microscopes, Computer enhanced image capturing system and automated DNA extraction systems for error free and accurate diagnostic procedure.
    </p>
    <p>
      We perform genetic analysis in Aminotic fluid and Aborted tissue or Poc samples as well to help identify, if your baby is suffering from any disease or had genetic disorder which caused the miscarriage or abortion.
      Genetic Research facility has a specialised genetic counseling centre.The Counseling sessions provide each and every information the patient requires to know future and other possibilty.
    </p>
    <p>
      The Genetic Counseling sessions are conducted by certified counsellors,who look at the patient family history,diagnostic reports and provides fully detailed treatment suggestion.
      We provide genetic counselling for couple combatibilty to see , Herediteary disease inheritance, Treatment solutions for genetic diseases and risk prediction of herediatery disease occurance.
    </p>
    <p>   The genetic facility is equiped with Real time PCR systems, Computer enhanced image capturing system and automated DNA extraction systems for error free and accurate diagnostic procedure.</p>
    <p>
      Trained several graduate and post graduate doctors and students in cytogenetic, FISH, molecular techniques. 11 Students successfully completed their PG dissertation thesis under th<p>
      The Genetic Counseling sessions are conducted by certified counsellors,who look at the patient family history,diagnostic reports and provides fully detailed treatment suggestion.
      We provide genetic counselling for couple combatibilty to see , Herediteary disease inheritance, Treatment solutions for genetic diseases and risk prediction of herediatery disease occurance.
    </p>
    <p> She is well experienced with pedigree drawing, analysis and determination of inheritance patterns, syndrome identification, dysmorphology work up, prenatal screening and diagnosis, genetic data bases, communication with clinicians, drafting counselling reports, pre and post test genetic counselling sessions</p>
    <p>
      Her special interest is in the development of simple, rapid and point of care assays for an early and cost-effective diagnosis of infectious diseases. She is well known Internationally for the application of Dried Blood spot (DBS) technology for surveillance of infectious pathogens. She was involved in several national and international collaborative projects of Public health importance and applied research, especially using the DBS and LAMP assay. Her research work on the LAMP assay for rapid molecular diagnosis of Infectious diseases such as HIV and Dengue, won 5 national awards.
    </p>


  </div>-->

  <div class="conatiner">
    <div class="col-lg-12">
      <div class="card-img" style="border-shadow:10px red;border-bottom:1px solid #FFFFFF;">


        <!--<img src="../../../assets/img/WhatsApp Image 2022-01-29 at 16.18.30.jpeg" alt="Avatar" style="width:206px;margin-left: 33px;border-radius:50%;    height: auto; cursor:pointer;" routerLink="/DrRamakrishna" />-->
        <img src="../../../assets/img/WhatsApp Image 2022-02-19 at 5.42.19 PM.jpeg" alt="credence Diagnostics center"  class="ra_card"alt="Avatar" routerLink="/DrRamakrishna" >  <h5 class="ra_card1"> <b>Dr.Ramakrishna Devaki</b> </h5>
        <p class="ra_card2"><b>Chief Executive Officer</b></p>


      </div>
      <div class="col-lg-6">
        <div class="card-img">
          <div class="la_card">
            <img src="../../../assets/img/WhatsApp Image 2022-02-19 at 5.42.17 PM.jpeg" alt="credence Diagnostics center" class="la_card" alt="Avatar" routerLink="/DrLaxshmi" />
          </div>

          <!--<img src="../../../assets/img/image.PNG" alt="Avatar" style="width: 164px; margin-bottom:21px;    margin-left: 33px;border-radius:50%;    height: auto;cursor:pointer;" routerLink="/DrLaxshmi" />-->
          <h5 class="la_card1">   <b>Dr.V.Lakshmi</b> </h5>

          <p  class="la_card2"><b>Lab Director</b></p>

        </div>
      </div>
      <div class="col-lg-6">

        <div class="card-img">

          <img src="../../../assets/img/WhatsApp Image 2022-02-23 at 3.26.52 PM.jpeg" class="po_card"
       
               alt="credence Diagnostics center"routerLink="/DrSubhadra" />
          <!--<img src="../../../assets/img/poornima.JPG" alt="Avatar" style="width: 164px; margin-bottom:21px;    margin-left: 33px;border-radius:50%;    height: auto;cursor:pointer;" routerLink="/DrSubhadra" />-->
          <h5 class="po_card1">   <b>Dr. Subhadra Poornima</b> </h5>
          <p class="po_card2"><b>HOD-Genetics</b></p>
        </div>
      </div>
   <!----<div class="col-lg-6">
        <div class="card-img">
          <img src="../../../assets/img/WhatsApp Image 2022-02-19 at 5.42.17 PM.jpeg" alt="Avatar" style="width: 164px; margin-bottom:21px;    margin-left: 133px;border-radius:50%;    height: 193px;cursor:pointer;" routerLink="/DrLaxshmi" />

      
          <h5 style="margin-left: 160px;">   <b>Dr.V.Lakshmi</b> </h5>

          <p style="margin-left:133px;font-size: 11px;"><b>Professor,Dept Of Microbiology</b></p>
        </div>
      </div>-->
    </div>
  </div>


  <!--<div class="container">
    <div class="row">
      <div class="col-lg-6 ">
        <div class="card-img">


          <img src="../../../assets/img/WhatsApp Image 2022-01-29 at 16.18.30.jpeg" alt="Avatar" style="width:206px;margin-left: 70px;border-radius:50%;    height: auto; cursor:pointer;" routerLink="/DrRamakrishna" />

          <h5 style="margin-left: 83px;padding-top: 10px;">   <b>Dr.Ramakrishna Devaki</b> </h5>
          <p style="margin-left: 90px;font-size: 11px;"><b>Founder & Chief Executive Officer</b></p>-->
  <!--</div>
      </div>
      <div class="col-lg-6 ">

        <div class="card-img">
          <img src="../../../assets/img/poornima.JPG" alt="Avatar" style="width: 164px; margin-bottom:21px;    margin-left: 70px;border-radius:50%;    height: auto;cursor:pointer;" routerLink="/DrSubhadra" />
          <h5 style="margin-left: 83px;padding-top: 10px;">   <b>Dr. Subhadra Poornmia</b> </h5>
          <p style="margin-left:90px;font-size: 11px;"><b>Consultant & HOD</b></p>
        </div>
      </div>
    </div>
  </div>-->
  <br /><br /><br /><br />
  <hr  class="hr"/>
  <div class="conatiner">
    <div class="w3-container w3-border-bottom w3-border-red">
      <h5  class="credence">&nbsp; CDC- Credence Diagnostics Centre team is divided into multiple clinical domains from</h5>
    </div><br />
  </div>

  <div class="container" style="margin-left:150px;">
    <div class="row">

      <div class="col-lg-4">
        <div class="card-img ">
          <img src="https://img.icons8.com/external-justicon-flat-justicon/64/000000/external-dropper-science-justicon-flat-justicon-2.png" alt="credence Diagnostics center" class="bio" />
          <a routerLinkActive="active" routerLink="/Biochemistry" class="bio_1">Biochemistry</a>
        </div><br /><br />
        <div class="card-img mio_2">
          <img src="https://img.icons8.com/external-wanicon-flat-wanicon/64/000000/external-petri-dish-vaccine-development-wanicon-flat-wanicon.png" alt="credence Diagnostics center" class="mio" />
          <a routerLinkActive="active" routerLink="/Microbiology" class="mio_1">Microbiology</a>
        </div>

        <br /><br />

      </div>
      <div class="col-lg-4">
        <div class="card-img ">
          <img src="https://img.icons8.com/external-icongeek26-outline-colour-icongeek26/64/000000/external-research-virus-icongeek26-outline-colour-icongeek26.png"alt="credence Diagnostics center" class="pat" />
          <a routerLinkActive="active" routerLink="/ClinicalPathology" class="pat_1">Pathology</a>
        </div><br /><br />
        <div class="card-img gen_2">
          <img src="https://img.icons8.com/external-icongeek26-flat-icongeek26/64/000000/external-dna-pregnancy-amp-maternity-icongeek26-flat-icongeek26.png" alt="credence Diagnostics center"class="gen" />
          <a routerLinkActive="active" routerLink="/GeneticsMolecularMedicine" class="gen_1">Genetics</a>
        </div>
      </div><br /><br />
    </div>
    </div>
    <div class="col-lg-12">
      CDC has a reputation of adhering to world class laboratory practices laid by NABL the National Accreditation Board for Testing
      & Calibration Laboratories (NABL) with cutting edge technological equipment, prioritizing patient experience, and providing
      the shortest possible turnaround time (TAT) for reports with a team of well-trained technicians, phlebotomists & pathologists
      and do follow stringent Internal Quality Control (IQC) and External Quality Control (EQC) to ensure accurate and precise
      results Our Staff periodically participate in proficiency-testing assessments for the increased clinician and patient confidence.
      All the laboratory procedure are standardised by ISO quality standard and followed by NABL guidelines.
    </div>
    <!--<div class="col-lg-8">
    <div class="row">
      <div _ngcontent-c7="" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgin_t_8">
        <div _ngcontent-c7="" class="col-lg-4 col-md-4 col-sm-3 col-xs-3 rounded-outline">
          <div _ngcontent-c7="" class="circle_9 text-align_c">
            <img src="https://img.icons8.com/external-icongeek26-flat-icongeek26/64/000000/external-dna-pregnancy-amp-maternity-icongeek26-flat-icongeek26.png" style="border:2px dashed;border-radius:50px;    padding: 6px;" />
          </div>
        </div><div _ngcontent-c7="" class="col-lg-8 col-md-8 col-sm-7 col-xs-7">  <a routerLinkActive="active" routerLink="/GeneticsMolecularMedicine" style="margin-left:50px;">Genetics</a></div>
      </div>
      <div _ngcontent-c7="" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgin_t_8">
        <div _ngcontent-c7="" class="col-lg-4 col-md-4 col-sm-3 col-xs-3 rounded-outline">
          <div _ngcontent-c7="" class="raio text-align_c">
            <img src="https://img.icons8.com/external-justicon-flat-justicon/64/000000/external-dropper-science-justicon-flat-justicon-2.png" style="border:2px dashed;border-radius:50px;    padding: 6px;" />
          </div>
        </div><div _ngcontent-c7="" class="col-lg-8 col-md-8 col-sm-7 col-xs-7"> <a routerLinkActive="active" routerLink="/Biochemistry">Biochemistry</a></div>
      </div>
    </div><br />
    <div class="row">
      <div _ngcontent-c7="" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgin_t_8">
        <div _ngcontent-c7="" class="col-lg-4 col-md-4 col-sm-3 col-xs-3 rounded-outline">
          <div _ngcontent-c7="" class="circle_9 text-align_c">
            <img src="https://img.icons8.com/external-wanicon-flat-wanicon/64/000000/external-petri-dish-vaccine-development-wanicon-flat-wanicon.png" style="border:2px dashed;border-radius:50px;    padding: 6px;" />
          </div>
        </div><div _ngcontent-c7="" class="col-lg-8 col-md-8 col-sm-7 col-xs-7"><a routerLinkActive="active" routerLink="/Microbiology">Microbiology</a></div>
      </div>
      <div _ngcontent-c7="" class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgin_t_8">
        <div _ngcontent-c7="" class="col-lg-4 col-md-4 col-sm-3 col-xs-3 rounded-outline">
          <div _ngcontent-c7="" class="raio text-align_c">
            <img src="https://img.icons8.com/external-icongeek26-outline-colour-icongeek26/64/000000/external-research-virus-icongeek26-outline-colour-icongeek26.png" style="border:2px dashed;border-radius:50px;    padding: 6px;" />
          </div>
        </div><div _ngcontent-c7="" class="col-lg-8 col-md-8 col-sm-7 col-xs-7">  <a routerLinkActive="active" routerLink="/ClinicalPathology">Pathology</a></div>
      </div>

    </div>
  </div>-->
  
  </div>

