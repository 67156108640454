import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
 
})
  
export class GeneralService {
  url: any;
  urlname: any;
  prodData: any = [];
  public productArr = new BehaviorSubject<any>([]);

  constructor(public http: HttpClient, ) { }

  ShowAlert(title, text, type) {
    Swal.fire({
      title: title,
      text: text,
      //icon: type,
      confirmButtonText: 'Ok',
    })
  }
  ShowAlert1(title, text, type) {
    Swal.fire({
      title: title,
      text: text,
      //icon: type,
      confirmButtonText: 'Print',
    })
  }

  setequipment(data: any) {
    this.prodData = []
    this.prodData.push(...data)
    this.productArr.next(this.prodData)
  }

  //Global Method to retrieve data 
  GetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        debugger
        this.urlname = data.Webservice;
        localStorage.setItem('url', this.urlname);
        this.url = this.urlname + url
        this.http.get(this.url).subscribe(data => {

          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData(url, PostParam) {
    
    return new Promise((resolve, reject) => {
      
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        debugger
        this.urlname = data.Webservice;
        url = data.Webservice + url
        this.http.post(url, PostParam).subscribe(data => {
          
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
  PostData2(url, PostParam, PostParam2) {
    
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebService.json').subscribe((data: any) => {
        this.urlname = data.Webservice;
        url = data.Webservice + url
        this.http.post(url, PostParam, PostParam2).subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }

  SoapServicePostData(url, PostParam) {
    debugger
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebSoapService.json').subscribe((data: any) => {
        
        url = data.Webservice + url
         // url =url
        this.http.post(url, PostParam).subscribe(data => {
          
          resolve(data)
        }, err => {
          reject(err)
          })
      }, err => {
        reject(err)
        })


    });
  }
  SuccessMessage() {
  
  }
  getequipment() {
    return this.productArr.asObservable();
  }

  SoapServiceGetData(url) {
    return new Promise((resolve, reject) => {
      this.http.get('../assets/WebSoapService.json').subscribe((data: any) => {
        
        this.urlname = data.Webservice;
        this.url = this.urlname + url
        this.http.get(this.url).subscribe(data => {

          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }
}
