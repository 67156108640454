import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms'; 
declare var $;
import { DataService } from '../../Services/data.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  public form: FormGroup;
  Mobile: any;
  Name: any;
  arr: any[];
  checkNo: number;
  randomnumber: any;
  otp: any;
  PhoneNo: any;
    CITY: any;
    AREA: any;
  GENDER: any;
  EMAIL: any;
  genderList: any = [];
  gender: any = [];
  AGE: any;
  LoginDetails: any;
  constructor(private dataSharingService: DataService, public generalService: GeneralService,public router: Router, fb: FormBuilder) {
    this.router = router;
    this.form = fb.group({
      'Name': ['', Validators.required],
      'Mobile': ['', Validators.compose([Validators.required, Validators.pattern(/^[6-9]\d{9}$/)])],
      'EMAIL': ['', Validators.required],
      'CITY': ['', Validators.required],
      'AREA': ['', Validators.required],
      'GENDER': ['', Validators.required],
      'AGE': ['', Validators.required],
    });
  }

  ngOnInit(): void {
   // this.genderList.push({ Name: 'Male', Value: 'Male' }, { Name: 'FeMale', Value: 'FeMale' }, { Name: 'Others', Value: 'Others' })
    //this.gender = this.genderList;
  }

  getActive(ev: any,val) {
    debugger
    this.GENDER = val;
  }
  //this is to submit the name,mobile 
  onSubmit1() {
    this.arr = []
    this.arr.push({
      EMP_NAME: this.Name,
      PHONENUMBER: this.PhoneNo,
      EMAIL: this.EMAIL,
      CITY: this.CITY,
      AREA: this.AREA,
      GENDER: this.GENDER,
      AGE: this.AGE


    });
    var UploadFile = new FormData();
    UploadFile.append("xml1", JSON.stringify(this.arr));
    UploadFile.append("Param1","1");
    var url = "api/Values/InsertEmployees";
    this.generalService.PostData(url, UploadFile).then(data => {


      //this.LoginDetails = data;
      //localStorage.removeItem('LoginDetails');
      //localStorage.setItem("LoginDetails", this.LoginDetails);


      if (data != null) {
        debugger
        if (data == "SUCCESS") {
          this.generalService.ShowAlert('Success', 'Thank You  for Registering', 'Success');
          localStorage.setItem("LoginDetails1", this.Name);
          this.dataSharingService.login();
          this.sendsms();
          //this.router.navigateByUrl('/Login')


          //localStorage.setItem("LoginDetails", JSON.stringify(this.LoginDetails));
          //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          //this.router.onSameUrlNavigation = 'reload';
          //this.router.navigate(['/Home']);


        }
        else {
          this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        }
      }
    });
  }
  public onlogin() {
    debugger
      var UploadFile = new FormData();
      UploadFile.append("Mobile", this.PhoneNo);
      var url = "api/Values/CheckMobile";
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger;
        if (data != 'NOT EXIST') {
          $('#myModal3').modal('show');
          this.LoginDetails = data;
          localStorage.removeItem('LoginDetails');
          localStorage.setItem("LoginDetails", this.LoginDetails);
          this.VerifyLoginOtp(this.randomnumber);
        }
        else {
          this.generalService.ShowAlert('ERROR', 'You dont have an account,please Sign up', 'error')

          this.router.navigate(['/Registration']);
        }
      }, err => {
        //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
  }
  VerifyLoginOtp(otp) {
    debugger
    if (this.randomnumber == otp) {
      $('#myModal3').modal('hide');

      localStorage.setItem("LoginDetails", JSON.stringify(this.LoginDetails[0]));
      // location.reload();
      //this.dataSharingService.login();
      //this.router.navigateByUrl('/Header1', { skipLocationChange: true }).then(() => {
      //    this.router.navigateByUrl('/Home');
      //    });
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/Home']);
    }
    else {
      alert("Invalid OTP");
    }
  }

  sendsms() {
    debugger
    var UploadFile = new FormData();
    var msg = "Thank You for registering with us CREDENCE DIAGNOSTICS"
    UploadFile.append("Text", msg);
    UploadFile.append("Mobile", this.PhoneNo);
    var url = "api/Values/Send_Sms";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.onlogin();
    });
  }






  //This is where you will submit the form.
  public onSubmit(val) {
    debugger
    this.PhoneNo = val.Mobile;
    this.Name = val.Name;
    this.EMAIL = val.EMAIL;
    this.AREA = val.AREA;
    this.CITY = val.CITY;
    this.GENDER = val.GENDER;
    this.AGE = val.AGE;
    this.otp = ""
    this.SendOtp();
    $('#myModal3').modal('show');
  }
  

  /* send otp for registration */
   SendOtp() {
    debugger
    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
     var UploadFile = new FormData();
     var msg = "Your OTP is " + this.randomnumber + " CREDENCE DIAGNOSTICS";
    //var msg = "Your Otp Is: " + this.randomnumber
    //  + " HYDRST"
    UploadFile.append("Text", msg);
     UploadFile.append("Mobile", this.PhoneNo);
    var url = "api/Values/Send_Sms";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      if (data == "SUCCESS") {

      }
      else {

      }
    });
  }
  /*end code*/
  search(event, val) {
    debugger
    let filtered: any[] = [];
    let query = event.query;
    if (val == '1') {
      this.genderList = [];
      for (let i = 0; i < this.gender.length; i++) {
        let cat = this.gender[i];
        if (cat.Name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(cat);
        }
      }
      this.genderList = filtered;
    }

  }
  /*verifyotp for forgotpassword*/
  VerifyOtp(otp) {
    debugger
    if (this.randomnumber == otp) {
      $('#myModal3').modal('hide');
      
      this.onSubmit1();
  
    }
    else {
      alert("Invalid OTP");
    }
  }

  /*end region*/
  /*check mobile exist or not*/
  CheckMobileNo() {
    debugger;
    this.PhoneNo = this.form.get('Mobile').value;
    var UploadFile = new FormData();
    var UploadFile = new FormData();
    UploadFile.append("MobileNo", this.PhoneNo);
    UploadFile.append("Flag", "1");
    var url = "api/Values/CheckMobileandmail"
    debugger;
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger;
      if (data == 'EXIST') {
        this.checkNo = 10;
      }
      else {
        this.checkNo = 11;
      }
    }, err => {
      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*endregion*/
  //This code is only used to enter numerical values.
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
