import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-molecular-biology',
  templateUrl: './molecular-biology.component.html',
  styleUrls: ['./molecular-biology.component.scss']
})
export class MolecularBiologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
