import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralService } from '../../Services/general.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  EnquiryAry: any = [];
  formGroup: any;
  admin: any = 9182057756;

  //ApiURL: any;
  ContactUsForm: FormGroup;
  spinner: boolean = false;
 
  constructor(public generalService: GeneralService, public fb: FormBuilder) {

    debugger


  }

  ngOnInit() {
    debugger

    this.ContactUsForm = this.fb.group({
      Name: ['', Validators.required],

      Mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Email: ['', Validators.compose([Validators.required, Validators.email])],
      Message: ['', Validators.required]
    });
  }



  //When a consumer submits an inquiry, it is forwarded to the administrator( EnquiryMailToAdmin)
  onSubmit(form: any) {
    this.ContactUsForm.reset();
    debugger;

    this.EnquiryAry = []; this.EnquiryAry.push({ Name: form.Name, Mobile: form.Mobile, Email: form.Email, Message: form.Message })
    var UploadFile = new FormData();
    UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));
    var url = "api/Values/EnquiryMailToAdmin"
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger;
          

      Swal.fire(
        'SUCCESS',
        'Your Enquiry has been submitted successfully',
        'success'
      )
      this.sendsms();
    });

  }



  sendsms() {
    debugger
    debugger
    var UploadFile = new FormData 
 
    var msg = "Thank you for contacting with us, our agent will contact you soon. Reach Credence on number "+ this.admin +"CREDENCE DIAGNOSTICS "
   
    UploadFile.append("Text", msg);
    UploadFile.append("Mobile", this.EnquiryAry[0].Mobile); 
    var url = "api/Values/Send_Sms";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger

    }); 
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}
