<!--<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Contact Us</li>
    </ol>
  </nav>

  <div class="container-fluid" style="padding:0">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.178067590973!2d78.55366231487723!3d17.45118928804046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9bf26b9ba2bd%3A0x844ebd49c70c8265!2sCredence+Diagnostics!5e0!3m2!1sen!2sin!4v1558520865444!5m2!1sen!2sin" width="100%" height="250px" frameborder="0" style="border:0" allowfullscreen=""></iframe>
  </div>

  <div class="col-lg-5">
    <h5>Credence Diagnostic Centre</h5>
    <p>
      4-7, Moula-Ali,
      Hyderabad -500 040,
      Telangana,India.
    </p>
    <h6>Opening Times</h6>
    <p>24X7 Customer Care</p>
    <div class="row">
      <div class="col-lg-6">
        <h6>Hyderabad</h6>
        <a href="tel:+91 9182057756" style="color:#000">
          <i class="fa fa-phone"></i> +91 9182057756
        </a>
      </div>
      <div class="col-lg-6">
        <h6>Chennai</h6>
        <a href="tel:+91 7569041272" style="color:#000">
          <i class="fa fa-phone"></i> +91 7569041272
        </a>
      </div>
      <div class="col-lg-6">
        <h6>
          Fax
        </h6>
        <a href="tel:+91 4027241733" style="color:#000">
          <i class="fa fa-phone"></i> +91 40 2724 1733
        </a>
      </div>
    </div>

  </div>
  <div class="col-lg-7">
    <h5>Send us an Email</h5>
    <div class="row">
      <div class="col-lg-6">
        <label>First Name </label>
        <input type="text" class="form-control" />
      </div>
      <div class="col-lg-6 form-group">
        <label>Last Name </label>
        <input type="text" class="form-control" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label>Email </label>
        <input type="text" class="form-control" />
      </div>
      <div class="col-lg-6 form-group">
        <label>Phone </label>
        <input type="text" class="form-control" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <label>Message </label>
        <textarea style="height:170px !important" class="form-control"></textarea>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-12" style="text-align:right">
        <button type="button" class="btn btn-primary">Send</button>
      </div>
    </div>
  </div>
</div>-->
<!---------------------------------------------------------------------------------->




<div *ngIf="spinner" class="lds-roller">
  <div></div><div></div><div></div><div>
  </div><div></div><div></div><div></div><div>
  </div>
</div>
<!-----this code contactus page  -->
<div class="container">
  <!--<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Contact Us</li>
    </ol>
  </nav>-->
  <app-breadcrumb></app-breadcrumb>

  <div class="container-fluid" style="padding:0">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.178067590973!2d78.55366231487723!3d17.45118928804046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9bf26b9ba2bd%3A0x844ebd49c70c8265!2sCredence+Diagnostics!5e0!3m2!1sen!2sin!4v1558520865444!5m2!1sen!2sin" width="100%" height="250px" frameborder="0" style="border:0" allowfullscreen=""></iframe>
  </div>

  <!--- contact us page  Send Enquiry the enquiry received ADMIN  -->
  <div class="card">
    <div class="row">
      <div class="col-md-6">
        <h3>Contact Form</h3>
        <form [formGroup]="ContactUsForm">
          <div class="form-group">
            <label for="pwd">Name :</label>
            <input class="form-control" formControlName="Name" name="name" type="text" placeholder="Full Name">
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Name.touched && ContactUsForm.controls.Name.errors?.required">Field is required</small>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email :</label>
            <input class="form-control" formControlName="Email" name="name" type="text" placeholder="Email Address">
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Email.touched && ContactUsForm.controls.Email.errors?.required">Field is required</small>
            </div>
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Email.errors?.email">Incorrect email format.</small>
            </div>
          </div>
          <div class="form-group">
            <label for="pwd"> Mobile Number :</label>
            <input class="form-control" formControlName="Mobile" name="name" type="text" placeholder="Mobile Number">
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Mobile.touched && ContactUsForm.controls.Mobile.errors?.required">Field is required</small>
            </div>
          </div>
          <div class="form-group">
            <label for="pwd"> Message :</label>
            <textarea rows="4" formControlName="Message" placeholder="Message..." class="form-control" cols="50"></textarea>
            <div class="alrt">
              <small class="text-danger" *ngIf="ContactUsForm.controls.Message.touched && ContactUsForm.controls.Message.errors?.required">Field is required</small>
            </div>
          </div>
          <button class="btn btn-primary" [disabled]="ContactUsForm.invalid" (click)="onSubmit(ContactUsForm.value)">Send Mail</button>
        </form>
      </div>
      <!----YK MART ADDRESS-->
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-8">
            <h5>Credence Diagnostic Centre</h5>
            <ul class="tcy">
              <li style="margin-right:1%;"><i class="fa fa-map-marker icon_p"></i></li>
              <li>
                <p>
                  4-7, Moula-Ali,
                  Hyderabad -500 040,
                  Telangana,India.
                </p>

            </ul>
          </div>
          <div class="col-md-4">
            <h6><b> Opening Times</b></h6>
            <div class="tcy">
              24X7 Customer Care
            </div>

          </div>
        </div>

        <div class="tcy">
          <i class="fa fa-envelope-o icon_p"></i>helpline@credencediagnostics.com
          <div>
            <div class="tcy">
              <i class="fa fa-phone icon_p"></i>
              +91 9814411441&nbsp;/&nbsp;9593779377
            </div>
          </div>
        </div>
        <div class="row">
          
          <!----<div class="col-md-4">
            <h6>Chennai</h6>
            <div class="tcy">
              <i class="fa fa-phone icon_p"></i>
              +91 7569041272
            </div>
          </div>
          <div class="col-md-4">
            <h6>
              Fax
            </h6>
            <div class="tcy">
              <i class="fa fa-phone icon_p"></i>
              +91 40 2724 1733
            </div>
          </div>-->
        </div>
        <br />
        <img src="../../../assets/img/Crlogo.png"alt="Credence Diagnostic Centre" routerLink="/Home" style="width:55%;" />
      </div>
      <br>
    </div>
    <br />
  </div>
</div>
<br />
<style>
  .tcy {
    line-height: 30px;
    letter-spacing: 0.5px;
  }

  ul, li {
    list-style: none;
    display: inline-flex;
    margin: 0
  }

  .card {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    padding: 13px;
  }

  .icon_p {
    font-size: 20px;
    color: red;
    margin-right: 1%;
  }

  /*styles for loader or spinner*/
  .lds-roller {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    z-index: 999;
    margin: 16% 0% 0% 35%;
  }

    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 32px 32px;
    }

      .lds-roller div:after {
        content: " ";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: red;
        margin: -3px 0 0 -3px;
      }

      .lds-roller div:nth-child(1) {
        animation-delay: -0.036s;
      }

        .lds-roller div:nth-child(1):after {
          top: 50px;
          left: 50px;
        }

      .lds-roller div:nth-child(2) {
        animation-delay: -0.072s;
      }

        .lds-roller div:nth-child(2):after {
          top: 54px;
          left: 45px;
        }

      .lds-roller div:nth-child(3) {
        animation-delay: -0.108s;
      }

        .lds-roller div:nth-child(3):after {
          top: 57px;
          left: 39px;
        }

      .lds-roller div:nth-child(4) {
        animation-delay: -0.144s;
      }

        .lds-roller div:nth-child(4):after {
          top: 58px;
          left: 32px;
        }

      .lds-roller div:nth-child(5) {
        animation-delay: -0.18s;
      }

        .lds-roller div:nth-child(5):after {
          top: 57px;
          left: 25px;
        }

      .lds-roller div:nth-child(6) {
        animation-delay: -0.216s;
      }

        .lds-roller div:nth-child(6):after {
          top: 54px;
          left: 19px;
        }

      .lds-roller div:nth-child(7) {
        animation-delay: -0.252s;
      }

        .lds-roller div:nth-child(7):after {
          top: 50px;
          left: 14px;
        }

      .lds-roller div:nth-child(8) {
        animation-delay: -0.288s;
      }

        .lds-roller div:nth-child(8):after {
          top: 45px;
          left: 10px;
        }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .ui-widget {
    font-size: 12px !important;
    text-decoration: none;
    width: 100%;
  }
  /*end code*/
</style>
