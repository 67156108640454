<div class="container">


  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Molecular Biology</li>
    </ol>
  </nav>
  <!--<br />
  <br /> <br />-->
  <h1>Molecular Biology</h1>
  <div class="row">
    <div class="col-lg-5" >
      <img style="width:100%;border-radius:10px;box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;" src="../../../assets/img/OurServices/molecular_biology.jpg" alt="credence Diagnostics center"/>
    </div>
    <div class="col-lg-7">
      <p>
        <b>Molecular biology</b> is <b>the branch of biology that studies the molecular basis of biological activity.</b> Living things are made of
        chemicals just as non-living things are, so a molecular biologist studies how molecules interact with one another in living
        organisms to perform the functions of life. Molecular biology also plays a critical role in the understanding of structures,
        functions, and internal controls within individual cells, all of which can be used efficiently target new drugs, diagnose
        disease, and better understanding of cell physiology.

      </p> <p>
      </p> <p>
        Molecular biologists conduct experiments to investigate the structure, function, processing, regulation and evolution of biological
        molecules and their interactions<br />
        with one another — providing micro-level insights into how life works. Although there are many kinds of molecules in every
        living thing, most molecular biologists focus on genes and proteins. Proteins perform a huge diversity of functions within
        living cells and genes contain the information required to make more proteins.

      </p>
    </div>



  </div>
  <p>
    Molecular biology is perhaps most famous for its distinctive suite of laboratory methods. Molecular biologists make extensive use
    of:

  </p>
  <b>1. Molecular cloning</b><br />
  <b> 2. Polymerase  Chain reaction</b><br />
  
  <b> 3. Gel electrophoresis</b><br />
  <b>4. Blotting</b>
</div>
