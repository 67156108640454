import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { CartService } from '../../Services/cart.service';
import { MessageService } from 'primeng/api';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

declare var $;
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {
  Blogs: any;
  HomeUrl: any;

  constructor(public router: Router, public http: HttpClient, public generalService: GeneralService, public toastrService: ToastrService,
    public messageService: MessageService, public cartService: CartService) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
  }

  ngOnInit(): void {
    this.getBlogs();
  }

  getBlogs() {
    var UploadFile = new FormData();
    var url = "api/Values/GetBlogs";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.Blogs = data;
        for (var i = 0; i < this.Blogs.length; i++) {
          this.Blogs[i].Discription = decodeURIComponent(this.Blogs[i].Discription);
        }
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  }
}
