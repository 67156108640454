import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { HomeComponent } from './page-lay-out/home/home.component';
import { PageHeaderComponent } from './page-lay-out/page-header/page-header.component';
import { PageFooterComponent } from './page-lay-out/page-footer/page-footer.component';
import { PageLayOutComponent } from './page-lay-out/page-lay-out.component';
import { RegistrationComponent } from './page-lay-out/registration/registration.component';
import { LoginComponent } from './page-lay-out/login/login.component';
import { CartComponent } from './page-lay-out/cart/cart.component'; 
import { PateintOrdersComponent } from './page-lay-out/pateint-orders/pateint-orders.component'
import { OrganBasedTestsComponent } from './page-lay-out/organ-based-tests/organ-based-tests.component';
import { UpdateProfileComponent } from './page-lay-out/update-profile/update-profile.component';
import { AboutusComponent } from './page-lay-out/aboutus/aboutus.component';
import { KnowledgeCenterComponent } from './page-lay-out/knowledge-center/knowledge-center.component';
import { HealthPackagesComponent } from './page-lay-out/health-packages/health-packages.component';
import { BookTestComponent } from './page-lay-out/book-test/book-test.component';
import { BlogsComponent } from './page-lay-out/blogs/blogs.component';
import { LabEquipmentComponent } from './page-lay-out/lab-equipment/lab-equipment.component'
import { ContactUsComponent } from './page-lay-out/contact-us/contact-us.component';
import { MoreInfoComponent } from './page-lay-out/more-info/more-info.component';
import { NewsDetailsComponent } from './page-lay-out/news-details/news-details.component';
import { LabInstrumentsComponent } from './page-lay-out/lab-instruments/lab-instruments.component';
import { OurservicesComponent } from './page-lay-out/ourservices/ourservices.component';
import { AboutCDCComponent } from './page-lay-out/about-cdc/about-cdc.component';
import { OurTeamComponent } from './page-lay-out/our-team/our-team.component';
import { BiochemistryComponent } from './page-lay-out/biochemistry/biochemistry.component';
import { ClinicalPathologyComponent } from './page-lay-out/clinical-pathology/clinical-pathology.component';
import { MicrobiologyComponent } from './page-lay-out/microbiology/microbiology.component';
import { GeneticsMolecularMedicineComponent } from './page-lay-out/genetics-molecular-medicine/genetics-molecular-medicine.component';
import { HistoCytoPathologyComponent } from './page-lay-out/histo-cyto-pathology/histo-cyto-pathology.component';
import { MolecularBiologyComponent } from './page-lay-out/molecular-biology/molecular-biology.component'
import { DrRamakrishnaComponent } from './page-lay-out/dr-ramakrishna/dr-ramakrishna.component';
import { DrSubhadraComponent } from './page-lay-out/dr-subhadra/dr-subhadra.component';
import { OrderInvoiceComponent } from './page-lay-out/order-invoice/order-invoice.component';
import { DrlaxshmiComponent } from './page-lay-out/drlaxshmi/drlaxshmi.component';

export const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch: 'full' },
  {
    path: '',
    component: PageLayOutComponent,
    children: [   
      { path: 'Home', component: HomeComponent,  data: { breadcrumb: 'Home' } },
      { path: 'Header1', component: PageHeaderComponent , data: { breadcrumb: 'Header' } },
      { path: 'Footer', component: PageFooterComponent , data: { breadcrumb: 'Footer' } },
      //{ path: '', loadChildren: () => import('./page-lay-out/customer-management/customer-management.module').then(m => m.CustomerManagementModule), data: { breadcrumb: 'Dashboard' } },
      //{ path: '', loadChildren: () => import('./page-lay-out/lab-management/lab-management.module').then(m => m.LabManagementModule), data: { breadcrumb: 'Dashboard' } },
      { path: 'Registration', component: RegistrationComponent, data: { breadcrumb: 'Registration' } },
      //{ path: 'CustomerLogin', component: LoginComponent },
      { path: 'Cart', component: CartComponent, data: { breadcrumb: 'Cart' } },
      { path: 'Login', component: LoginComponent, data: { breadcrumb: 'Login' } },
      { path: 'CustomerOrders', component: PateintOrdersComponent, data: { breadcrumb: 'PateintOrders' }  },
      { path: 'OrgansTests', component: OrganBasedTestsComponent, data: { breadcrumb: 'OrgansTests' } },
      { path: 'UpdateProfile', component: UpdateProfileComponent, data: { breadcrumb: 'UpdateProfile' }  },
      { path: 'Aboutus', component: AboutusComponent, data: { breadcrumb: 'Aboutus' } },
      { path: 'KnowledgeCenter', component: KnowledgeCenterComponent, data: { breadcrumb: 'KnowledgeCenter' } },
      { path: 'HealthPackages', component: HealthPackagesComponent, data: { breadcrumb: 'HealthPackages' } },
      { path: 'BookTest', component: BookTestComponent, data: { breadcrumb: 'BookTest' } },
      { path: 'Blogs', component: BlogsComponent, data: { breadcrumb: 'Blogs' } },
      { path: 'Instruments/:CategoryName', component: LabEquipmentComponent, data: { breadcrumb: 'LabEquipment' } },
      { path: 'ContactUs', component: ContactUsComponent, data: { breadcrumb: 'ContactUs' } },
      { path: 'HealthPackage/:Name', component: MoreInfoComponent, data: { breadcrumb: 'HealthPackage' } },
      { path: 'Ourservices', component: OurservicesComponent, data: { breadcrumb: 'Ourservices' } },
      { path: 'NewsDetails', component: NewsDetailsComponent, data: { breadcrumb: 'News details' } },
      { path: 'LabInstrument/:jame', component: LabInstrumentsComponent, data: { breadcrumb: 'Instruments' } },  
      { path: 'AboutCDC', component: AboutCDCComponent, data: { breadcrumb: 'About CDC' } },
      { path: 'DrLaxshmi', component: DrlaxshmiComponent, data: { breadcrumb: 'Dr V.Laxshmi' } },

      { path: 'DrRamakrishna', component: DrRamakrishnaComponent, data: { breadcrumb: 'Dr Ramakrishna' } },
      { path: 'DrSubhadra', component: DrSubhadraComponent, data: { breadcrumb: 'Dr subhadra' } },
  { path: 'OurTeam', component: OurTeamComponent, data: { breadcrumb:  'Leader ship' } },
      { path: 'Biochemistry', component: BiochemistryComponent, data: { breadcrumb: 'Biochemistry' } },
      { path: 'ClinicalPathology', component: ClinicalPathologyComponent, data: { breadcrumb: 'Clinical Pathology' } },

      { path: 'Microbiology', component: MicrobiologyComponent, data: { breadcrumb: 'Microbiology' } },
      { path: 'GeneticsMolecularMedicine', component: GeneticsMolecularMedicineComponent, data: { breadcrumb: 'Genetics Molecular Medicine' } },

      { path: 'HistoCytoPathology', component: HistoCytoPathologyComponent, data: { breadcrumb: 'HistoCyto Pathology' } },
      { path: 'MolecularBiology', component: MolecularBiologyComponent, data: { breadcrumb: 'Molecular Biology' } },
      { path: 'OrderInvoice/:No', component: OrderInvoiceComponent, data: { breadcrumb: 'Invoice' } },
    ]
  },
  {
    
    path: '', 
    component: PagesComponent,
    children:[
      { path: 'Dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
      { path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },

      //{ path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' }  },
      { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } }
    ]
  },

 
  { path: 'AdminLogin', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
 // { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: '**', component: NotFoundComponent }
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: 'legacy',
       useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
