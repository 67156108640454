import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Product } from '../page-lay-out/home/home.component';
import { take, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})

export class CartService {

  constructor(private messageService: MessageService) {
    if (localStorage.getItem('products')) {
      let existingCartItems = JSON.parse(localStorage.getItem('products'));
      if (!existingCartItems) {
        existingCartItems = [];
      }
      this.itemsSubject.next(existingCartItems);
    }
  }

  private itemsSubject = new BehaviorSubject<Product[]>([]);
  items$ = this.itemsSubject.asObservable();

  addToCart(product: Product) {
    localStorage.removeItem('info');
    debugger
    this.items$.pipe(
      take(1),
      map((products) => {
        debugger
        if (product.ISPACKAGE==false) {
          if (!products.some(e => e.TEST_ID === product.TEST_ID)) {
            products.push(product);
            localStorage.setItem('info', 'added');
          }
          else {
            localStorage.setItem('info', 'exists');
          }
        }
        else if (product.ISPACKAGE == true) {
          if (!products.some(e => e.TEST_ID === product.TEST_ID)) {
            products.push(product);
            localStorage.setItem('info', 'added');
          }
          else {
            localStorage.setItem('info', 'exists');
          }
        }
        
        localStorage.setItem('products', JSON.stringify(products));
      }),
    ).subscribe();
  }

  removeFromCart(product: Product,i) {
    debugger
    this.items$.pipe(
      take(1),
      map((products) => {
        debugger
       // products.push(product);
        products.splice(i, 1);
        localStorage.setItem('products', JSON.stringify(products));
      }),
    ).subscribe();
  }
  clearCart() {
    debugger
    this.items$.pipe(
      take(1),
      map(() => {
        debugger
        localStorage.removeItem('products');
      }),
    ).subscribe();
  }
}
