


<div class="container">
 
    <br /><br />
    <div class="col-md-6 d-none d-md-block"style="padding-top:50px;">
    <p> Credence Diagnostics Centre is one of the leading Diagnostic laboratory network in India moving ahead at every step.
    We are currently servicing nearly 2000 doctors & labs all across India. Our vision is to be the undisputed leader in
      providing world class diagnostic services by maintaining highest ethical standards and quality. Year 2014 marks the
      beginning of a new era for Credence as it is launching web based services.</p>
    <p>Credence Diagnostics is an emerging leader in the field of personalized medicine in India, offering diagnostic services
      that enable precision medicine in every clinical department from Biochemistry to Clinical Genetics. </p>
      <!----
  <img  src="../../../assets/img/OurServices/clinical-pathology.jpg" style="width: 100%;">-->
    </div>
    <div class="col-md-6 bg-white mt-10 p-5">
     
        <h3 class="pb-3" style="text-align:center;color:#09cad3;">Login Form</h3>
        <div class="form-style">
          <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
            <div class="form-group pb-3">

              <input formControlName="Mobile" class="form-control validation-field" placeholder=" Enter Mobile" type="text" (keypress)=keyPress($event) maxlength="10">
              <small class="text-danger" *ngIf="form.get('Mobile').touched && form.get('Mobile').hasError('required')">Mobile is required</small>
            </div>
            <div class="pb-2">
              <button type="submit" [disabled]="!form.valid" class="btn btn-info w-100 font-weight-bold mt-2">Submit</button>
            </div>
          </form>

          <div class="pt-4 text-center" routerLink="/Registration">
            Get Members Benefit. <a style="color:blue;cursor:pointer;font-size:16px;">Sign Up</a>
          </div>
        </div>

      </div>
   
</div>


<!--This is for the otp number to be displayed in the popup model.-->
<div class="modal" id="myModal3">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" >Enter OTP received on {{PhoneNo}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"style="color:black;">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>Enter OTP<span style="color:red">*</span></label>
          <input [(ngModel)]="otp" class="form-control Caps" placeholder="Enter valid OTP" type="text">
        </div>
        <div class="row" style="float:right;cursor:pointer;margin-right:2px;">
          <a class="resend" (click)="SendOtp()">Resend OTP</a>
          <!-- <button type="submit" class="btn btn-primary" (click)="VerifyOtp(otp)">Submit</button>-->
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="VerifyOtp(otp)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>



