import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent implements OnInit {

  grandTotal: any;
  billNo: any;
  orderDate: any;
  orderDetails: any = [];
  orderDetails1: any = [];
  orderDetails2: any = [];
  ORDERID: string;
  LoginDetails: any;

  constructor(public generalService: GeneralService, public activeroute: ActivatedRoute) {
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    this.ORDERID = this.activeroute.snapshot.paramMap.get('No')
  }

  ngOnInit(): void {
    this.getOrdersbasedOnOrderDetails()
  }
  // getting orderinvoice based on orderid details 
  getOrdersbasedOnOrderDetails() {
 
    let formData: FormData = new FormData();
    formData.append('ORDERID', this.ORDERID);
    var url = "api/Values/GetInvoiceDetailsBasedOnOrderId"
    this.generalService.PostData(url, formData).then((data: any) => {
      debugger
      if (data != "") {
        this.grandTotal = 0;
        this.orderDetails = []
        this.orderDetails = data.listorder;
        this.orderDetails1 = data.listtest;
        this.orderDetails2 = data;
        for (var i = 0; i < this.orderDetails1.length; i++) { 
          this.grandTotal = this.grandTotal + this.orderDetails1[i].PRICE
          this.grandTotal = this.grandTotal + this.orderDetails1[i].PacPrice
        }
      } 
    });
  }

  print():
    void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    //popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          
* {
  font-size: 14px;
  font-family: Arial !important;
width:100%;
}



td,
th,
tr,
table {
  border-top: 1px solid black;
  font-size: 12px;
width: 100% !important;

}
.ticket {
  width: 100% !important;
  /*max-width: 155px;*/
  max-width: 100% !important;
font-family: Arial !important;
  font-size: 14px;
}

img {
  max-width: inherit;
  width: inherit;
}

@media print {
  body {
font-family: Arial !important;
        height:100px; 
        width: 100%;


        /* to centre page on screen*/
       
    }
  .hidden-print,
  .hidden-print * {
    display: none !important;
width:100%;
  }
}
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
