import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { VERSION } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
declare var $: any

@Component({
  selector: 'app-organ-based-tests',
  templateUrl: './organ-based-tests.component.html',
  styleUrls: ['./organ-based-tests.component.scss']
})
export class OrganBasedTestsComponent implements OnInit {
  cartProducts: any = [];
  organBasedTests: any = [];
  organBasedTestsList: any = [];

  organ: any;
  TestName: any;
  SAMPLE: any;
  organName: any;
  organId: any;
  PRICE: any;
  TESTCODE: any;
  a2zTests: any=[];
  a2zTestsList: any = [];
  PTests: any = [];
  HomeUrl: any;
  url: any;
  constructor(fb: FormBuilder, public formBuilder: FormBuilder, public http: HttpClient, private messageService: MessageService, public generalService: GeneralService, public cartService: CartService, public router: Router) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });
    debugger
    this.cartProducts = JSON.parse(localStorage.getItem('products'));
    this.organ = JSON.parse(localStorage.getItem('organ'));
    this.organName = this.organ.OrganName
    this.organId = this.organ.OrganId


    
  }

  ngOnInit(): void {
    this.getOrganBasedTests();
    this.get_A_to_Z_Tests();
  }
  //=================Getting OrganBasedTests data from BackEnd using general service=====================//
  getOrganBasedTests() {
    var UploadFile = new FormData();
    var url = "api/Values/getOrganBasedTests";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.organBasedTests = data;
        this.url = localStorage.getItem('url');
        this.organBasedTestsList = data.filter(a => a.OrganId == this.organ.OrganId);
        for (var i = 0; i < this.organBasedTests.length; i++) {
          this.organBasedTests[i].OverView = decodeURIComponent(this.organBasedTests[i].OverView);
          this.organBasedTests[i].Symptoms = decodeURIComponent(this.organBasedTests[i].Symptoms);
          this.organBasedTests[i].AtRisk = decodeURIComponent(this.organBasedTests[i].AtRisk);
          this.organBasedTests[i].DiagnosisAndTests = decodeURIComponent(this.organBasedTests[i].DiagnosisAndTests);
          this.organBasedTests[i].TypesOfDiseases = decodeURIComponent(this.organBasedTests[i].TypesOfDiseases);
          this.organBasedTests[i].Prevention = decodeURIComponent(this.organBasedTests[i].Prevention);
        }

      }
      else {
        console.log('no data')//sandhya
      }
    }, err => {
      console.log('error')//sandhya
    });
  }


  //=================Getting A2Z Tests data from BackEnd using general service=====================//
  get_A_to_Z_Tests() {
    let arr1 = [];
    arr1.push(
      {
        
        TestName:'',
        TESTCODE: '',
        SAMPLE: '',
       PRICE: '',
     
       
      }
    )

    var UploadFile = new FormData(); 
    UploadFile.append("Xml", JSON.stringify(arr1));
    UploadFile.append("param", this.organId);
    var url = "api/Values/GetTests_xmldata_basedon_organid";
    //var url = "api/Values/Get_TestsDetails";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.PTests = data;
       
        this.a2zTests = data;    //All Tests
        this.a2zTestsList = data ;    //All Tests
      }
      else {
        console.log('no data')//sandhya
      }
    }, err => {
      console.log('error')//sandhya
    });
  }
  organTests() {
    debugger
    this.a2zTests = this.a2zTestsList.filter(a => a.organId == this.organId)
  }
  // This code uesed for  To click the Organ  show in  Organ information loike (over view risks and syntoms),
  //Tests and also show in related test  the test add to cart
  addToCart(item) {
    debugger
    var TEST_ID;
    this.messageService.clear();
    let arr = [];
    arr.push(
      {
        TEST_ID: item.TEST_ID
        , TESTNAME: item.TestName
        , TESTCODE: item.TESTCODE
        , PRICE: item.PRICE
        , ISPACKAGE: false
      }
    )
    this.cartService.addToCart(arr[0]);
    debugger
    if (localStorage.getItem('info') == 'added') {
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Organ Based Test added to the cart successfully' });

    }
    else if (localStorage.getItem('info') == 'exists') {
      this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Organ Based Test already added to the cart' });

    }
  }
}
