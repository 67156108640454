import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { ToastrModule } from 'ngx-toastr';
import { GMapModule } from 'primeng/gmap'
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';

import { MessageService } from 'primeng/api';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 
import { POSITION_OPTIONS, GeolocationService } from '@ng-web-apis/geolocation';
import { AutoCompleteModule } from 'primeng/autocomplete';


import { PipesModule } from './theme/pipes/pipes.module';

import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PageLayOutComponent } from './page-lay-out/page-lay-out.component';
import { HomeComponent } from './page-lay-out/home/home.component';
import { PageHeaderComponent } from './page-lay-out/page-header/page-header.component';
import { PageFooterComponent } from './page-lay-out/page-footer/page-footer.component';
import { RegistrationComponent } from './page-lay-out/registration/registration.component';
import { LoginComponent } from './page-lay-out/login/login.component';
import { CartComponent } from './page-lay-out/cart/cart.component'; 
import { DataService } from './Services/data.service';
import { PateintOrdersComponent } from './page-lay-out/pateint-orders/pateint-orders.component';
import { UpdateProfileComponent } from './page-lay-out/update-profile/update-profile.component';
import { OrganBasedTestsComponent } from './page-lay-out/organ-based-tests/organ-based-tests.component';
import { AboutusComponent } from './page-lay-out/aboutus/aboutus.component';
import { KnowledgeCenterComponent } from './page-lay-out/knowledge-center/knowledge-center.component';
import { BookTestComponent } from './page-lay-out/book-test/book-test.component';
import { HealthPackagesComponent } from './page-lay-out/health-packages/health-packages.component';
import { BlogsComponent } from './page-lay-out/blogs/blogs.component'
import { LabEquipmentComponent } from './page-lay-out/lab-equipment/lab-equipment.component';
import { ContactUsComponent } from './page-lay-out/contact-us/contact-us.component';
import { MoreInfoComponent } from './page-lay-out/more-info/more-info.component';
import { NewsDetailsComponent } from './page-lay-out/news-details/news-details.component';
import { LabInstrumentsComponent } from './page-lay-out/lab-instruments/lab-instruments.component';
import { AboutCDCComponent } from './page-lay-out/about-cdc/about-cdc.component';
import { OurTeamComponent } from './page-lay-out/our-team/our-team.component';
import { BiochemistryComponent } from './page-lay-out/biochemistry/biochemistry.component';
import { ClinicalPathologyComponent } from './page-lay-out/clinical-pathology/clinical-pathology.component';
import { MicrobiologyComponent } from './page-lay-out/microbiology/microbiology.component'
import { GeneticsMolecularMedicineComponent } from './page-lay-out/genetics-molecular-medicine/genetics-molecular-medicine.component';
import { HistoCytoPathologyComponent } from './page-lay-out/histo-cyto-pathology/histo-cyto-pathology.component';
import { MolecularBiologyComponent } from './page-lay-out/molecular-biology/molecular-biology.component';
import { DrRamakrishnaComponent } from './page-lay-out/dr-ramakrishna/dr-ramakrishna.component';
import { DrSubhadraComponent } from './page-lay-out/dr-subhadra/dr-subhadra.component';
import { OrderInvoiceComponent } from './page-lay-out/order-invoice/order-invoice.component';
import { DrlaxshmiComponent } from './page-lay-out/drlaxshmi/drlaxshmi.component';

@NgModule({  
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    TableModule,
    PerfectScrollbarModule, AutoCompleteModule,
    NgbModule,
    RadioButtonModule,
    DropdownModule,
    GMapModule,
    MultiselectDropdownModule, ToastModule, ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ToastrModule.forRoot(), ToastrModule,
    PipesModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FlagsMenuComponent,
    SideChatComponent,
    FavoritesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    PageLayOutComponent,
    HomeComponent,
    PageHeaderComponent,
    PageFooterComponent,
    RegistrationComponent,
    LoginComponent,
    CartComponent,
    PateintOrdersComponent,
    UpdateProfileComponent,
    OrganBasedTestsComponent,
    AboutusComponent,
    KnowledgeCenterComponent,
    BookTestComponent,
    HealthPackagesComponent,
    BlogsComponent,
    LabEquipmentComponent,
    ContactUsComponent,
    MoreInfoComponent,
    NewsDetailsComponent,
    LabInstrumentsComponent,
    AboutCDCComponent,
    OurTeamComponent,
    BiochemistryComponent,
    ClinicalPathologyComponent,
    MicrobiologyComponent,
    GeneticsMolecularMedicineComponent,
    HistoCytoPathologyComponent,
    MolecularBiologyComponent,
    DrRamakrishnaComponent,
    DrSubhadraComponent,
    OrderInvoiceComponent,
    DrlaxshmiComponent
  ],
  providers: [ 
    AppSettings, MessageService, DataService,
    {
      provide: POSITION_OPTIONS,
      useValue: { enableHighAccuracy: true, timeout: 3000, maximumAge: 1000 },
    },],
  bootstrap: [ AppComponent ]
})
export class AppModule { } 
