

<footer class="mainfooter" role="contentinfo">
  <div class="footer-middle">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <!--Column1-->
          <div class="footer-pad">
            <h5 class="useful">Useful Links</h5>
            <ul class="list-unstyled" style="font-family:Roboto, sans-serif;font-Size:14px;">
              <li> <a class="nav-link booka" routerLink="/BookTest">Book A Test</a></li>
              <li> <a class="nav-link booka" routerLink="/HealthPackages">Health Packages</a></li>
            </ul>

            <!---<h5> About Us</h5>
  <p>
    Credence Diagnostics Centre is one of the leading Diagnostic laboratory network in India moving ahead at every step. We are currently servicing nearly 2000 doctors & labs all across India. Our vision is to be the undisputed leader in providing world class diagnostic services by maintaining highest ethical standards and quality. Year 2014 marks the beginning of a new era for Credence as it is launching web based services. Credence Diagnostics is an emerging leader in the field of personalized medicine in India,
  </p>-->
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <!--Column1-->
          <div class="footer-pad">
            <h5 class="useful"> Quick Links</h5>
            <ul class="list-unstyled" style="font-family:Roboto;font-Size:14px; ">
              <li><a href="../../../assets/img/TermsAndConditions.pdf" alt="credence Diagnostics center"target="_blank" class="links">Terms And Conditions</a></li>
              <br />
              <li><a href="../../../assets/img/PrivacyPolicy.pdf"alt="credence Diagnostics center" target="_blank" class="links">Privacy Policy</a></li>
              <br />
           
              <li><a href="../../../assets/img/FAQ's.pdf"class="links" alt="credence Diagnostics center"target="_blank">FAQs</a></li>
              <!--<a href="../../../assets/img/FAQ's.pdf">../../../assets/img/FAQ's.pdf</a>-->
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <!--Column1-->
          <div class="footer-pad">
            <h5 class="info" >Information</h5>
            <ul class="list-unstyled" style="font-family:Roboto, sans-serif;font-Size:14px;">
              <li> <a class="nav-link blogs" routerLink="/Aboutus">About Us</a></li>
              <li> <a class="nav-link blogs" routerLink="/Blogs">Blogs</a></li>
              <li> <a class="nav-link blogs" routerLink="/NewsDetails">News</a></li>
              <li> <a class="nav-link blogs" routerLink="/AdminLogin" >Admin Login</a></li>

              <li>
                <!--<a class="nav-link" style="font-size: 30px;
    letter-spacing: 0.3px;" target="_blank" href="https://www.justdial.com/Hyderabad/Credence-Diagnostics-Moula-Ali/040PXX40-XX40-150728191821-W4M2_BZDET"><span style="color:#1a6dba;">Just</span><span style="color:#f56a15;">Dial</span></a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <h5  class="follow">Follow Us</h5>
          <ul class="social-network social-circle">
            <li>
              <a href="https://www.facebook.com/credencediagnostics/"target="_blank" style="cursor:pointer" alt="credence Diagnostics center"class="icoFacebook" title="Facebook">
                <i class="fa fa-facebook"></i>
              </a>
            </li>
           
            <li>
              <a  href="https://www.linkedin.com/signup" target="_blank" style="cursor:pointer"alt="credence Diagnostics center"class="icoLinkedin" title="Linkedin">
                <i class="fa fa-linkedin"></i>
              </a>
            </li>
            
            <li>
              <a  href="https://www.youtube.com/" target="_blank" style="cursor:pointer" class="icoyoutube"alt="credence Diagnostics center" title="Youtube">
                <i class="fa fa-youtube-play"></i>
              </a>
            </li>
            
          </ul>
        </div>
        <div class="col-lg-12">
          <h1 > <a class="nav-link_1 " routerLink="/BookTest"style="font-size:30px;">Home Sample Collection Hyderabad - Book an Appointment Online</a></h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 copy">
          <div class="row">
            <div class="col-lg-6">
              <div class="footcrs">
                Credence Diagnostics © 2022
              </div>
            </div>
            <div class="col-lg-6" style="text-align:right">
              <div> Powered by :<a href="https://gagri.net/" style="color:#fff" target="_blank"> Gagri Global IT Services Pvt. Ltd.</a> </div>

            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</footer>

