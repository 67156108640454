import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { BrowserModule, DomSanitizer } from '@angular/platform-browser'
declare var google: any;

@Component({
  selector: 'app-lab-instruments',
  templateUrl: './lab-instruments.component.html',
  styleUrls: ['./lab-instruments.component.scss']
})
export class LabInstrumentsComponent implements OnInit {
  
  Pincode: any;
  Name: any;
  lat: any;
  lng: any;
  addressDetails: any;
  addressId: any;
  Cartimesforloginusers: any = [];
  ProductInfo: any;
  Description: any;
  UsedTo: any;
  CustomerMobile: any;
  logedinUser: any;
  LoginDetails: any;
  seletedItemforWishlist: any = [];
  wishListItems: any = [];
  whatsAppProductURL: string;
  TotalEquipments: any = 1;
  itemSize: any;
  ItemId: any;
  Quantity: any;
  currntPacksize: any;
  currentPrice: any;
  Title: any;
  CATEGORINAME: any;
  HomeURL: any;
  productDetails: any;
  cartItemList: any = []
  selectedItemforCart: any = [];
  Mobile: any;
  HNo: any;
  Area: any;
  CustomerName: any;
  Email: any;
  Landmark: any;
  City: any;
  State: any;
  Comments: any;
  videoUrl: any;
    EnquiryAry: any[];
 
  constructor(public generalService: GeneralService, public http: HttpClient,
    public activeroute: ActivatedRoute
    , public router: Router,
    public toast: MessageService, private sanitizer: DomSanitizer) {
    this.Title = this.activeroute.snapshot.paramMap.get('jame').replace(/\_/g, " ").trim();
    this.LoginDetails = JSON.parse(localStorage.getItem("LoginDetails"));
    this.generalService.getequipment().subscribe(res => {
      if (res.length == 0) {//if category id is null
        //this.GetProductDetailsbasedonProductName()
      }
      else {
        this.Title = res[0].Title;
        
        //this.productDetails = res;   
      
        //this.Description = decodeURIComponent(this.productDetails[0].Description);
        //this.UsedTo = decodeURIComponent(this.productDetails[0].UsedTo ? this.productDetails[0].UsedTo:"");
      }
      this.GetProductDetailsbasedonProductName() /// sandhya
    })
    
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
    navigator.geolocation.getCurrentPosition(position => {
      this.lat = (position.coords.latitude) * 1
      this.lng = (position.coords.longitude) * 1
    //  this.getLocation(this.lat, this.lng)

     
    });
  }

  ngOnInit(): void {
   
    if (this.LoginDetails) {
      this.getCustomerAddress();
      this.GetProductDetailsbasedonProductName();
    }
 
  }
  /*
   Getting equipment details based on their equipment name 
   */
  GetProductDetailsbasedonProductName() {
    debugger
    var UploadFile = new FormData()
    UploadFile.append("Param", this.Title)
    var url = "/api/Values/GetAllCategoryWiseequipmentBasedOn_eqipmenetName"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.productDetails = data     
      this.Description = null;
      this.UsedTo = null;
      let prodarr = this.productDetails.filter(A => A.Title == this.Title)//Filtering Categories data based on selected chemical name for getting category id    

      this.Description = prodarr[0].Description;   
      this.UsedTo = decodeURIComponent(prodarr[0].UsedTo);
      
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

 

  /*
   *Below method is for sharing url on whatsapp 
   */
  sendWhatsapp(item) {
    var prodName = ""
    var str = item.productname.split(" ")//spliting the category name into string array where space occured
    for (let i = 0; i < str.length; i++) {
      prodName += "_" + str[i];
    }
    this.whatsAppProductURL = "https://ykmart.breakingindiaapp.com/%23/Product/" + prodName
    window.open("https://api.whatsapp.com/send?&text=" + this.whatsAppProductURL)
  }


  /** 
   * below method is for getting loged in user name
   */
  logedin() {
    this.logedinUser = localStorage.getItem('token');
    return this.logedinUser;
  }
  /*
   *Get Customer Delivery Address 
   */
  getCustomerAddress() {
    let Address = [{
      UserId: this.LoginDetails.Id
    }];
    let formData: FormData = new FormData();
    formData.append('Param', JSON.stringify(Address));
    var url = "api/Values/CRED_Get_Customer_Address_Based_On_Id"    
    this.generalService.PostData(url, formData).then((data: any) => {
      if (data != "") {
        this.addressDetails = data;
      }
    });
  }

  /*
  get selected addresss of customers
   */
  getSelectedAddress(item) {
    this.addressId = item
  }



  /* ProductName for navigation*/
  navigatingtoequipment(data) {
    var equpName = "";
    var str = data.split(" ")//spliting the equpName into string array where space occured
    for (let i = 0; i < str.length; i++) {
      equpName += "_" + str[i];
    }
    this.router.navigate(['/Instruments/' + equpName]);
  }

  
}
