
<div class="container-fluid blk" style="background: #3498db30;">
  <div class="row " style="margin-right:85px;">
    <div class="col-lg-6">
    <h6 style="margin-left:120px;font-weight:700;cursor:pointer;"><a class="nav-itemm" routerLink="/BookTest">Home Sample Collection Hyderabad</a> </h6></div>
    <div class="col-lg-6" style="text-align:right">
      <div class="navbar_H ">


        <a class="active" href="tel:+91 9814411441"><i class="fa fa-fw fa-phone"></i> +91 9814411441 / 9593779377</a>
        <a (click)="View()"style="cursor:pointer;"><i class="fa fa-fw fa-phone"></i> Request a call back</a>
        <a href="#/ContactUs"><i class="fa fa-fw fa-map-marker"></i> Hyderabad</a>
        <a href="https://api.whatsapp.com/send?phone=919814411441&text=&source=&data=" target="_blank"><i class="fa fa-fw fa-whatsapp"></i> </a>
      </div>

    </div>

  </div>

</div>


<!--<div class="container-fluid top-container" style="background:#3498db;padding:2px">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <a href="tel:+91 7569041272" class="head_font">
          <i class="fa fa-phone"></i
          Call Chennai: +91 7569041272
        </a>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12" style="text-align:right">
        <a href="tel:+91 9182057756" class="head_font">
          <i class="fa fa-phone"></i> Call  Hyderabad : +91 9182057756
        </a>
      </div>
    </div>
  </div>
</div>-->
<div class="container-fluid header" id="myHeader" style="box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light navbar-fixed-top" style="padding: 1% 0% 0% 0%;">

     
        <li class="navbar-toggler button_0" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon icon"></span>
        </li>
        <a class="navbar-brand" href="#" style="padding:0">
          <img width="80%" src="../../../assets/img/profile/Crlogo.png" alt="Credence Diagnostics Center" class="page" />
        </a>
        <li class="navbar-toggler button_0" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">

          <i class="fa fa-search search_1" routerLink="/BookTest"></i>
        </li>
        <i class="navbar-toggler bundi_1" data-toggle="collapse" data-target="#navbarNavDropdown"
           aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <p class="nav-item cart">
            <a class="nav-link" routerLink="/Cart"><i class="fa fa-cart-arrow-down " aria-hidden="true">({{(items$ | async).length}})</i></a>
          </p>
        </i>
    



      <div class="collapse navbar-collapse" id="navbarNavDropdown"style="font-weight:500">
        <ul class="navbar-nav "style="margin-left: -23px;">
          <li class="nav-item active">
            <a routerLink="/Home" class="nav-link home">Home</a>
          </li>



          <div ngbDropdown class="d-inline-block about">
            <a id="dropdownBasic1" ngbDropdownToggle class="about_1">About Us</a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop">
              <button ngbDropdownItem routerLinkActive="active" routerLink="/AboutCDC" style="cursor:pointer;">About CDC</button>
              <button ngbDropdownItem routerLinkActive="active" routerLink="/OurTeam" style="cursor:pointer">Leadership</button>
            </div>
          </div>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle int" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Instruments</a>
            <ul class="dropdown-menu drop_2" aria-labelledby="dropdown1" *ngIf="Categories?.length>0">
              <li class="dropdown-item" *ngFor="let cat of Categories" (click)="FilterList(cat.CATEGORINAME)"> {{cat.CATEGORINAME}}</li>

            </ul>
          </li>&nbsp;&nbsp;
          <div ngbDropdown class="d-inline-block services">
            <a id="dropdownBasic1" ngbDropdownToggle style="color: black;cursor:pointer;font-family:roboto;font-size:18px;">Services</a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="drop_1">
              <button ngbDropdownItem routerLinkActive="active" routerLink="/Biochemistry" style="cursor:pointer;">Biochemistry</button>
              <button ngbDropdownItem routerLinkActive="active" routerLink="/ClinicalPathology" style="cursor:pointer;">Clinical Pathology</button>
              <button ngbDropdownItem routerLinkActive="active" routerLink="/Microbiology" style="cursor:pointer;">Microbiology</button>
              <button ngbDropdownItem routerLinkActive="active" routerLink="/GeneticsMolecularMedicine" style="cursor:pointer;">Genetics Molecular Medicine</button>
              <button ngbDropdownItem routerLinkActive="active" routerLink="/HistoCytoPathology" style="cursor:pointer;">Histo Pathology</button>
              <button ngbDropdownItem routerLinkActive="active" routerLink="/MolecularBiology" style="cursor:pointer;">Molecular Biology</button>
            </div>
          </div>
          <li class="nav-itemm">

            <button mat-button color="primary" routerLink="/BookTest" class="book">
              Book A Test
            </button>

          </li>


          <!----  <div ngbDropdown class="d-inline-block" style="margin-top: 18px;">
        <a id="dropdownBasic1" ngbDropdownToggle style="color: black;cursor:pointer;">Services</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style=" font-size:14px;cursor:pointer;">
          <button ngbDropdownItem routerLinkActive="active" routerLink="/Biochemistry" style="cursor:pointer;">Biochemistry</button>
          <button ngbDropdownItem routerLinkActive="active" routerLink="/ClinicalPathology" style="cursor:pointer;">Clinical Pathology</button>
          <button ngbDropdownItem routerLinkActive="active" routerLink="/Microbiology" style="cursor:pointer;">Microbiology</button>
          <button ngbDropdownItem routerLinkActive="active" routerLink="/GeneticsMolecularMedicine" style="cursor:pointer;">Genetics Molecular Medicine</button>
          <button ngbDropdownItem routerLinkActive="active" routerLink="/HistoCytoPathology" style="cursor:pointer;">Histocyto Pathology</button>
          <button ngbDropdownItem routerLinkActive="active" routerLink="/MolecularBiology" style="cursor:pointer;">Molecular Biology</button>
        </div>
      </div>-->

          <li class="nav-item">
            <a class="nav-link contactus" routerLinkActive="active" routerLink="/ContactUs" style="cursor:pointer;font-family:roboto;">Contact Us</a>
          </li>



          <!----<li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor:pointer;">Instruments</a>
        <ul class="dropdown-menu" aria-labelledby="dropdown1" style="font-size:14px">
          <li class="dropdown-item" *ngFor="let cat of Categories" (click)="FilterList(cat.CATEGORINAME)"> {{cat.CATEGORINAME}}</li>

        </ul>
      </li>  -->

          <li>
            <!-- this code use for search icon to click navigate on bookatest page-->
            <i class="fa fa-search search_1" routerLink="/BookTest"></i>

        

          </li>


          <li class="nav-item cart">
            <a class="nav-link" routerLink="/Cart"><i class="fa fa-cart-arrow-down " aria-hidden="true">({{(items$ | async).length}})</i> Cart</a>
          </li>

        </ul>






        <ul *ngIf="!loggedIn">
          <div routerLink="/Login">
            <i class="fa fa-user-o login"></i>
            <a style="cursor:pointer;font-size:15px;">Login</a>

          </div>
        </ul>
        <ul *ngIf="loggedIn" class="usericon">
          <li class="nav-item" style="list-style-type: none;">
            <div class="dropdown" style="margin-top: 18px;">
              <span class="profileIcon"><b>{{logindata.EMP_NAME| slice:0:1}}</b></span>
              <!--<i class="fa fa-user-o" style="padding-right: 4px !important;font-size:20px ; margin-top: 11px; color:#ff9900;cursor:pointer"></i>-->
              <div class="dropdown-content">
                <a routerLink="/UpdateProfile">{{logindata.EMP_NAME | titlecase}}(My Account)</a>
                <a routerLink="/CustomerOrders">My Bookings</a>
                <!--<a routerLink="/UpdateProfile">Update Profile</a>-->
                <a (click)="Logout()">Logout</a>
              </div>
            </div>
          </li>
        </ul>

        <!--<div *ngIf="!loggedIn">
      <ul style="list-style: none;display: flex;margin:0">
        <i class="fa fa-user-o" style="padding-right: 4px !important;font-size:20px ; color:#ff9900;"></i>
        <li routerLink="/Login" style="cursor:pointer;outline:none">Login</li>
      </ul>
    </div>
    <div *ngIf="loggedIn">
      <ul style="list-style: none;display: flex;margin:0">
        <li (click)="Logout()" style="cursor:pointer;outline:none" routerLink="/Home">
          <span style="color: #ff9900;font-weight: bold;"><i class="fa fa-user-o" style="padding-right: 4px !important;"></i></span>

          Hi |{{logindata.EMP_NAME | titlecase}}
        </li>
      </ul>
    </div>-->

      </div>
    </nav>
  </div>
  
</div>

<!--this code use for to click the requestacallback open model view then enter your  the name mobile number   after sumbit  then send enquiry send to credence admin--->
<div class="modal" id="myModal7">
  <p-toast position="top-Right"></p-toast>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-warning" style="background-color:#076dbf">
        <h4 style="text-align:left;" class="modal-title" id="modal-warning-label">Enter Your Details</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" style=" color:white">×</span></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form">
          <div class="form-group required">
            <label for="input-telephone" class="control-label"> Name</label>
            <!--<input type="text" formControlName="Name" class="form-control" id="txtPhone" placeholder="Enter Name">-->
            <input class="form-control" formControlName="Name" placeholder="Enter Name  " />
            <small class="text-danger" *ngIf="form.controls.Name.touched && form.controls.Name.errors?.required">Name is required</small>

          </div>
          <div class="form-group required">
            <label for="input-telephone" class=" control-label"> Mobile Number</label>
            <input class="form-control" formControlName="Mobile" (keypress)=keyPress($event) maxlength="10" placeholder="Enter Mobile No  " />
            <small class="text-danger" *ngIf="form.controls.Mobile.touched && form.controls.Mobile.errors?.require">Mobile is required</small>

          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        <button type="button" data-intervaldata-dismiss="modal" style="margin-bottom:3px;" class="btn btn-success" (click)="Submit(form.value)">Submit</button>
      </div>
    </div>
  </div>
</div>

