import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
@Component({
  selector: 'app-lab-equipment',
  templateUrl: './lab-equipment.component.html',
  styleUrls: ['./lab-equipment.component.scss']
})
export class LabEquipmentComponent implements OnInit {
  Categories: any = [];
  Equipments: any = [];
  categoryId: any;
  CatName: any;
  HomeURL: any;
  CategoryName: any;
  DEPT_ID: any;
  catData: any;

  constructor(public router: Router, public generalService: GeneralService,
    public http: HttpClient, public activeroute: ActivatedRoute) {
    debugger
    this.CategoryName = this.activeroute.snapshot.paramMap.get('CategoryName').replace(/\_/g, " ").trim();   
    this.getAllCategories()
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeURL = data.Webservice;
    });
  }

  ngOnInit(): void {
    //this.getAllCategories();
  }


  getAllCategories() {
    debugger
    var url = 'api/Values/GetCategories';
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.Categories = data;
      this.catData = this.Categories.filter(A => A.CATEGORINAME === this.CategoryName)
      this.categoryId = this.catData[0].CATEGORYID
      this.getCategoryBasedonCategoryID()

    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
    })
  }
  
  onclickmethodforLeftsideCategories(categoryId, name) {
    debugger
    this.categoryId = categoryId;
    this.CategoryName = name;
    this.getCategoryBasedonCategoryID();
   
  }

  //Below method is used to get the Equipments based on category Id
  getCategoryBasedonCategoryID() {
    debugger
    let arr = [];
    arr.push({ DEPT_ID: this.categoryId })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(arr))
     var url = "api/Values/Get_categories_basedon_euipmentId"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.Equipments = data     
         }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });

  }
  

  
    navigatingToInstrument(data) {
      debugger
      var prodName = ""; var prodarr = []
      prodarr = this.Equipments.filter(A => A.Title == data.Title)//Filtering Categories data based on selected chemical name for getting category id    
      var str = data.Title.split(" ")//spliting the category name into string array where space occured
      for (let i = 0; i < str.length; i++) {
        prodName += "_" + str[i];
      }
      this.generalService.setequipment(prodarr);
      this.router.navigate(['/LabInstrument/' + prodName]);
    }

}



