
<div id="myCarousel" class="carousel slide" data-ride="carousel" style="  width: 100%;">
  <!-- Indicators -->
  <ol class="carousel-indicators">
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    <li data-target="#myCarousel" data-slide-to="2"></li>
    <li data-target="#myCarousel" data-slide-to="3"></li>
    <li data-target="#myCarousel" data-slide-to="4"></li>
    <li data-target="#myCarousel" data-slide-to="5"></li>
    <li data-target="#myCarousel" data-slide-to="6"></li>
    <li data-target="#myCarousel" data-slide-to="7"></li>
  </ol>

  <!-- Wrapper for slides -->
  <div class="carousel-inner">
    <div class="carousel-item slider_image_1 active">
      <div class="slider_heading card-image ">
        <!--Committed to High Quality Diagnostics-->
      </div>
      <div class="container">
        <br />
        <span class="subtxt">
          <!--Credence is a world pioneer in the development of medical<br />
        devices for blood-based laboratory and Point of Care (POCT) testing for<br />
        hospital,professional, and self-testing applications.-->
        </span>

      </div>
    </div>

    <div class="carousel-item slider_image_2">
      <div class="slider_heading card-image ">
        <!--Redefining In-Vitro DiagnosticsS-->
      </div>
      <div class="container">
        <br />
        <span class="subtxt">
          <!--With a unique fingerprint in structure and design, Credence<br />
        ensures top-quality products and high-quality customer service.-->
        </span>
      </div>
    </div>

    <div class="carousel-item slider_image_3">
      <div class="slider_heading card-image">
        <!--Better Diagnostics Today For A Better Life Tomorrow-->
      </div>
      <div class="container">
        <br />
        <span class="subtxt">
          <!--Our goal is to supply advanced analytical and diagnostic analyzers<br />
        and hand-held devices to hospitals, pathological laboratories,<br />
        OEM clients, and patients.-->
        </span>
      </div>
    </div>
    <div class="carousel-item slider_image_4">
      <div class="slider_heading card-image">
        <!--Better Diagnostics Today For A Better Life Tomorrow-->
      </div>
      <div class="container">
        <br />
        <span class="subtxt">
          <!--A full body checkup is health checkup pakage-->
        </span>
      </div>
    </div>
    <div class="carousel-item slider_image_5">
      <div class="slider_heading card-image">
        <!--Better Diagnostics Today For A Better Life Tomorrow-->
      </div>
      <div class="container">
        <br />
        <span class="subtxt">
          <!--couple health package-->
        </span>
      </div>
    </div>
    <div class="carousel-item slider_image_6">
      <div class="slider_heading card-image">
        <!--Better Diagnostics Today For A Better Life Tomorrow-->
      </div>
      <div class="container">
        <br />
        <span class="subtxt">
          <!--couple health package-->
        </span>
      </div>
    </div>

    <div class="carousel-item slider_image_7">
      <div class="slider_heading card-image">

        <span class="subtxt">
          <!--senior health package-->
        </span>
      </div>
    </div>

    <div class="carousel-item slider_image_8">
      <div class="slider_heading card-image">

        <!--<span class="subtxt">
          senior health package
        </span>-->
      </div>
    </div>


    <!-- Left and right controls -->
    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>

<div class="container mt-5">
  <div class="row">

    <div class="col-md-12" style="margin-top:20px;">
      <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
        <div class="row">
          
          <img src="../../../assets/img/credence banners/CDCNewsimage.png" alt="Credence Diagnostics Centre"class="image_6" />
          <!--<img src="../../../assets/img/Arrow.PNG"-->
          <!--<img src="../../../assets/img/Arrow.PNG" style=" margin-left: 9px; height:25px; margin-top: -30px;" />-->
        </div>
       <!--<div class="d-flex flex-row flex-grow-1 flex-fill justify-content-center bg-danger py-2 text-white px-1 news" style="margin-left: -5px"><span class="d-flex align-items-center" style="width: 111px;cursor:pointer; ">CDC News</span></div>-->
        <marquee class="news-scroll" behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" style="margin-top:-32px;">


          <span *ngFor="let item of news; let i=index">
            <span class="dot_1"></span> <a routerLink="/NewsDetails">{{item.Title}} </a>&nbsp;

          </span>

          <!--<a routerLink="/NewsDetails">Credence Diagnostic Centre Best Diagnostic centre in Hyderabad. </a> <span class="dot_1"> <a routerLink="/NewsDetails">Credence Diagnostic Centre World's Biggest Diagnostic centre in India. advanced health centre having World-Class Diagnostic Facilities directly </a> <span class="dot"></span> <a routerLink="/NewsDetails">Credence Diagnostic Centre Best Diagnostic centre in India. </a>-->
        </marquee>
      </div>
    </div>
  </div>
</div>
<br /><br />
<div class="container">
  <!--<div class="col-lg-12" style="text-align:end;margin-bottom:10px;">
    <button (click)="moreHealthPackages()" type="button" class="btn btn-primary">More Health Packages</button>
  </div>-->
  <div class="maniHeader">
    <div class="row" style="margin-bottom:10px;">
      <h2 class="hpackage"> HEALTH PACKAGES</h2>
      <button (click)="moreHealthPackages()" type="button" class="btn btn-primary morehealth">
        More Health Packages
      </button>
      <!----- <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="50" height="50"
      viewBox="0 0 172 172"
      style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#3498db"><path d="M6.88,82.56v6.88h158.24v-6.88z"></path></g></g></svg>  HEALTH PACKAGES-->
    </div>
  </div>
  <!--This is to show some of the health packages.-->

  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6" *ngFor="let item of healthPackages| slice:0:4">
      <div class="health1">
        <div (click)="knowMore(item)">
        <img src="{{HomeUrl+item.Image}}"alt="Credence Diagnostics Centre" class="hPackimge">
        <div style="    padding: 2% 3% 5% 2%;">
          <div class="healthCtext">
            {{item.PACKAGENAME | titlecase}}
          </div>
          <div class="pacCard1">
            {{item.PACKAGECODE || 0}}
          </div>
        </div>
          <hr />
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="know_more1"  (click)="knowMore(item)">
                Know More
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="addto_cart1" (click)="addToCart1(item)">Add to cart</div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>


</div>
<br /><br />
<h3 class="organ"> ORGAN BASED & OTHER TESTS</h3>
<div class="container-fluid orgTests">
  <div class="container">
    <div class="card_padd card_width">
      <div class="maniHeader1">
        <!--<h1 style="font-size:40px; font-family:roboto;color:black;"> ORGAN BASED TESTS</h1>-->
        <!----<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="50" height="50"
        viewBox="0 0 172 172"
        style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#fff"><path d="M6.88,82.56v6.88h158.24v-6.88z"></path></g></g></svg> -->

      </div>
      <div class="row">
        <div style="width:100%;">

          <div class="col-md-2 col-sm-6 col-xs-6 orgIconsPadding" *ngFor="let item of organs" style="cursor:pointer" (click)="navigateToOrganBasedTests(item)">

            <div class="d-flex d-block rounded-circle organ_1">
              <div class="align-self-center m-auto border-0 img_center">
                <img  [src]="url+item.OrganImage" alt="Virus In Lungs Icon - Download in Colored Outline Style" class="organ_2" />
                <br />
                <h3 class="testinglines">{{item.OrganName}}</h3>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--<div id="myCarousel" class="carousel slide" data-ride="carousel" style="  width: 100%;">

        <ol class="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
          <li data-target="#myCarousel" data-slide-to="3"></li>
          <li data-target="#myCarousel" data-slide-to="4"></li>
          <li data-target="#myCarousel" data-slide-to="5"></li>
          <li data-target="#myCarousel" data-slide-to="6"></li>
        </ol>


        <div class="carousel-inner">
          <div >
            <div>
              <ul style="margin-left: -6px;">

                <li class="orgIconsPadding" *ngFor="let item of organs" style="cursor:pointer" (click)="navigateToOrganBasedTests(item)">

                  <div class="d-flex d-block rounded-circle" style="height: 150px; width: 150px;    background: #fff;margin-right:-20px;">
                    <div class="align-self-center m-auto border-0 img_center">
                      <img style="width:100%;height: 100%; border-radius: 5.5rem;" [src]="url+item.OrganImage" alt="Virus In Lungs Icon - Download in Colored Outline Style" />
                    </div>
                  </div>

                </li>
              </ul>
            </div>
            <div class="container">


            </div>
          </div>


          <a class="left carousel-control" href="#myCarousel" data-slide="prev">
            <span class="glyphicon glyphicon-chevron-left"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="right carousel-control" href="#myCarousel" data-slide="next">
            <span class="glyphicon glyphicon-chevron-right"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>-->

    </div>

  </div>
</div>
<br />
<div class="container">
  <div class="maniHeader">
    <h3 class="a2z">A2Z TESTS</h3>
    <button (click)="moreTests()" type="button" class="btn btn-primary more">More  Tests</button>
    <!----<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
     width="50" height="50"
     viewBox="0 0 172 172"
     style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#3498db"><path d="M6.88,82.56v6.88h158.24v-6.88z"></path></g></g></svg>-->

  </div>
  
  
  <ul class="overflow">
    <li [ngClass]="{'testHead1':item==selectedAlphabet,'testHead':item !=selectedAlphabet}" style="cursor: pointer;" (click)="alphabetFilter(item)" *ngFor="let item of alphabet">{{item}}</li>
  </ul>
  <p-toast position="top-right"></p-toast>

  <!--This is used to bind the A2Z dynamic tests.-->
  <div class="row mb-5" style="margin:0 -20px;">
    <div class="col-md-3 col-sm-6 col-xs-6" *ngFor="let item of a2zTests | slice:0:12">
      <div class="testcard" (click)="navigatepage(item)" style="cursor:pointer;border-radius:6px;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; ">
        <div class="card-body">
          <h5 class="card-title testNames" *ngIf="item.Name!=''">{{item.TESTNAME}} - ({{item.Name}})</h5>
          <h5 class="card-title testNames" *ngIf="item.Name==''">{{item.TESTNAME}}</h5>

          <div class="pacCardd">
            {{item.TESTCODE}}
          </div>
          <p>
            <i class="fa fa-angle-double-right pacIcion" aria-hidden="true"></i>
            {{item.SAMPLE}}
          </p>


          <div class="row">
            <div class="col-lg-6">
              <b>Rs - ₹ {{item.PRICE}}</b>
            </div>
            <!--<div class="col-lg-6" style="text-align:end" (click)="addToCart(item)">
              <i class="fa fa-cart-arrow-down pacCart" aria-hidden="true"></i>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <!--<div class="col-lg-12" style="text-align:end">
      <button (click)="moreTests()" type="button" class="btn btn-primary">More  Tests</button>
    </div>-->
  </div>

</div>














