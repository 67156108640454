<div class="container">
  <!--<app-breadcrumb></app-breadcrumb>-->

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/OurTeam">Leadership</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Dr.Subhadra Poornima</li>
    </ol>
  </nav>

<br />
  <br />



  <br /><h2 style="    color: black;  margin-top: -64px; "><b>Dr.Subhadra Poornima M.Sc PhD</b></h2>
  <p style="font-size: 14px;
    color: black;
    margin-left: 22px;margin-top:-9px;"><b>Consultant & HOD,  Certified Level 2 Genetic Counselor- BGCI</b></p>



  <!--<address style="color: #030506;
    margin-top: -12px;
    margin-left: 40px;
    font-size: 13px;">
    <b>
      Certified Level 2 Genetic Counselor- BGCI

    </b>
  </address>-->
  
  <div class="row">

    <div class="col-lg-7">
      <p style="color:black">
        <b> ➣</b>
        She awarded PhD in Human Genetics for her work
        <b style="color:black">“MOLECULAR BIOMARKERS FOR &nbsp;&nbsp;&nbsp;&nbsp;PRIMARY KNEE OSTEOARTHRITIS AND ITS MANAGEMENT” </b> from Osmania University,
        &nbsp;&nbsp;&nbsp;&nbsp;Hyderabad in 2018.
      </p>
  
      <p>
        <b> ➣</b>
        She successfully completed her Masters in Biochemistry from Kakatiya University, &nbsp;&nbsp;&nbsp;&nbsp;Warangal, India in 2009 and
        <b>topped university- </b>Gold Medalist
      </p>

      <p><b>➣</b>  To her credit she has<b> 29 publications</b> in National and International Journals.</p>


      <p><b>➣</b> She is recognised Supervisor from Osmania University for guiding PhD students.</p>

      <p>
        <b> ➣</b>
        Trained several graduate and post graduate doctors and students in cytogenetic, &nbsp;&nbsp;&nbsp;&nbsp;FISH and molecular techniques.
        11 Students successfully completed their PG &nbsp;&nbsp;&nbsp;&nbsp;dissertationthesis under the guidance of Dr. S. Poornima.
      </p>

      <p>
        <b>➣</b>
        Dr. Subhadra Poornima has expertise in <b>Genetic Counselling:</b> Prenatal, &nbsp;&nbsp;&nbsp;&nbsp;Paediatric,Reproductive, Neonatal, Postnatal,
        Premarital, Musculoskeletal, &nbsp;&nbsp;&nbsp;&nbsp;Neurological,Cancer and extended family screening-co segregation analysis, risk &nbsp;&nbsp;&nbsp;&nbsp;assessment etc.
        She is instrumental in Quality control and <b>authorised signatory</b>-NABL.
      </p>

      <p>
        <b> ➣</b>
        Her areas of interest include: Cytogenetics, Molecular Diagnostics, Musculoskeletal &nbsp;&nbsp;&nbsp;&nbsp;and Neuro genetics, Paediatric Genetics,
        Pre Implantation and Prenatal &nbsp;&nbsp;&nbsp;&nbsp;Genetics,Cancer Genetics, Stem Cells & Regenerative Medicine and Genetic  &nbsp;&nbsp;&nbsp;&nbsp;Counselling.
      </p>





    </div>
    <div class="col-lg-5">

      <img style="border-radius:10px; height:400px;width:400px;" src="../../../assets/img/WhatsApp Image 2022-02-23 at 3.26.52 PM.jpeg" alt="Credence Diagnostic Centre"/>
    </div>


  </div>

  <p>
    <b>➣</b>
    Dr. S. Poornima is a member in Professional Societies like Indian Society of Human Genetics, Genetics Society,
    Hyderabad & Board of Genetic &nbsp;&nbsp;&nbsp;&nbsp;Counselling, India.
  </p>





  <br />
  <h3 style="color:#0a3750"><b>Merits & Awards:</b></h3><br />
                                                        <h5>
                                                          <b>➣</b>  Received <b>Research Leadership award of</b> the year 2020 from RULA –In collaboration with World Research Council and &nbsp;&nbsp;&nbsp;&nbsp;United Medical
                                                          Counsel- Jan, 2020
                                                        </h5><br />
                                                             <h5>
                                                               <b>➣</b> Awarded<b> first prize </b>in <b>Postgraduate Quiz during the CME</b> on “ RECENT ADVANCES IN PEDIATRIC GENETICS’ held at NIMS,
                                                               &nbsp;&nbsp;&nbsp;&nbsp;Hyderabad from Indian Academy of Medical Genetics - Nov, 2017
                                                             </h5><br />
  <h5><b> ➣ </b> Received <b>“Travel Grant from Department of Science and Technology, Govt of India</b> for attending<b> American Society of &nbsp;&nbsp;&nbsp;&nbsp;Human Genetics</b> Annual meetings 2015 [ITS/3689/2015-16].
      </h5>

  <h5 ><b>➣  Gold medalist</b> in M. Sc [Biochemistry] from Kakatiya University Warangal-2009   </h5><br />
  <h5><b>➣ </b> Certificate of merit for topping in B. Sc in Biotechnology and biochemistry-2007 </h5>
  <h5><b>➣</b> Certificate of merit for the best student teacher award-2004</h5><br />

</div>
