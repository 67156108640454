<div class="container">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Histopathology-and-Cytology</li>
    </ol>
  </nav>
  <!--<br />

  <br /> <br />-->
  <h1>Histopathology-and-Cytology</h1>
  <div class="row">
    <div class="col-lg-5" >
      <img style="width:100%;border-radius:10px;box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;" src="../../../assets/img/OurServices/Histopathology-and-Cytology.jpg"alt="Credence Diagnostics Centre" />
    </div>
    <div class="col-lg-7">
      <p>
        <b> Histology</b> is the study of tissues, and pathology is the study of disease. Histopathology means the study of tissues related
      to disease. A histopathology report describes the tissue that the pathologist examined.

      </p><p>
  Pathologists process and cut tissue into very thin layers, called sections. Then, they stain and examine under a microscope.
      Using a microscope, they can observe and document the tissue's details.

</p><p>
  A variety of diseases can be identified with a histopathology examination, including ulcerative colitis, Crohn's disease, uterine
      fibroids, cancer, and even infections.
  The main components of a Histopathology report are…..
 
</p>
    <p>
   1.  A description of the appearance of the involved tissue<br />
    2. A diagnosis<br />
      3. A synoptic report detailing the findings of the case<br />
      4. Pathologist's comments.<br />

    </p>
    </div>




  </div>

</div>
