
<br />
<div class="container">
  <div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4 groupCrd">
      <div>
        <h1 class="title" style="font-size:1.9rem;">Registration Account</h1>
        <p>If you already have an account with us, please login at the <a routerLink="/Login">Login Page</a>.</p>
        <!-- this code customer registration form we are enter this fields name mobile
            ,gender age  feilds  after submit open the login form-->
      </div>
      <form [formGroup]="form" class="form-horizontal" (ngSubmit)="onSubmit(form.value)">
        <div class="form-group required">
          <label for="input-telephone" class="control-label"> Name</label>
          <div class="">
            <input type="text" formControlName="Name" class="form-control" id="txtPhone" placeholder="Enter Name">
          </div>
          <small class="text-danger" *ngIf="form.get('Name').touched && form.get('Name').hasError('required')">Name is required</small>

        </div>
        <div class="form-group required">
          <label for="input-telephone" class=" control-label"> Mobile Number</label>
          <div class="">
            <input type="text" formControlName="Mobile" class="form-control" id="txtPhone" placeholder="Enter Mobile No" maxlength="10" (input)="CheckMobileNo()" (keypress)=keyPress($event)>
          </div>
          <small class="text-danger" *ngIf="form.get('Mobile').touched && form.get('Mobile').hasError('required')">Mobile is required</small>
          <span class="text-danger" *ngIf="checkNo==10">Mobile Exist</span>
        </div>


        <div class="form-group required">
          <label for="Email" class=" control-label"> Email</label>
          
          <div class="">
            <input type="text" formControlName="EMAIL" class="form-control form-control-sm"
                   id="EMAIL" placeholder="Enter  Email" />
          </div>
            <small class="text-danger" *ngIf="form.get('EMAIL').touched && form.get('EMAIL').hasError('required')">Email is required
              
           
            </small>
          
        </div>


        <label for="input-telephone" class="control-label"> Select Gender</label>
        <div class="row">
          <div class="col-lg-8">
            <div class="form-group required">
              <!--<input type="radio" value="1" name="Name" formControlName="GENDER"> Male
        <input type="radio" value="2" name="Name" formControlName="GENDER"> Female
        <input type="radio" value="3" name="Name" formControlName="GENDER"> Others-->
              <p-radioButton name="GENDER" value="Male" formControlName="GENDER"></p-radioButton>Male
              <p-radioButton name="GENDER" value="Female" formControlName="GENDER"></p-radioButton>Female
              <p-radioButton name="GENDER" value="Others" formControlName="GENDER"></p-radioButton>Others

              <!--<div style="padding-left:0px">
          <p-autoComplete formControlName="GENDER" [suggestions]="genderList" (completeMethod)="search($event,1)"
                          field="Name" [dropdown]="true" [size]="90"
                          placeholder="Gender" [minLength]="1" [style]="{'height': '33px','width': '100px !important','font-size':'15px !important'}">
          </p-autoComplete>
        </div>-->
              <small class="text-danger" *ngIf="form.get('GENDER').touched && form.get('GENDER').hasError('required')">Gender is required</small>
            </div>
          </div>

          <div class="col-lg-4 age">
            <div class="form-group required">
              <label for="input-telephone" class="control-label"> Age</label>
              <div class="">
                <input type="text" formControlName="AGE" class="form-control" id="txtPhone" placeholder="Enter Age">
              </div>
              <small class="text-danger" *ngIf="form.get('AGE').touched && form.get('AGE').hasError('required')">Age required</small>

            </div>
          </div>
        </div>






        <div class="form-group required">
          <label for="input-telephone" class=" control-label"> City</label>
          <div class="">
            <input type="text" formControlName="CITY" class="form-control" id="txtPhone" placeholder="Enter City">
          </div>
          <small class="text-danger" *ngIf="form.get('CITY').touched && form.get('CITY').hasError('required')">City is required</small>

        </div>
        <div class="form-group required">
          <label for="input-telephone" class=" control-label">Area</label>
          <div class="">
            <input type="text" formControlName="AREA" class="form-control" id="txtPhone" placeholder="Enter Area ">
          </div>
          <small class="text-danger" *ngIf="form.get('AREA').touched && form.get('AREA').hasError('required')">AREA is required</small>

        </div>
        <br />
        <div class="form-group" style="    text-align: center;">
          <button *ngIf="checkNo!=10"[disabled]="!form.valid" class="btn btn-primary" type="submit">Submit</button>
        </div>
      </form>
    </div>
    <div class="col-lg-4"></div>
  </div>
</div>

<style>
  input.ng-invalid.ng-dirty {
    border: 1px solid red;
  }
</style>
<!--<div class="container">

  <div class="row">

    <h1 class="title">Register Account</h1>
    <p>If you already have an account with us, please login at the <a href="/Login">Login Page</a>.</p>

    <form [formGroup]="form" class="form-horizontal" (ngSubmit)="onSubmit(form.value)">
      <div class="form-group required">
        <label for="input-telephone" class="col-sm-2 control-label">Enter Name</label>
        <div class="col-lg-4">
          <input type="text" formControlName="Name" class="form-control" id="txtPhone" placeholder="Name">
        </div>
        <small class="text-danger" *ngIf="form.get('Name').touched && form.get('Name').hasError('required')">Name is required</small>

      </div>
      <div class="form-group required">
        <label for="input-telephone" class="col-sm-2 control-label">Enter Mobile Number</label>
        <div class="col-lg-4">
          <input type="text" formControlName="Mobile" class="form-control" id="txtPhone" placeholder="Mobile No" (keypress)=keyPress($event)>
        </div>
        <small class="text-danger" *ngIf="form.get('Mobile').touched && form.get('Mobile').hasError('required')">Mobile is required</small>

      </div>
      <div class="form-group">
        <button [disabled]="!form.valid" class="btn btn-primary" type="submit">Submit</button>
      </div>
    </form>
  </div>
</div>-->

<!--This is for the otp number to be displayed in the popup model.-->

<div class="modal" id="myModal3">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary">
        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter OTP received on {{PhoneNo}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <label>Enter OTP<span style="color:red">*</span></label>
          <input [(ngModel)]="otp" class="form-control Caps" placeholder="Enter valid OTP" type="text">
        </div>
        <div class="row" style="float:right;cursor:pointer;margin-right:2px;">
          <a class="resend" (click)="SendOtp()">Resend OTP</a>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="VerifyOtp(otp)">Submit</button>
        <button id="closemodal" type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
