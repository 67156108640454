<!--<div class="container">
  <app-breadcrumb></app-breadcrumb>
  <div class="  card" style="margin-bottom:1%" *ngFor="let item of Blogs">
    <div class="row">
      <div class="col-lg-6">  <h2 style="color:#3498db; margin-left: 16px;">{{item.BlogTitle}}</h2></div>
      <div class="col-lg-6" style="text-align:end"> <i><b>{{item.CreatedDate | date:'dd-MMM-yyyy'}}</b></i></div>

      <div class="col-lg-2">
        <img src="{{HomeUrl+item.BlogImage}}" class="card_image" style="height: 137px;margin-left: 18px;border-radius: 5%">
      </div>

      <div class="HideDescription" style="color:#000;font-size: 15px; padding-left: 7.5rem;letter-spacing: -7.5px;" [innerHtml]="item.Discription"></div>

    </div>
  </div>
</div>
<div class="container">-->
<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Blogs</li>
    </ol>
  </nav>

  <div class="  card" style="margin-bottom:1%;box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;" *ngFor="let item of Blogs">
    <div class="row">
      <!-- new title -->
      <h2 style="color:#3498db; margin-left: 29px;font-weight: bold; font-size: 18px;">{{item.BlogTitle}}</h2>
      <div class="col-lg-12" style="text-align:start;margin-left: 16px;
    font-style: initial!important;
    color: #b93956;"><i><b style=" font-style: initial!important">{{item.CreatedDate | date:'dd-MMM-yyyy'}}</b></i></div>
    </div>
    <!--this code use for  onnce insert to image  on admin it s will show in fronted-->
    <div class="row">
      <div class="col-lg-6">
        <img src="{{HomeUrl+item.BlogImage}}" class="card_image blogs" alt="Credence Diagnostic Centre">
      </div>


      <div class="col-lg-6">

        <div class="HideDescription" [innerHtml]="item.Discription"></div>
      </div>

      <br />
    </div>
    <br />
  </div>
</div>
