<div class="container">




  <!--<app-breadcrumb></app-breadcrumb>-->

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page"><a routerLink="/OurTeam">Leadership</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Dr Ramakrishna Devaki</li>
    </ol>
  </nav>
  <br /><br /><br />
  
  <h2 style="    color: black;  margin-top: -50px;"><b>Dr Ramakrishna Devaki</b></h2>
  <p style="font-size: 14px;
    color: black;margin-top:-9px;
    margin-left: 9px;"><b>Chief Executive Officer</b></p>


  <div class="row">

    <div class="col-lg-7">
      <p style="color:black">
        <b style="color:black">➣  Dr. Rama Krishna Devaki </b>completed his Ph.D. in Medical Biochemistry from National &nbsp;&nbsp;&nbsp;&nbsp;Institute of Mental Health & Neurosciences, (<b>NIMHANS</b>), Bangalore India.

        He did his &nbsp;&nbsp;&nbsp;&nbsp;Post-doctoral fellow ship from Cancer Genetics Inc, New Jersey, and USA.
      </p>

      <p>
        <b>➣</b>
        He has 22 yrs of teaching experience in the field of Biochemistry and 28 yrs of &nbsp;&nbsp;&nbsp;&nbsp;research experience
        Currently he is extending his services in the capacity of<b style="color:#0a3750">
  Chief &nbsp;&nbsp;&nbsp;&nbsp;Executive Officer (CEO) &nbsp;to Credence Diagnostic Centre
</b>, Since Dec, 2015.
      </p>
      <p>
        <b>➣</b>
        He has 22 publications in National & International Journals and has presented 13 &nbsp;&nbsp;&nbsp;&nbsp;papers in Conferences/Meetings and to his credit two chapters were published.
      </p>
      <p>
        <b>  ➣</b>
        Received <b style="color:#0a3750">Young Scientist Award </b>at 10th World congress of<b style="color:black"> biological psychiatry</b> held &nbsp;&nbsp;&nbsp;&nbsp;at Prague, Czech Republic, from 29th May to 2nd June,
        2011 and <b style="color:#0a3750">RGYI Young &nbsp;&nbsp;&nbsp;&nbsp;investigator award</b> from <b style="color:black">Department of Biotechnology </b> on 7th 7 &nbsp;December 2010.
      </p>
      <p>
        <b>➣</b>
        Member Secretary- Institutional Biosafety Committees (IBSCs), Govt. of India and &nbsp;&nbsp;&nbsp;&nbsp;Review Committee on Genetic Manipulation (RCGM), Govt. of India.
      </p>
      <p><b>➣ </b>Advisory committee member of National Accreditation Board of Hospital (NABH), &nbsp;&nbsp;&nbsp;&nbsp;Government of India.</p>
      <p><b>➣ </b>Empanelled as a Lead Assessor for National Accreditation Board for Testing and &nbsp;&nbsp;&nbsp;&nbsp;Calibration Laboratories (NABL), India and Technical Assessor for Dubai Accreditation &nbsp;&nbsp;&nbsp;&nbsp;Committee (DAC), Government of Dubai.</p>
    </div>
    <div class="col-lg-5">
      <!---- <img style="width:100%" src="../../../assets/img/WhatsApp Image 2022-01-29 at 16.18.30.jpeg" />-->
      <img  style="border-radius:10px;height:400px;width:400px;"src="../../../assets/img/WhatsApp Image 2022-02-19 at 5.42.19 PM.jpeg" class="krishna" alt="Credence Diagnostic Centre"/>
    </div>
  </div>




</div>
