<section id="Microbiology">
  <div class="container">

   
  

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
        <li class="breadcrumb-item bread_active" aria-current="page">Microbiology</li>
      </ol>
    </nav>


    <h1>Microbiology</h1>
    <div class="row">
      <div class="col-lg-5">
        <img style="width:100%;border-radius:10px;box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;" src="../../../assets/img/OurServices/Microbiology_H.png"alt="credence Diagnostics center" />
      </div>
      <div class="col-lg-7">
        <p>
          <b> Clinical microbiology </b>is a discipline of laboratory medicine that plays a pivotal role in the diagnosis and treatment of
          infectious diseases. Clinical Microbiology laboratories (CMLs) are the first lines of defense for detection of new antibiotic resistance,
          outbreaks infectious diseases and a possible bioterrorism event. Maintaining high-quality CMLs is the current best approach for managing
          today's problems of emerging and re-emerging infectious diseases and antimicrobial agent resistance by providing good patient care outcomes
          that are actually cost effective. Accurate laboratory diagnosis and timely reporting of the infectious agents has become a mandate in the
          management of these difficult diseases.
        </p>

        The CML at Credence Diagnostic Centre, is a full-service laboratory offering a broad selection of diagnostic tests related to Clinical
        microbiology and Immunology.
      </div>
      The laboratory receives specimens from several hospitals and health care facilities in and around Hyderabad.
          The Microbiology laboratory at CDC, is managed by experienced, quality conscious and dedicated technical and academic personnel, guided by
          the vital principles of Good Laboratory Practices of Accuracy, Precision and Timeliness. The facility is equipped with state of the art,
          conventional and automated instruments that facilitate generation of reliable, rapid and quality assured results in a clinically useful
          time, pertaining to bacteriology, mycobacteriology, immunology and Molecular biology divisions.
       

      

      <b>Services offered by the laboratory include: </b>



      <p>
        <b>  SEROLOGY:</b> The diagnostic work up in serology focuses on performance of immunochromatography assays, ANA testing (autoantibodies) \
        by Indirect Immunofluorescence (IIF). CD4 counts are being done by Flow Cytometry.


      </p>
      <p>
        <b> BACTERIOLOGY: </b>The diagnostic work up of Bacterial infections focuses on Microscopic detection by Grams stain, special stains and cultures.  

      </p>
      <p>
        <b> MYCOBACTERIOLOGY: </b>The routine diagnostic work up of Mycobacterial infections focuses on Microscopic detection by Acid fast
        staining (AFB), Auramine and Rhodamine staining and Mycobacterial liquid (MGIT) and solid culture. Molecular detection of M.tb and
        susceptibility to Rifampicin by Gene Xpert is also performed.

      </p>
      <p>
        <b>PARASITOLOGY: </b>The routine diagnostic work up of parasitic infections focuses on microscopic detection of ova & cyst by wet
        mount and modified acid fast staining.
      </p>
      <p>
        <b>MOLECULAR BIOLOGY: </b>The diagnostic work up is done in Molecular biology for viral loads of various viral infections.
      </p>
      Special expertise is available in all these sections to support collaborative research.
      <br />
      All investigations are performed as per standard guidelines using Standard Operating Manuals in every division of the lab. All the protocols are referred to the Clinical Microbiology Practical Handbook (ASM, USA) and CLSI guidelines. This not only helps in improving and maintaining the work quality but also allows each and everyone to get acquainted and master all the techniques that are being performed in the Lab. These manuals are updated regularly with newer techniques and test assays being added to the diagnostic armamentarium.
      <br />
      The Microbiology laboratory also had conducted hands-on training workshops in Immuno fluorescence assays, Molecular Biology and transplant immunology.



    </div>


  </div>
</section>
