import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../Services/general.service';
import { DataService } from '../../Services/data.service';
import { PageHeaderComponent } from '../page-header/page-header.component';
declare var $;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PageHeaderComponent]
})
export class LoginComponent implements OnInit {
  
  public form: FormGroup;
  public Mobile: AbstractControl;
  randomnumber: any;
  otp: any;
  PhoneNo: any;
  LoginDetails: any;
    TemplateId: string;

  constructor(private dataSharingService: DataService,public router: Router, fb: FormBuilder, public generalService: GeneralService) {

    this.router = router;
    this.form = fb.group({
      'Mobile': ['', Validators.compose([Validators.required, Validators.pattern(/^[6-9]\d{9}$/)])]
    });
  }

  ngOnInit(): void {

  }
  //This is where you will submit the form.
  public onSubmit(val) {
    debugger
    this.PhoneNo = val.Mobile;
    if (this.form.valid) {
      var UploadFile = new FormData();
      UploadFile.append("Mobile", val.Mobile);    
      var url = "api/Values/CheckMobile";
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger;
        if (data != 'NOT EXIST') {
          this.otp = "";
          this.SendOtp();
          
          $('#myModal3').modal('show');
          this.LoginDetails = data;
          localStorage.removeItem('LoginDetails');
          localStorage.setItem("LoginDetails", this.LoginDetails);
        }
        else {
          this.generalService.ShowAlert('ERROR', 'You dont have an account,please Sign up', 'error')
     
          this.router.navigate(['/Registration']);
        }
      }, err => {
        //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      });
    }
  }

  /* send otp for registration */
  SendOtp() {
    debugger
    this.randomnumber = Math.floor(1000 + Math.random() * 9000);
    var UploadFile = new FormData();
    var msg = "Your OTP is " + this.randomnumber +" CREDENCE DIAGNOSTICS";
    //var msg = "Your Otp Is: " + this.randomnumber
    //  + " HYDRST"
    //this.TemplateId = "1207164518487740000";
    UploadFile.append("Text", msg);
    UploadFile.append("Mobile", this.PhoneNo);
    //UploadFile.append("TemplateId", this.TemplateId);
    var url = "api/Values/Send_Sms";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      if (data == "SUCCESS") {
        
      }
      else {
        
      }
    });
  }
  /*end code*/

  /*verifyotp for forgotpassword*/
  VerifyOtp(otp) {
    debugger
    if (this.randomnumber == otp) {
      $('#myModal3').modal('hide');
     
      localStorage.setItem("LoginDetails", JSON.stringify(this.LoginDetails[0]));
     // location.reload();
      //this.dataSharingService.login();
      //this.router.navigateByUrl('/Header1', { skipLocationChange: true }).then(() => {
      //    this.router.navigateByUrl('/Home');
      //    });
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/Home']);
    }
    else {
      alert("Invalid OTP");
    }
  }
  /*end region*/

  //This code is only used to enter numerical values.
  keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
