import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../Services/general.service';
import { VERSION } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { MessageService } from 'primeng/api';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
declare var $;
import { Router } from '@angular/router';
@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss'],
  providers: [MessageService]
})
export class MoreInfoComponent implements OnInit {
  packageName: string;
  healthPackages:any=[]
  testsInclude: any;
  
  constructor(private actRoute: ActivatedRoute, public router: Router, public generalService: GeneralService, public toastrService: ToastrService, public messageService: MessageService, public cartService: CartService) {
    this.packageName = this.actRoute.snapshot.params.Name
  }


  ngOnInit(): void {
    debugger
    this.actRoute.paramMap.subscribe(params => {
      debugger
      this.packageName = params.get('Name');
      this.getHealthPackages();
    });
  }

  //=================Getting HealthPackages data from BackEnd using general service=====================//
  getHealthPackages() {
    var UploadFile = new FormData();
    var url = "api/Values/GetHealthPackages?flag=0";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.healthPackages = data.filter(a => a.PACKAGENAME == this.packageName);
        this.testsInclude = this.healthPackages[0].PACKAGEDESCRIPTION1;
       
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  } 


  addToCart() {
    debugger
    let item = this.healthPackages[0];
    var TEST_ID;
    this.messageService.clear();
    let arr = [];
    arr.push(
      {
        TEST_ID: item.PACKAGE_ID
        , TESTNAME: item.PACKAGENAME
        , TESTCODE: item.PACKAGECODE
        , PRICE: item.PRICE
        , ISPACKAGE: true
      }
    )
    this.cartService.addToCart(arr[0]);
    debugger
    if (localStorage.getItem('info') == 'added') {
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Package addted to the cart successfully' });

    }
    else if (localStorage.getItem('info') == 'exists') {
    //  this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Package already added to the cart' });
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Package addted to the cart successfully' });

    }
  }
  goToHealthPacks() {
    this.router.navigateByUrl('/HealthPackages')
  }
}
