import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biochemistry',
  templateUrl: './biochemistry.component.html',
  styleUrls: ['./biochemistry.component.scss']
})
export class BiochemistryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
