import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/general.service';
import { VERSION } from '@angular/core';
import { CartService } from '../../Services/cart.service';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { ToastrService, GlobalConfig } from 'ngx-toastr'
declare var $;
import { Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';

@Component({
  selector: 'app-health-packages',
  templateUrl: './health-packages.component.html',
  styleUrls: ['./health-packages.component.scss'],
  providers: [MessageService]

})
export class HealthPackagesComponent implements OnInit {
  healthPackages: any = [];
    HomeUrl: any;
  constructor(public router: Router, public http: HttpClient, public generalService: GeneralService, public toastrService: ToastrService,
    public messageService: MessageService, public cartService: CartService) {
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      this.HomeUrl = data.Webservice;
    });



  }

  ngOnInit(): void {
    this.getHealthPackages();
  }
  //=================Getting HealthPackages data from BackEnd using general service=====================//
  getHealthPackages() {
    var UploadFile = new FormData();
    var url = "api/Values/GetHealthPackages?flag=0";
    this.generalService.GetData(url).then((data: any) => {
      if (data != null) {
        debugger
        this.healthPackages = data;
      }
      else {
        alert('error')
      }
    }, err => {
      alert('error')
    });
  }

  addTocart1(item) {
    debugger
    var TEST_ID;
    this.messageService.clear();
    let arr = [];
    arr.push(
      {
          TEST_ID: item.PACKAGE_ID
        , TESTNAME: item.PACKAGENAME
        , TESTCODE: item.PACKAGECODE
        , PRICE: item.PRICE
        , ISPACKAGE: true
      }
    )
    this.cartService.addToCart(arr[0]);
    debugger
    if (localStorage.getItem('info') == 'added') {
      this.messageService.add({ severity: 'success', summary: 'SUCCESS', detail: 'Package added to the cart successfully' });

    }
    else if (localStorage.getItem('info') == 'exists') {
      this.messageService.add({ severity: 'info', summary: 'INFO', detail: 'Package already added to the cart' });

    }
  }
  

  knowMore(val) {
    debugger
    this.router.navigate(['/HealthPackage/', val.PACKAGENAME]);
  }

}
//export interface Product {
//  //PACKAGE_ID: number;
//  //PACKAGENAME: string;
//  //PACKAGECODE: string;
//  //PRICE: number;
//}
export interface Product {
  PACKAGE_ID: number;
  TESTNAME: string;
  TESTCODE: string;
  PRICE: number;
  ISPACKAGE: boolean;
  TEST_ID: number;

}

