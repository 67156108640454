

<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">About CDC</li>
    </ol>
  </nav>

  <div class="row">
  
    <div class="col-lg-10">
      <h1>About CDC</h1>
    </div>

    <div class="row">
      <div class="col-lg-5">

        <!--<img src="../../../assets/img/CDCNewLogo.PNG"  class="logo"/>-->
        <img src="../../../assets/img/Credence logo.PNG" alt="Credence Diagnostic Centre"class="cdc" />

      </div>
      <div class="col-lg-7">
        <div class="con">
          <!--<p>
            Credence Diagnostics Centre is one of the leading Diagnostic laboratory network in<br /> India moving ahead at every step.
            We are currently servicing nearly 2000 doctors & labs all across India.
          </p>
          <p>
            Our vision is to be the undisputed leader in providing world class diagnostic services by maintaining highest ethical
            standards and quality. Year 2014 marks the beginning of a <br />new era for Credence as it is launching web based services.
            Credence Diagnostics is an emerging leader in the field of personalized medicine in India,
          </p>
          <p>
            offering diagnostic services that enable precision medicine in every clinical department from Biochemistry to Clinical Genetics.
            The Services being developed at Credence Diagnostics are poised to transform patients management, increase treatment efficacy, and reduce health care costs.
          </p>
          <p>
            Credence Diagnostics Centre is known for its excellence in Molecular, Cytogenetics and Clinical Diagnostics. We want to change the world,
            by looking a disease or disorder at different perspective by using advanced diagnostic tools and techniques. If a disease is diagnosed earlier, then it can be treated easily.
          </p>-->
          <p>
            Credence Diagnostics Centre is one of the leading Diagnostic laboratory network in
            India recommended by many Physicians. Our aim is to provide most reliable and affordable health care packages and services with
            uncompromising quality and world class service. We specialize in offering personalized diagnostics in Biochemistry, Microbiology and
            Clinical Genetics using advanced diagnostic tools and techniques in accordance with regulatory standards provide detailed clinical
            interpretations and aid doctors in making right decision.

          </p>
        </div>


      </div>

      <div class="container" style="margin-top:-60px;">
      
                          <div class="row">
                            <div class="col-lg-4 vision">
                              <img src="../../../assets/img/vision.png"alt="Credence Diagnostic Centre" />
                              <!--<img src="../../../assets/img/vision.jpg" />-->
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-7">

                              <h1 class="vision_1">Vision </h1>
                              <p class="visi">
                                <!--Our vision is to provide cost effective and top class diagnostic service across all prognosis while
  adapting latest technologies and become a leader in quality healthcare to one and all.-->
                                Our Vision is to be top diagnostic center without compromising on quality, accuracy and customer satisfaction.
                                To foray into unique range of diagnostic services and offer entire range of clinical investigation services.

                              </p>
                         
                            </div>


                          </div>
        <br /><br /><br /><br />
                          <div class="row">
                            <div class="col-lg-7 mision_1">
                              <h1> Mission </h1>
                              To Provide best in class diagnostic services at affordable rates, while being the best in this space by introducing
                              innovation, Global R&D and new inventions in the clinical and pathological tests, while simplifying the Patient’s
                              Diagnostic journey and ensure 100% privacy and customer confidentiality.
                              <!--Credence Diagnostics Centre is part of Kamineni Hospitals and is committed to:
  <br /><br />
        <p>

          <b> ➣</b>State of the Art Testing and medical diagnostics<br />
          <b> ➣</b>100% Patient Privacy and Quick Delivery of Reports<br />
          <b> ➣</b>Deliver World -class Diagnostic Facilities
        </p>-->
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-4 mision">
                              <img src="../../../assets/img/Missionimage.png" alt="Credence Diagnostic Centre"/>
                              <!--<img src="../../../assets/img/mission.jpg" />-->
                            </div>
                          </div>
        <!--<div class="row">
    <div class="col-lg-6">

      <h1 style="color:black;text-align:center;">Vision </h1>
      &nbsp;&nbsp;  Our vison is to provide cost effective and top class diagnosticacross all prognosis while adapting latest technologiesand become a leader in quality healthcare to one and all.
      <br /><br /><br />
    </div>
    <div class="col-lg-2"></div>
    <div class="col-lg-4">
      <img src="../../../assets/img/vision.png" />

    </div>
  </div>-->

      </div>
      <!--<p>
        The Department of Genetics has state of the art cytogenetic facility to study the Human chromosome whether it is to observe the structure or to see deletions and insertions in the chromosomes.
        The genetic facility is equiped with Real time PCR systems, High resolution Sequencers</p>
       <p> High powered fluroscent Microscopes, Computer enhanced image capturing system and automated DNA extraction systems for error free and accurate diagnostic procedure.
        We perform genetic analysis in Aminotic fluid and Aborted tissue or Poc samples as well to help identify, if your baby is suffering from any disease or had genetic disorder which caused the miscarriage or abortion.</p>
       <p> Genetic Research facility has a specialised genetic Counseling centre. The Counseling sessions provide each and every information the patient requires to know future and other possibilty. The Genetic Counseling sessions are conducted by certified counsellors, who look at the patient family history, diagnostic reports and provides fully detailed treatment suggestion. We provide genetic counselling for couple combatibilty to see , Herediteary disease inheritance, Treatment solutions for genetic diseases and risk prediction of herediatery disease occurance.
      </p>-->
    </div>
  </div>
</div>
