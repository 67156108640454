<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">Instruments</li>
    </ol>
  </nav>
  <div class="row">
    <div class="col-lg-3">
      <div>
        <div class="catHead">Categories</div>
        <!--Below div for side bar categories-->
        <div *ngFor="let item of Categories;let i=index">
          <div [class]="(CategoryName === item.CATEGORINAME) ?  'activeCategory':'catHeader'" (click)="onclickmethodforLeftsideCategories(item.CATEGORYID,item.CATEGORINAME)">
            {{item.CATEGORINAME}}
          </div>
        </div>       
      </div>
     
    </div>
    <div class="col-lg-9">
      <div class="row">
        <h1>Credence Diagnostics Center Lab Equipments</h1>
        <!--below div is for showing products regarding selected categories-->
        <div class="col-lg-4" *ngFor="let item of Equipments;let i=index">
          <div class="card" (click)="navigatingToInstrument(item)" style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;  height: 412px;">
            <img src={{HomeURL+item.Image}}
                 class="card-img-top" alt="Credence Diagnostics Centre" style="cursor:pointer;" />
            <div class="card-title">
              {{item.Title}}
            </div>     
          </div>
        </div>
      </div>
    
    </div>
  </div>
</div>

