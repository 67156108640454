
 

<div class="container">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/Home">Home</a></li>
      <li class="breadcrumb-item bread_active" aria-current="page">About Us</li>
    </ol>
  </nav>
  <div class="'container">
    <h2 style="color:deepskyblue">About CDC</h2>
    <p style="font-size:14px;">
      Credence Diagnostics Centre is one of the leading Diagnostic laboratory network India moving ahead at every step. We are currently servicing nearly 2000 doctors & labs all across India. Our vision is to be the undisputed leader in providing world class diagnostic services by maintaining highest ethical standards and quality. Year 2014 marks the beginning of a new era for Credence as it is launching web based services.
      Credence Diagnostics is an emerging leader in the field of personalized medicine in India, offering diagnostic services that enable precision medicine in every clinical department from Biochemistry to Clinical Genetics. The Services being developed at Credence Diagnostics are poised to transform patients management, increase treatment efficacy, and reduce health care costs. Credence Diagnostics Centre is known for its excellence in Molecular, Cytogenetics and Clinical Diagnostics. We want to change the world, by looking a disease or disorder at different perspective by using advanced diagnostic tools and techniques. If a disease is diagnosed earlier, then it can be treated easily.
    </p>
    <br />

    <h2 style="color:deepskyblue">Leadership</h2>

    CDC- Credence Diagnostics Centre team is divided into multiple clinical domins from<br />
    1. Microbiology<br />
    2. Pathology<br />
    3. Genetics<br />
    4. Biochemistry<br />
    <br />
    <p style="font-size:14px;">
      Each Departmental domin has specialised team of technicians and scientists who take at most care of each every sample. We have more 100 Laboratory techinicians and Scientists working together to provide accurate and quick diagnostics report. All the laboratory procedure are standardised by ISO quality standard and followed by NABL guidelines. To achieve the highest quality every test conducted are verified by Quality Control auditor and Lab director with dedicated Laboratory Information System.
    </p>
    <br />
    <h2 style="color:deepskyblue">CDC's Advanced Genetic Research Facility</h2>
    <p style="font-size:14px;">
      The Department of Genetics has state of the art cytogenetic facility to study the Human chromosome whether it is to observe the structure or to see deletions and insertions in the chromosomes. The genetic facility is equiped with Real time PCR systems, High resolution Sequencers, High powered fluroscent Microscopes, Computer enhanced image capturing system and automated DNA extraction systems for error free and accurate diagnostic procedure. We perform genetic analysis in Aminotic fluid and Aborted tissue or Poc samples as well to help identify, if your baby is suffering from any disease or had genetic disorder which caused the miscarriage or abortion.
      Genetic Research facility has a specialised genetic Counseling centre. The Counseling sessions provide each and every information the patient requires to know future and other possibilty. The Genetic Counseling sessions are conducted by certified counsellors, who look at the patient family history, diagnostic reports and provides fully detailed treatment suggestion. We provide genetic counselling for couple combatibilty to see , Herediteary disease inheritance, Treatment solutions for genetic diseases and risk prediction of herediatery disease occurance.
      </p>
  </div>
  </div>
