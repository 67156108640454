<p-toast position="top-center"></p-toast>
<hr />

<div class="container-fluid" style="background:#fff">
  <br />
  <div class="container">
    <ol class="breadcrumb" style="    margin-top: -28px;">
      <li>
        <a routerLink="/"><i class="fa fa-home mr-2"></i>Home</a>
      </li>
      <li>
        <a style="cursor:pointer" (click)="navigatingtoequipment(productDetails[0].CATEGORINAME)">{{productDetails[0].CATEGORINAME}}</a>
        <!--<a style="cursor:pointer">Instruments</a>-->
      </li>
      <li>
        <a>{{Title}}</a>
      </li>
    </ol>

    <div class="row">
   
      <div class="col-lg-6 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;  height: 505px;width: 105%; height: 100%;margin-left: -14px;">
       
          <img src={{HomeURL+productDetails[0].Image}}
               class="cart_img" alt="Credence Diagnostics Centre">
        
        &nbsp;&nbsp;&nbsp;
      </div>
      <div class="col-lg-6">
        <div class="decCard">
          <h4 class="mainHeader">{{productDetails[0].Title}}</h4>


          <div class="aboutFont">
            About this instrument
          </div>
          <p class="text-align_j">
            {{productDetails[0].Description}}
          </p>
          <div class="aboutFont">
            Used To
          </div>
          <p class="text-align_j" [innerHtml]="UsedTo">
          </p>


        </div>
      </div>









  </div>
    </div>
  </div>

<style>
  .custcards {
    border: 1px solid #c1c1c1;
    padding: 11px;
    border-radius: 4px;
  }

  .cartpad {
    padding-right: 0
  }
</style>


