import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drlaxshmi',
  templateUrl: './drlaxshmi.component.html',
  styleUrls: ['./drlaxshmi.component.scss']
})
export class DrlaxshmiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
